import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CourseDataService } from '../course-data.service';
import { Careers } from './careers.model';

@Component({
  selector: 'app-careers-page',
  templateUrl: './careers-page.component.html',
  styleUrls: ['./careers-page.component.css']
})
export class CareersPageComponent implements OnInit {
  careers: Careers;
  onSubmitBtn: boolean = false;
  careersThumbnail:  any;
  selectedImageName: any;
  formValidationErrors: any;


  @ViewChild('form', { static: false }) form: any;
  constructor(private courseData: CourseDataService, private router: Router) {
    this.careers = new Careers();
    this.careers.country = "Australia";
    this.careers.account_name = "Mechatron Robotics Australia";
    this.careersThumbnail = null;
    this.selectedImageName = null;
    this.formValidationErrors = this.courseData.getFormValidationErrors();
  }



  ngOnInit(): void {
    ​//window.scrollTo(0, 0);
  }

  onImageSelected(event: any){
    this.careersThumbnail = event.target.files[0];
    this.selectedImageName = this.careersThumbnail.name;

    // console.log("this.courseThumbnail", this.careersThumbnail);
    // console.log("this.selectedImageName ", this.selectedImageName);

    this.upload();
  }

  upload() {
    let formData = new FormData();
    formData.append('file', this.careersThumbnail, this.selectedImageName)
    console.log(this.selectedImageName, 'careers', this.careers.email, formData);
    this.courseData.uploadFile( this.selectedImageName, 'careers', this.careers.email, formData).subscribe(
      (result: any) => {
        // console.log(result.url)
         this.careers.resume = (result.url);

      //  this.careers.resume = (result.url)
    // console.log("DATA", this.selectedImageName, 'careers', this.careers.email, formData);

      },
    //   err => {
    //  alert("Error");
    //   }
    );
  }



 onSubmit() {
    if (!this.form.valid) {
      return;
    }
    if (this.careersThumbnail){
      this.upload();
      this.courseData.createCareers(this.careers).subscribe((res: any) => {
        // console.log("this.careers",this.careers);
        //  console.log('enq', res);
        this.onSubmitBtn = true;
          //  console.log("Submitted Successfully !!! Thank You..");
            if(res){
            this.onSubmitBtn = false;
            alert("Submitted Successfully !!! Thank You..")
            this.form.reset();
            location.reload();
            }
       },
       error =>{
         console.log("err", error)
       }
       );

    }
  }
}
