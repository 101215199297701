import { LaunchingDialogeComponent } from './launching-dialoge/launching-dialoge.component';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import { SEOService } from 'src/app/core/services/seo.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  // registerForm: FormGroup;
  submitted = false;
  showprofileHeath = false;
  showprofileChristine = false;
  showprofileTony = false;
  dialogRef:any
  // tslint:disable-next-line:max-line-length
  title = 'Top Coding, Robotics and AI Classes in Australia';

  constructor(
    public seo: SEOService,
    //  private formBuilder: FormBuilder,
    //  public dialog: MatDialog
    public dialog: MatDialog
     )
      { }
  ngOnInit() {
    // ​window.scrollTo(0, 0);
    // setTimeout(() => this.openDialog());
    this.seo.updateTitle(this.title);
    this.seo.updateTitleDescription('description',
      //tslint:disable-next-line:max-line-length
      ' Mechatron Robotics is one of the top institutes in Australia to learn online courses on Coding, Electronics, Robotics, Artificial Intelligence, App Development, Arduino, Machine Learning, Python & Robot Operating System.'
    );
   
    //Lunching Dialog
    // this.openDialog();

    // this.registerForm = this.formBuilder.group({
    //   parentName: ['', { validators: [Validators.required, Validators.minLength(1), Validators.pattern(/^[a-zA-Z\s]*$/)] }],
    //   studentName: ['', { validators: [Validators.required, Validators.minLength(1), Validators.pattern(/^[a-zA-Z\s]*$/)] }],
    //   contactno: ['', { validators: [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)] }],
    //   email: ['', [Validators.required, Validators.email]],
    //   studentage: ['', { validators: [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)] }],
    //   location: ['', Validators.required],


    // });



  }
  

  showProfileHeath(){
    this.showprofileHeath=true
  }
  hideProfileHeath(){
    this.showprofileHeath=false
  }

  showProfileChristine(){
    this.showprofileChristine=true
  }
  hideProfileChristine(){
    this.showprofileChristine=false
  }

  showProfileTony(){
    this.showprofileTony=true
  }
  hideProfileTony(){
    this.showprofileTony=false
  }

  openDialog() {
    const dialogRef = this.dialog.open(LaunchingDialogeComponent, { width:'50%'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  

  // openDialog(): void {
  //   const dialogRef = this.dialog.open(HomedialogComponent, {
  //     width: '1000px',
  //     height: 'auto'
  //   });


  // }




  // openDialog(): void {
  //   this.dialog.open(HomedialogComponent, {
  //     disableClose: false,
  //     'panelClass': 'congrats-container1',
  //     width: '69%',
  //     height: '80%',
  //     hasBackdrop: true,
  //      data: {}
  //   });
  // }

  // get f() {
    // return this.registerForm.controls;
  //  }

  onSubmit() {
    this.submitted = true;


  //   alert(this.f.parentName.value);
  //   alert('SUCCESS!! :-)')
  // }

}

}
