<div class="container">
  <div style="margin-top: 120px">
    <h3 class="main-head"><b>WEBSITE TERMS OF USE </b></h3>
    <br />
    <p>
      Welcome to Mechatron Robotics! We offer a wide range of STEM (Science, Technology, Engineering and Mathematics)
      courses for students of all ages. Our courses are designed to provide the practical knowledge required, in
      accordance with industry standards, to help students learn more about robotics. Before you use our site, please
      read these Terms of Use, as they contain important information.
    </p>
  </div>
  <ul>
    <li>
      <span class="sub-head">Information and availability</span>
      <ul>
        <li class="p-text">
          While we use reasonable attempts to ensure the accuracy and completeness of the content and materials on the
          Site (Content), to the extent permitted by law (including the Australian Consumer Law), we do not warrant the
          accuracy, completeness or suitability of any of the Content. The Content may be subject to change without
          notice and we do not undertake to keep the Site up to date. The Content is factual information only, is not
          comprehensive and is for general information purposes only. We also do not warrant that access to the Site
          will be uninterrupted, error-free or free from viruses.
        </li>
      </ul>
    </li>
    <li>
      <span class="sub-head">Intellectual Property rights</span>
      <ul>
        <li class="p-text">
          Unless otherwise indicated, we own or license the Content and all intellectual property rights (including any
          copyright, registered or unregistered designs, illustrations, artwork, patents or trademark or logo rights and
          domain names) displayed or used on the Site (Our Intellectual Property).
        </li>
        <li class="p-text">
          We authorize you to access and use the Site solely for your own personal, non-commercial use and to display,
          print and download the Content onto your personal device provided that you do not remove any copyright notice
          included in Our Intellectual Property.
        </li>
        <li class="p-text">
          Subject to the above, your use of, and access to, the Site and the Content does not grant or transfer to you
          any rights, title or interest to Our Intellectual Property. Unless otherwise permitted in these Terms, you
          must not:
          <ul>
            <li>
              copy or use, in whole or in part, any of Our Intellectual Property;
            </li>
            <li>
              reproduce, retransmit, distribute, display, disseminate, sell, publish, broadcast or circulate any of Our
              Intellectual Property.
            </li>
            <li>
              breach any intellectual property rights connected with Our Intellectual Property, including altering or
              modifying any of Our Intellectual Property, causing any of Our Intellectual Property to be framed or
              embedded in another website or platform, or creating derivative works from Our Intellectual Property.
            </li>
            <li>
              breach any intellectual property rights connected with Our
              Intellectual Property, including altering or modifying any of Our
              Intellectual Property, causing any of Our Intellectual Property to
              be framed or embedded in another website or platform, or creating
              derivative works from Our Intellectual Property.
            </li>
            <li>
              Nothing in the above clause restricts your ability to publish, post or repost Content or Our Intellectual
              Property on your social media page or blog, provided that:
            </li>
            <li>
              you do not assert that you are the owner of the Content or Our Intellectual Property;
            </li>
            <li>
              unless explicitly agreed by us in writing, you do not assert that you are endorsed or approved by us;
            </li>
            <li>
              you do not damage or take advantage of our reputation, including in a manner that is illegal, unfair,
              misleading or deceptive;
            </li>
            <li>you comply with all other terms of these Terms.
            </li>
          </ul>
        </li>
      </ul>
    </li>
    <li>
      <span class="sub-head">Conduct we don’t accept </span>
      <ul>
        <li class="p-text">
          You must not do or attempt to do anything that is unlawful, which is prohibited by applicable law, which we
          would consider inappropriate, or which might bring us or the Site into disrepute. This includes: <ul>
            <li>
              anything that would constitute a breach of an individual’s privacy (including uploading private or
              personal information without an individual's consent) or any other legal rights;
            </li>
            <li>
              using the Site to defame, harass, threaten, menace or offend any person;
            </li>
            <li>using the Site for unlawful purposes;</li>
            <li>interfering with any user of the Site;</li>
            <li>
              tampering with or modifying the Site, knowingly transmitting viruses or other disabling features, or
              damaging or interfering with the Site, including using trojan horses, viruses or piracy or programming
              routines that may damage or interfere with the Site;
            </li>
            <li>using the Site to send unsolicited electronic messages;</li>
            <li>
              using data mining, robots, screen scraping or similar data gathering and extraction tools on the Site;
            </li>
            <li>
              facilitating or assisting a third party to do any of the above acts.
            </li>
          </ul>
        </li>
      </ul>
    </li>
    <li>
      <span class="sub-head">Content you upload </span>
      <ul>
        <li class="p-text">
          We encourage you to interact with the Site and with us on social media! You may be permitted to post, upload,
          publish, submit or transmit relevant information and content (User Content) on the Site. We may run campaigns
          via the Site and via social media that encourage you to post User Content on social media using specific
          hashtags (#) (Tag).

        </li>
        <li class="p-text">
          If you make any User Content available on or through the Site, including on social media using a Tag, you
          grant to us a worldwide, irrevocable, perpetual, non-exclusive, transferable, royalty-free license to use the
          User Content, with the right to use, view, copy, adapt, modify, distribute, license, transfer, communicate,
          publicly display, publicly perform, transmit, stream, broadcast, access, or otherwise exploit such User
          Content on, through or by means of the Site and our social media platforms.
          <ul>
            <li>
              You agree that you are solely responsible for all User Content that you make available on or through the
              Site, including on social media using a Tag. You represent and warrant that:
            </li>
            <li>
              you are either the sole and exclusive owner of all User Content or you have all rights, licenses, consents
              and releases that are necessary to grant to us the rights in such User Content (as contemplated by these
              Terms);
            </li>
            <li>
              neither the User Content nor the posting, uploading, publication, submission or transmission of the User
              Content or our use of the User Content on, through or by means of our Site (including on social media)
              will infringe, misappropriate or violate a third party’s intellectual property rights, or rights of
              publicity or privacy, or result in the violation of any applicable law or regulation.
            </li>
            <li>
              We do not endorse or approve, and are not responsible for, any User Content. We may, at any time (at our
              sole discretion), remove any User Content.
            </li>
            <li>
              To the maximum extent permitted by law, you are liable for, and agree to indemnify us and hold us harmless
              in respect of, any liability that we may suffer, incur or otherwise become liable for, arising from or in
              connection with any claim by a person that any of your User Content infringes the intellectual property
              rights of any person.
            </li>
          </ul>
        </li>
      </ul>
    </li>
    <li>
      <span class="sub-head">Third party sites </span>
      <ul>
        <li class="p-text">
          The Site may contain links to websites operated by third parties. Unless we tell you otherwise, we do not
          control, endorse or approve, and are not responsible for, the content on those websites. We recommend that you
          make your own investigations with respect to the suitability of those websites. If you purchase goods or
          services from a third-party website linked from the Site, such third party provides the goods and services to
          you, not us.
        </li>
        <li class="p-text">
          We may receive a benefit (which may include a referral fee or a commission) should you visit certain
          third-party websites via a link on the Site (Affiliate Link) or for featuring certain products or services on
          the Site. We will make it clear by notice to you which (if any) products or services we receive a benefit to
          feature on the Site, or which (if any) third party links are Affiliate Links
        </li>
      </ul>
    </li>
    <li>
      <span class="sub-head">Our liability is limited </span>
      <ul>
        <li class="p-text">
          You may have certain rights under the Australian Consumer Law in relation to the Site and the Content, and you
          may contact us for more information about this. Despite anything to the contrary, to the maximum extent
          permitted by law (including the Australian Consumer Law), we exclude all liability for any loss or damage of
          any kind (including consequential loss, indirect loss, loss of profit, loss of benefit, loss of opportunity or
          loss of reputation) whether under statute, contract, equity, tort (including negligence), indemnity or
          otherwise arising out of or in connection with the Site or the Content.
        </li>
      </ul>
    </li>
    <li>
      <span class="sub-head">Privacy</span>
      <ul>
        <li class="p-text">
          We respect your privacy and understand protecting your personal information is important. Our Privacy Policy
          (available on the Site) sets out how we will collect and handle your personal information.
        </li>
      </ul>
    </li>
    <li>
      <span class="sub-head">What happens if we discontinue the Site </span>
      <ul>
        <li class="p-text">
          We may, at any time and without notice, discontinue the Site (in whole or in part), or exclude any person from
          using our Site.
        </li>
      </ul>
    </li>
    <!-- <li>
      <span class="sub-head">Which laws govern these Terms</span>
      <ul>
        <li class="p-text">
          These Terms are governed by the laws of Victoria. Each party
          irrevocably and unconditionally submits to the exclusive jurisdiction
          of the courts operating in Victoria and any courts entitled to hear
          appeals from those courts and waives any right to object to
          proceedings being brought in those courts.
        </li>
      </ul>
    </li> -->
    <li>
      <span class="sub-head">Changes to these Terms </span>
      <ul>
        <li class="p-text">
          We may, at any time and at our discretion, vary these Terms by publishing the varied terms on the Site. We
          recommend you check the Site regularly to ensure you are aware of our current terms.
        </li>
      </ul>
    </li>
  </ul>
  <br>
  <p> <strong>For any questions and notices, please contact us at:</strong></p>
  <!-- <p class="sub-head">
    Mechatron Robotics Australia Pty Ltd (ABN 99 654 594 064)
  </p>
  <p>
    <strong>Address :</strong>191 Balaclava Road, Caulfield North, Victoria 3161,
    Australia
  </p> -->
  <!-- <p><strong>Phone Number : </strong>03 9524 3115</p> -->
  <p style="margin-bottom: 80px;"><strong>Email : </strong>info@mechatronrobotics.com</p>
  <!-- <p><strong>© LegalVision ILP Pty Ltd</strong></p> -->
</div>