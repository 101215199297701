import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Enquiry } from './book-trail-class-page/landing-page.model';
import { Careers } from './careers-page/careers.model';

@Injectable({
  providedIn: 'root',
})
export class CourseDataService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {

  }
  // get product list
  getAllCourse() {
    // return this.http.get(`${this.apiUrl}/products?type=Subscription&$sort={"course.level" : 1}`);
    return this.http.get(`${this.apiUrl}/products?type=Course&$mode=Category&catalog_visibility=true&token=${environment.apiTokens}&$sort={"course.level": 1}`);

  }


  filterProductByTitleLog(url: any) {
    return this.http.get(`${this.apiUrl}/products?token=${environment.apiTokens}&type=Course&seo.url=${url}`);
  }



  mostHelfulreview(productId:any) {
    return this.http.get(
      `${this.apiUrl}/reviews?$sort={"likes" : -1}&product=${productId}&token=${environment.apiTokens}&$limit=1`
    );
  }


  // fetch all the reviews of that product
  getReviewListByProductId(id: any) {
    return this.http.get(`${this.apiUrl}/reviews?product=${id}&token=${environment.apiTokens}`);
  }



  formValidationErrors = {
    required: "This field is required.",
    numOnly: "Enter a valid No.",
    mobile: "Enter a valid contact No.",
    age: "Enter a valid age.",
    email: "Enter a valid email id.",
    name: "Only alphabets allowed.",
    pinCode: "Pin should be No. with 6 digits.",
    bankAcc: "Enter a valid Acc No.",
  };

  getFormValidationErrors() {
    return this.formValidationErrors;
  }

  //Save Book Trail Class lead
  createEnquiry(formData: Enquiry) {
    return this.http.post(`${this.apiUrl}/leads?account=Mechatron Robotics Australia`, formData);
  }

  createCareers(formData: Careers){
    return this.http.post(`${this.apiUrl}/careers`, formData);
  }
  uploadFile(fileName: any, service: any, email_id:any, imgData: any) {
    // files/upload?file_name=yash2&service=careers&service_id=email@test.com
    return this.http.post(`${this.apiUrl}/files/upload?file_name=${fileName}&service=${service}&service_id=${email_id}`, imgData);

    }
}
