import { Component, OnInit, ViewChild } from '@angular/core';
import { Address, EMAIL, Enquiry, PHONE } from './landing-page.model';
import { CourseDataService } from '../course-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-book-trail-class-page',
  templateUrl: './book-trail-class-page.component.html',
  styleUrls: ['./book-trail-class-page.component.css'],
})
export class BookTrailClassPageComponent implements OnInit {
  onSubmitBtn: boolean = false;
  enquiry: Enquiry;
  dial = '61';
  mobile!: String;
  baseUrl:any;
  formValidationErrors: any;
  currDate = new Date();
  @ViewChild('form', { static: false }) form: any;
  constructor(private courseData: CourseDataService, private router: Router,
    private sanitizer: DomSanitizer) {
    this.baseUrl = environment.baseUrl;
    this.enquiry = new Enquiry();
    this.enquiry.query_date = this.currDate;
    this.enquiry.address = new Address();
    this.enquiry.PHONE = new Array<PHONE>();
    this.enquiry.EMAIL = new Array<EMAIL>();
    this.enquiry.PHONE.push(new PHONE());
    this.enquiry.EMAIL.push(new EMAIL());
    this.formValidationErrors = this.courseData.getFormValidationErrors();
    // /book-trial-class?utm_source=OLX&utm_medium=Banner&utm_campaign=Summer%20Campaign
    if (this.router.url.split('?')[1]) {
      let UrlStr = this.router.url.split('?')[1].split('&');
      let tempArr: any = [];
      for (let index = 0; index < UrlStr.length; index++) {
        tempArr.push(UrlStr[index].split('='));
      }
      let utmDict = [];
      for (let i = 0; i < tempArr.length; i++) {
        utmDict.push({
          key: tempArr[i][0],
          value: tempArr[i][1],
        });
      }
      var body: { [key: string]: string } = {};
      for (var x in utmDict) {
        body[utmDict[x].key] = utmDict[x].value;
      }
      // console.log('this.enquiry.utmObj', body[utmDict[x].key]);
      this.enquiry = {
        ...this.enquiry,
        ...body,
      };
    }
  }

  ngOnInit(): void {
    //window.scrollTo(0, 0);
    this.enquiry.enqCheckbox1 = true;
    this.enquiry.enqCheckbox2 = true;
  }


bookTrialClassUrl() {
  return this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl + "/lead-form");
}
  onSubmit() {
    if (!this.form.valid) {
      return;
    }
    console.log('this.dial::', this.dial);
    this.enquiry.PHONE[0].VALUE = '+' + this.dial + ' ' + this.mobile;
    this.enquiry.notification_mail = true;
    this.onSubmitBtn = true;
    this.enquiry.scope="Demo Class"
    // console.log(this.enquiry);
    this.courseData.createEnquiry(this.enquiry).subscribe((res) => {
      // console.log('enq', res);
      //alert("Your trial Class Booking Successfully !!! Thank You..");
      this.form.reset();
      this.onSubmitBtn = false;
      this.router.navigate(['/thank-you']);
    });
  }
}
