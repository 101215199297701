import { HomePageComponent } from './modules/web/pages/home-page/home-page.component';
import { SchoolsComponent } from './modules/web/pages/schools/schools.component';
import { SponsorsPageComponent } from './modules/web/pages/sponsors-page/sponsors-page.component';
import { AboutusPageComponent } from './modules/web/pages/aboutus-page/aboutus-page.component';
import { CareersPageComponent } from './modules/web/pages/careers-page/careers-page.component';
import { ContactusPageComponent } from './modules/web/pages/contactus-page/contactus-page.component';
import { PartnersComponent } from './modules/web/pages/partners/partners.component';
import { OurCoursesComponent } from './modules/web/pages/our-courses/our-courses.component';
import { TermAndConditionPageComponent } from './modules/web/pages/term-and-condition-page/term-and-condition-page.component';
import { PrivacyPolicyPageComponent } from './modules/web/pages/privacy-policy-page/privacy-policy-page.component';
import { WebTermAndConditionPageComponent } from './modules/web/pages/web-term-and-condition-page/web-term-and-condition-page.component';
import { BookTrailClassPageComponent } from './modules/web/pages/book-trail-class-page/book-trail-class-page.component';
import { ThankYouPageComponent } from './modules/web/pages/thank-you-page/thank-you-page.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CourseDetailsComponent } from './modules/web/pages/our-courses/course-details/course-details.component';

const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: 'home', component: HomePageComponent },
  { path: 'schools', component: SchoolsComponent },
  { path: 'aboutus', component: AboutusPageComponent },
  { path: 'careers', component: CareersPageComponent },
  { path: 'sponsors', component: SponsorsPageComponent },
  { path: 'contactus', component: ContactusPageComponent },
  { path: 'franchisee', component: PartnersComponent },
  { path: 'courses', component: OurCoursesComponent },
  { path: 'terms_condition', component: TermAndConditionPageComponent },
  { path: 'privacy_policy', component: PrivacyPolicyPageComponent },
  { path: 'web_terms', component: WebTermAndConditionPageComponent },
  { path: 'book-trial-class', component: BookTrailClassPageComponent },
  { path: 'course/:category/:title', component: CourseDetailsComponent },
  { path: 'thank-you', component: ThankYouPageComponent },
  { path: "**", redirectTo: "/", pathMatch: "full" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
