import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './modules/web/pages/home-page/home-page.component';
import { OurCoursesComponent } from './modules/web/pages/our-courses/our-courses.component';
import { SchoolsComponent } from './modules/web/pages/schools/schools.component';
import { PartnersComponent } from './modules/web/pages/partners/partners.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatCardModule} from '@angular/material/card';
import {MatSelectModule} from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { LaunchingDialogeComponent } from './modules/web/pages/home-page/launching-dialoge/launching-dialoge.component';
import { MatDialogModule } from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import { SponsorsPageComponent } from './modules/web/pages/sponsors-page/sponsors-page.component';
import { CareersPageComponent } from './modules/web/pages/careers-page/careers-page.component';
import { ContactusPageComponent } from './modules/web/pages/contactus-page/contactus-page.component';
import { AboutusPageComponent } from './modules/web/pages/aboutus-page/aboutus-page.component';
import { HttpClientModule } from '@angular/common/http';
import {NgbPaginationModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import {MatInputModule} from '@angular/material/input';
import { PrivacyPolicyPageComponent } from './modules/web/pages/privacy-policy-page/privacy-policy-page.component';
import { TermAndConditionPageComponent } from './modules/web/pages/term-and-condition-page/term-and-condition-page.component';
import { WebTermAndConditionPageComponent } from './modules/web/pages/web-term-and-condition-page/web-term-and-condition-page.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { BookTrailClassPageComponent } from './modules/web/pages/book-trail-class-page/book-trail-class-page.component';
import { ThankYouPageComponent } from './modules/web/pages/thank-you-page/thank-you-page.component';import { SEOService } from './core/services/seo.service';
import { DownloadBoucherFormComponent } from './modules/web/pages/sponsors-page/download-boucher-form/download-boucher-form.component';
import { RatingComponent } from './shared/components/rating/rating.component';
import { CourseDetailsComponent } from './modules/web/pages/our-courses/course-details/course-details.component';
@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    OurCoursesComponent,
    SchoolsComponent,
    PartnersComponent,
    HeaderComponent,
    FooterComponent,
    LaunchingDialogeComponent,
    SponsorsPageComponent,
    CareersPageComponent,
    ContactusPageComponent,
    AboutusPageComponent,
    PrivacyPolicyPageComponent,
    TermAndConditionPageComponent,
    WebTermAndConditionPageComponent,
    BookTrailClassPageComponent,
    ThankYouPageComponent,
    DownloadBoucherFormComponent,
    RatingComponent,
    CourseDetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatDialogModule,
    MatCardModule,
    MatSelectModule,
    FormsModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    HttpClientModule,
    NgbPaginationModule, 
    NgbAlertModule,
    MatRadioModule,
    MatCheckboxModule
  ],
  providers: [SEOService],
  bootstrap: [AppComponent]
})
export class AppModule { }
