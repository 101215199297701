<div class="container-fluid main-background mb-4" >
  <div class="container main-container">
    <div class="row course-details-div">
      <div class="col-md-6 col-sm-12 p-3" *ngIf="course_loader">
        <img *ngIf='productData?.images' class="img-fluid course-img" alt="child"
          [src]="viewCourseImage(productData?.images)" alt="course_image">

        <img *ngIf='!productData?.images' class="img-fluid course-img" alt="child"
         src="/assets/images/courses/defaultpic.png" />
      </div>
      <div class="row mt-5" *ngIf="!course_loader">
      <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12">
        <div class="d-flex align-items-center">
          <strong>Courses Loading...</strong>
          <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
        </div>
      </div>
    </div>
      <!-- <div class="row mt-5" *ngIf="!course_loader">
      <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12">
        <div class="d-flex align-items-center">
          <strong>NO Data Found</strong>
          <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
        </div>
      </div>
    </div> -->
      <div class="col-md-6 col-sm-12 p-3" *ngIf="productData">
        <div>
          <div class="d-flex flex-column">
            <h3 class="card-heading boldText">
              <span class="orangeText"></span>{{ productData?.title }}
            </h3>

            <div class="level mt-1">
              Level {{ productData?.course?.level }}
            </div>
            <div class="d-flex justify-content-start mt-3 pr-3">
              <p class="boldText descTileText mr-4">
                <i class="fas fa-user mr-2"></i>{{ productData?.min_age }} Years+
              </p>
              <p class="boldText descTileText mr-4">
                <i class="fas fa-stopwatch mr-2"></i> 48 hours
              </p>
              <p class="online-course descTileText" *ngIf="productData?.course?.type == 'Online'">
                <i class="fa fa-signal mr-1"></i>Online
              </p>
              <p class="offline-course descTileText" *ngIf="productData?.course?.type == 'Class Room'">
                <i class="fa fa-signal-slash mr-1"></i>Offline
              </p>
              <p class="self-course descTileText" *ngIf="productData?.course?.type == 'Self Paced'">
                <i class="fas fa-chalkboard-teacher mr-1"></i>Self Paced
              </p>
              <p class="hybrid-course descTileText" *ngIf="productData?.course?.type == 'Hybrid'">
                <i class="far fa-books-medical mr-1"></i>Hybrid
              </p>
              <p class="integrated-course descTileText" *ngIf="productData?.course?.type == 'Integrated'">
                <i class="fal fa-chart-network mr-1"></i>Integrated
              </p>
            </div>

            <span class="rating-right" *ngIf="productData?.average_rating">
              <app-rating max="5" [setRating]="productData.average_rating" style="cursor: default"
                class="star1"></app-rating>
              <!-- <ng-template #t let-fill="fill">
                  <span class="star1" [class.full]="fill === 100">
                    <span class="half" [style.width.%]="fill"><em class="fa fa-star text-warning"></em></span><em
                      class="fa fa-star starclrr cursor"></em>
                  </span>
                </ng-template> -->
              <!-- <ngb-rating [rate]="productData?.average_rating" [starTemplate]="t" [readonly]="true" max="5">
                </ngb-rating> -->
              <span class="starclrbl ml-3">
                {{
                productData?.average_rating
                ? productData?.average_rating
                : "0"
                }}
                <span class="starclrblk">({{
                  productData?.average_rating?.total
                  ? productData.average_rating.total
                  : "0"
                  }})</span>
              </span>
            </span>
          </div>
          <hr class="underline" />
          <p class="courseview mt-3">Course Overview</p>
          <ul class="cousre-list list_name">
            <li *ngFor="let overview of productData?.overview; let i = index">
              <i class="fa fa-check mr-2"></i>{{ overview }}
            </li>
          </ul>

          <hr class="underline" />

          <!-- <div class="d-flex">
            <p class="course-price boldText m-0">&#x20B9; 21,800</p>
            <del style="margin-top: 6px; margin-left: 24px">&#x20B9; 23,800</del>
          </div> -->
        </div>
      </div>

      <div class="col-md-6 col-sm-12 p-3" *ngIf="!productData">
        <div>
          <div class="d-flex flex-column">
            <h3 class="card-heading boldText">
              <span class="orangeText"></span>No Data Found
            </h3>

</div>

    </div>
  </div>
</div>


<div class="container-fluid back-img-left">
  <div class="container margin_top">
    <div class="margin-top-desc" *ngIf="productData">
      <h1 class="boldText">Description</h1>
      <p class="cousre-list mt-3 list_name" [innerHTML]="productData?.desc">
        {{ productData?.desc }}
      </p>
    </div>
    <div class="margin-top-desc" *ngIf="!productData">
      <h1 class="boldText">Description</h1>
      <p class="cousre-list mt-3 list_name" >
      No Data Found
      </p>
    </div>
  </div>
</div>
<div class="container">
  <hr />
</div>
<div class="container margin_top">
  <div>
    <div class="row">
      <div class="col-md-6 mt-3 ml-3" *ngIf="productData">
        <h1 class="boldText">Skills You will Develop</h1>
        <ul class="cousre-list mt-3 list_name">
          <li *ngFor="let skill of productData?.course?.skills; let i = index">
            <i class="fal fa-check-circle mr-2"></i>{{ skill }}
          </li>
        </ul>
      </div>
      <div class="col-md-6 mt-3 ml-3" *ngIf="productData">
        <h1 class="boldText">Eligible Criteria</h1>
        <ul class="cousre-list mt-3 list_name">
          <li *ngFor="
              let requirement of productData?.course?.requirements;
              let i = index
            ">
            <i class="fal fa-check-circle mr-2"></i>{{ requirement }}
          </li>
        </ul>
      </div>
    </div>
    <hr />
  </div>
</div>
<div class="container-fluid right-back-img" *ngIf="productData">
  <div class="container">
    <!-- <div class="col-md-10"> -->
    <h1 class="boldText">What will you Learn?</h1>
    <ul class="cousre-list mt-3 list_name">
      <li *ngFor="let takeaway of productData?.course?.takeaways; let i = index">
        <i class="fal fa-check-circle mr-2"></i>{{ takeaway }}
      </li>
    </ul>
    <!-- </div> -->
    <!-- <div class="col-md-2 back-img-right">
    </div> -->
    <!-- </div> -->
    <!-- </div> -->
    <!-- <hr> -->
  </div>
</div>
<div class="container-fluid traning-back margin_top frequency-ask">
  <div class="container pt-4 pb-4 pr-4">
    <div class="row margin-top">
      <div class="col-md-7 mt-5">
        <h1 class="boldText">
          <span class="orangeText">Acknowledgement </span> for futuristic
          courses
        </h1>
        <p class="certificate-text pt-3">
          <span>Grab the opportunity to learn from our certified trainers and
            authenticate your skillset to reach the desired destination of
            excellence.</span>
        </p>
        <div class="pt-4 certificate-text2">
          <span><span class="orangeText font-icon"><i class="fas fa-award"></i>&nbsp;&nbsp;</span><span
              class="certificate-points ml-1">Gain Credibility & Recognition</span></span><br />
          <span><span class="orangeText font-icon"><i class="far fa-thumbs-up"></i>&nbsp;&nbsp;</span><span
              class="certificate-points">Get Preference from Higher Institutes and Employers</span></span><br />
          <span><span class="orangeText font-icon"><i class="far fa-smile"></i>&nbsp;&nbsp;</span><span
              class="certificate-points">Gain Self Confidence & professional development</span></span>
        </div>
        <!-- <p class="certificate-text2 p-3">After Completing the course and aced on your skills you will get the certificate from us. This certificate
          only verifies that Shiven Prakash has successfully completed the course <span class="font-blue">Artificial Intelligence [2021 Edition]</span>
          on
          26th April 2021 as taught by <span class="font-blue"> Mechatron Robotics</span> powered by <span class="font-blue">Neurapses Technologies Pvt. LTD.</span></p> -->
      </div>
      <div class="col-md-5 mt-4">
        <img class="img-fluid banner-img" alt="child"
          src="/assets/images/courses/new_certificate.png" />
      </div>
    </div>
  </div>
</div>

<div class="container-fluid back-img-left frequency-ask">
  <div class="container margin_top">
    <div class="margin-top-desc-top">
      <h1 class="boldText text-center" style="font-size: 35px !important">
        Course Review
      </h1>
      <hr style="margin-top: 2rem !important" />
      <div class="row mt-5">
        <div class="col-md-2" style="border-right: 1px solid #d8d8d8">
          <div class="p-2">
            <span style="color: #f2c200"><i class="fa fa-star"></i></span>&nbsp;&nbsp;<span
              style="font-weight: 600; color: #363636">Overall Ratings</span>
            <div class="text-center mt-3">
              <h1 style="font-weight: 700; font-size: 50px">
                <span style="color: #363636">
                  {{
                  productData?.average_rating
                  ? productData?.average_rating
                  : "0"
                  }}
                </span>
              </h1>
            </div>
            <br />
            <div class="row" style="justify-content: center">
              <ng-template #t let-fill="fill">
                <span class="star1" [class.full]="fill === 100">
                  <span class="half" [style.width.%]="fill"><em class="fa fa-star text-warning"></em></span><em
                    class="fa fa-star starclrr cursor"></em>
                </span>
              </ng-template>
              <!-- <ngb-rating [rate]="productData?.average_rating" [starTemplate]="t" [readonly]="true" max="5">
              </ngb-rating> -->
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="progress progressbar1" *ngFor="let item of ratingPercentage">
            <div class="progress-bar progress-bar-animated" role="progressbar" aria-valuenow="35" aria-valuemin="0"
              aria-valuemax="100" [style.width.%]="item" style="background-color: #34b53a"></div>
          </div>
        </div>

        <div class="col-md-2 mt-1">
          <div>
            <span class="fa fa-star starclr"></span>
            <span class="fa fa-star starclrr"></span>
            <span class="fa fa-star starclrr"></span>
            <span class="fa fa-star starclrr"></span>
            <span class="fa fa-star starclrr"></span>
          </div>
          <div>
            <span class="fa fa-star starclr"></span>
            <span class="fa fa-star starclr"></span>
            <span class="fa fa-star starclrr"></span>
            <span class="fa fa-star starclrr"></span>
            <span class="fa fa-star starclrr"></span>
          </div>
          <div>
            <span class="fa fa-star starclr"></span>
            <span class="fa fa-star starclr"></span>
            <span class="fa fa-star starclr"></span>
            <span class="fa fa-star starclrr"></span>
            <span class="fa fa-star starclrr"></span>
          </div>
          <div>
            <span class="fa fa-star starclr"></span>
            <span class="fa fa-star starclr"></span>
            <span class="fa fa-star starclr"></span>
            <span class="fa fa-star starclr"></span>
            <span class="fa fa-star starclrr"></span>
          </div>
          <div>
            <span class="fa fa-star starclr"></span>
            <span class="fa fa-star starclr"></span>
            <span class="fa fa-star starclr"></span>
            <span class="fa fa-star starclr"></span>
            <span class="fa fa-star starclr"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="container"> -->

<section class="frequency-ask">
  <!-- these are the other reviews  -->
</section>

<div class="container mt-5">
  <div class="container">
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-4"></div>
      <div class="col-md-4 back-img-right"></div>
    </div>

    <div class="container frequency-ask">
      <h1 class="blackText seaction-heading">
        <span class="boldText" style="font-size: 35px">Frequently asked </span>
        <span class="orangeText" style="font-size: 35px">Questions</span>
      </h1>

      <div class="row mt-5">
        <div class="col-md-12">
          <div class="faq">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel">
                <div class="panel-heading" role="tab" id="headingOne">
                  <h3 class="panel-title">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse1st"
                      aria-expanded="false" aria-controls="collapse1st"><span>What skills do the students develop at
                        Mechatron
                        robotics?</span>
                    </a>
                  </h3>
                </div>
                <div id="collapse1st" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                  <div class="panel-body">
                    <p>
                      Students learn various technical skills like Coding,
                      Electronics, Mechanics, Mechanical design, Arduino, IOT
                      and many more practical courses. Apart from the technical
                      skills students also develop various non -technical skills
                      like creativity, innovation, communication skills, logical
                      thinking, problem solving skills & teamwork.
                    </p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading" role="tab" id="headingTwo">
                  <h3 class="panel-title">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse2nd"
                      aria-expanded="false" aria-controls="collapse2nd">How long does it take to complete a course /
                      level?</a>
                  </h3>
                </div>
                <div id="collapse2nd" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                  <div class="panel-body">
                    <p>
                      Most of our courses are developed keeping in mind the
                      students develop a complete understanding of the topic.
                      So, each of our course / level is nearly 48 hrs. long
                      which includes extensive number of projects, assignments,
                      quizzes & project presentations.
                    </p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading" role="tab" id="headingThree">
                  <h3 class="panel-title">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse3rd"
                      aria-expanded="false" aria-controls="collapse3rd">What are the benefits of learning Robotics at an
                      early
                      age?
                    </a>
                  </h3>
                </div>
                <div id="collapse3rd" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                  <div class="panel-body">
                    <ul>
                      <li>
                        Numerous Research suggests Robotics as one of the best
                        and practical ways of STEM Education. Students really
                        love it.
                      </li>
                      <li>
                        Project based education increases the logical thinking
                        of kids.
                      </li>
                      <li>
                        Projects also increases the Problem-solving abilities of
                        kids.
                      </li>
                      <li>One of the best ways of increasing IQ levels.</li>
                      <li>
                        Induces creativity as kids are motivated to use their
                        own imagination to build projects.
                      </li>
                      <li>
                        Helps develops the confidence of a child as they
                        participate in various Robotics competitions.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading" role="tab" id="headingfour">
                  <h3 class="panel-title">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse4st"
                      aria-expanded="false" aria-controls="collapse4st">How different Mechatron robotics is and why you
                      should be
                      part of it?
                    </a>
                  </h3>
                </div>
                <div id="collapse4st" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingfour">
                  <div class="panel-body">
                    <p>
                      At Mechatron we not only develop the students in technical
                      things but also various non-technical skills. Our Trainers
                      are mainly from electronics background and provide really
                      great knowledge compared to any other company. Our Courses
                      are also designed in-depth for 48 hours and affordable for
                      students. Read above the 30+ reasons how we are different
                      from any other similar company in the world.
                    </p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading" role="tab" id="headingfive">
                  <h3 class="panel-title">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse5nd"
                      aria-expanded="false" aria-controls="collapse5nd">How can I track my child’s progress?</a>
                  </h3>
                </div>
                <div id="collapse5nd" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingfive">
                  <div class="panel-body">
                    <p>
                      Our trainers share the progress of the child regularly
                      with the parents. We also provide a detailed evaluation on
                      various skills like creativity, efforts taken to build
                      extra projects, team work, presentation and communication
                      skills, perseverance, problem solving skills and logical
                      thinking at the end of the course. We also take periodic
                      quizzes, give assignments to assess a child's
                      understanding of the subject.
                    </p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading" role="tab" id="headingsix">
                  <h3 class="panel-title">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse6rd"
                      aria-expanded="false" aria-controls="collapse6rd">
                      Do you provide certificate, after completion of courses?
                    </a>
                  </h3>
                </div>
                <div id="collapse6rd" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingsix">
                  <div class="panel-body">
                    <p>
                      Yes, we do provide certificates after the successful
                      completion of the course. The student needs to give a
                      final exam and a presentation on Graduation Day in order
                      to earn his certificate.
                    </p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading" role="tab" id="headingseven">
                  <h3 class="panel-title">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse7th"
                      aria-expanded="false" aria-controls="collapse7th">
                      Do the students need to be technically sound in order to
                      avail online/ offline courses?
                    </a>
                  </h3>
                </div>
                <div id="collapse7th" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headinseven">
                  <div class="panel-body">
                    <p>
                      No, since we provide the courses from fundamental level to
                      advanced level, any student can enrol as per his/her
                      interest. Most important criteria is they should have
                      interest towards technology & learn these skills.
                    </p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading" role="tab" id="headingeigt">
                  <h3 class="panel-title">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse8th"
                      aria-expanded="false" aria-controls="collapse8th">
                      Do you think the course increases the out-of-school
                      learning opportunities?
                    </a>
                  </h3>
                </div>
                <div id="collapse8th" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingeight">
                  <div class="panel-body">
                    <p>
                      Yes, our courses increase the out of school learning
                      opportunities as the school courses are designed according
                      to a certain curriculum whereas our courses are more
                      practical and framed in a way that relates to day-to-day
                      activities and the practical world. This makes students
                      more enthusiastic towards the learning process and as they
                      can apply the knowledge and build something innovative.
                    </p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading" role="tab" id="headingnine">
                  <h3 class="panel-title">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse9th"
                      aria-expanded="false" aria-controls="collapse9th">
                      Do you provide kits along with the course?
                    </a>
                  </h3>
                </div>
                <div id="collapse9th" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingnine">
                  <div class="panel-body">
                    <p>
                      Kits are not required for any of the online courses.
                      However students can buy the kits by paying extra as per
                      the kit price. And we deliver it to their home. Even for
                      the Offline / Classroom based courses the training centre
                      / Lab has all the Kits and equipment for the students to
                      learn. However students can buy the kits by paying extra
                      as per the kit price.
                    </p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading" role="tab" id="headingten">
                  <h3 class="panel-title">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse10th"
                      aria-expanded="false" aria-controls="collapse10th">
                      Are the teachers easily accessible for the students who
                      need extra help?
                    </a>
                  </h3>
                </div>
                <div id="collapse10th" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingten">
                  <div class="panel-body">
                    <p>
                      Yes. Our teachers help students achieve extra mile and
                      extra projects, by mentoring them. In case of any
                      competitions, our trainers help students to solve various
                      tasks and challenges as per the guidelines of the
                      competition. Our students have achieved many national and
                      international level championships with the help of their
                      mentors.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-md-12">
        <div class="faq">
          <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
            <div class="panel">
              <div class="panel-heading" role="tab" id="headingfour">
                <h3 class="panel-title">
                  <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse4st"
                    aria-expanded="false" aria-controls="collapse4st">How different Mechatron robotics is and why you should be
                   <span style="margin-left: 33px;"> part of it?</span>
                  </a>
                </h3>
              </div>
              <div id="collapse4st" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingfour">
                <div class="panel-body">
                  <p>At Mechatron we not only develop the students in technical things but also various non-technical skills. Our Trainers are mainly from electronics background and provide really great knowledge compared to any other company. Our Courses are also designed in-depth for 48 hours and affordable for students. Read above the 30+ reasons how we are different from any other similar company in the world.</p>

                </div>
              </div>
            </div>
            <div class="panel">
              <div class="panel-heading" role="tab" id="headingfive">
                <h3 class="panel-title">
                  <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse5nd"
                    aria-expanded="false" aria-controls="collapse5nd">How can I track my child’s progress?</a>
                </h3>
              </div>
              <div id="collapse5nd" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingfive">
                <div class="panel-body">
                  <p>Our trainers share the progress of the child regularly with the parents. We also provide a detailed evaluation on various skills like creativity, efforts taken to build extra projects, team work, presentation and communication skills, perseverance, problem solving skills and logical thinking at the end of the course. We also take periodic quizzes, give assignments to assess a child's understanding of the subject.</p>

                </div>
              </div>
            </div>
            <div class="panel ">
              <div class="panel-heading" role="tab" id="headingsix">
                <h3 class="panel-title">
                  <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse6rd"
                    aria-expanded="false" aria-controls="collapse6rd">
                    Do you provide certificate, after completion of courses?
                    </a>
                </h3>
              </div>
              <div id="collapse6rd" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingsix">
                <div class="panel-body">
                  <p>Yes, we do provide certificates after the successful completion of the course. The student needs to give a final exam and a presentation on Graduation Day in order to earn his certificate.</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  -->
      </div>
    </div>
    <br />
    <br />
    <div class="container">
      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4"></div>
        <div class="col-md-2 back-img-right"></div>
      </div>
      <br />
      <br />
      <div class="container">
        <div class="row">
          <div class="col-md-4"></div>
          <div class="col-md-4"></div>
          <div class="col-md-2 back-img-right"></div>
        </div>
      </div>
    </div>
  </div>
</div>
