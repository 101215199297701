import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Address, EMAIL, Enquiry, PHONE } from '../book-trail-class-page/landing-page.model';
import { CourseDataService } from '../course-data.service';

@Component({
  selector: 'app-contactus-page',
  templateUrl: './contactus-page.component.html',
  styleUrls: ['./contactus-page.component.css']
})
export class ContactusPageComponent implements OnInit {

 
  onSubmitBtn: boolean = false;
  downloadbtn: boolean = false;
  enquiry: Enquiry;
  dial = '61';
  mobile!: String;
  name!: String;
  alternate_mobile!: null;
  formValidationErrors: any;
  currDate = new Date();
  @ViewChild('form', { static: false }) form: any;
  constructor(private courseData: CourseDataService, private router: Router,
   ) {
    this.enquiry = new Enquiry();
    this.enquiry.query_date = this.currDate;
    this.enquiry.address = new Address();
    this.enquiry.PHONE = new Array<PHONE>();
    this.enquiry.EMAIL = new Array<EMAIL>();
    this.enquiry.PHONE.push(new PHONE());
    this.enquiry.EMAIL.push(new EMAIL());
    this.formValidationErrors = this.courseData.getFormValidationErrors();
    // /book-trial-class?utm_source=OLX&utm_medium=Banner&utm_campaign=Summer%20Campaign
    if (this.router.url.split('?')[1]) {
      let UrlStr = this.router.url.split('?')[1].split('&');
      let tempArr: any = [];
      for (let index = 0; index < UrlStr.length; index++) {
        tempArr.push(UrlStr[index].split('='));
      }
      let utmDict = [];
      for (let i = 0; i < tempArr.length; i++) {
        utmDict.push({
          key: tempArr[i][0],
          value: tempArr[i][1],
        });
      }
      var body: { [key: string]: string } = {};
      for (var x in utmDict) {
        body[utmDict[x].key] = utmDict[x].value;
      }
      // console.log('this.enquiry.utmObj', body[utmDict[x].key]);
      this.enquiry = {
        ...this.enquiry,
        ...body,
      };
    }
  }

  ngOnInit(): void {
    //​window.scrollTo(0, 0);
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }
    if (!this.downloadbtn) {
      alert("Click on Terms and Privacy Policy Check box!!!!")
      return;
    }
    this.name=this.enquiry.name
    this.enquiry.PHONE[0].VALUE = '+' + this.dial + ' ' + this.mobile;
    // if(this.alternate_mobile){
    // this.enquiry.PHONE[1].VALUE = '+' + this.dial + ' ' + this.alternate_mobile;
    // }
    this.enquiry.notification_mail = true;
    this.enquiry.scope="Lead"
    this.onSubmitBtn = true;
    console.log("enquiry Data",this.enquiry);
    this.courseData.createEnquiry(this.enquiry).subscribe((res) => {
      console.log('enq', res);
      this.form.reset();
      this.onSubmitBtn = false;
      alert("Dear "+this.name+" Thank you for connecting to Mechatron Robotics! We appreciate you contacting  Mechatron Robotics. One of our colleagues will get back in touch with you soon! Have a great day!")
      //this.router.navigate(['/sponsors']);
    });
  }

}
