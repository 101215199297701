import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DownloadBoucherFormComponent } from './download-boucher-form/download-boucher-form.component';

@Component({
  selector: 'app-sponsors-page',
  templateUrl: './sponsors-page.component.html',
  styleUrls: ['./sponsors-page.component.css'],
})
export class SponsorsPageComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
    //​window.scrollTo(0, 0);
  }
  openForm(): void {
    const dialogRef = this.dialog.open(DownloadBoucherFormComponent, {
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  //Download Open Form
  openDialog(){
    this.openForm()
  }
}
