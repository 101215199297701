import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.css']
})
export class RatingComponent implements OnInit {
  @Input() max: number |any;
  @Output() onRating = new EventEmitter<number>();
  @Input() setRating : number |any;

  public maxItem: any[] |any;
  public ratedCount: number;

  constructor(private router: Router,) {
    this.ratedCount = 0;
    // this.maxItem=new Array<any>()
   }

  ngOnInit() {
    this.maxItem = [];
    this.ratedCount = this.setRating;

    for (let i = 0; i < this.max; i++) {
      this.maxItem.push(i + 1);
    }
  }

  public toggleRating(s: number): void {
    this.ratedCount = s;
    this.onRating.emit(this.ratedCount);
  }

  getValue(data:any){
    return (this.ratedCount + "").split(".")[1]+1
  }

}
