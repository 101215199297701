import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-term-and-condition-page',
  templateUrl: './web-term-and-condition-page.component.html',
  styleUrls: ['./web-term-and-condition-page.component.css']
})
export class WebTermAndConditionPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

}
