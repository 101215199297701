<div class="hero-image">
  <div class="container">
    <div class="row">
      <div class="col-md-6 top-margin-top">
        <div class="d-flex flex-column" style="margin-top: 55px !important">
          <p class="p-head">
            <span class="head-text-black">Enriching the</span><br />
            <span class="le">Young minds of</span>
            <span class="head-text-org"> AUSTRALIA</span><br />
            <span class="le">with</span>
            <span class="head-text-black"> Robotics and AI
            </span>
          </p>

          <p class="banner-small-text-second mt-3" style="color: #333333">
            Empowering the next generation for educational advancement in the field of Robotics and AI.
          </p>
          <p class="banner-small-text-third">
            <span class="orangeText text-font-gray" style="font-family: sans-serif !important; font-size: 18px"> 50,000+
              Students
            </span>
            <span class="text-font-gray" style="color: gray; font-weight: 600">
              trained and mentored globally since 2018</span>
          </p>
          <!-- <div style="margin-top: 20px">
            <a routerLink="/courses">
              <button type="button" class="my-btn">START LEARNING</button></a>
          </div> -->
        </div>
      </div>
      <div class="col-md-6">
        <img src="../../../../../assets/images/Banner.png" alt="child" class="mtp-63 banerimg"
          style="width: 90%; margin-top: 85px !important" />
      </div>
    </div>
  </div>
</div>

<section class="first-top mg-top">
  <div class="container text-center g-max-width-950">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6 col-sm-6">
          <img alt="child" src="../../../../../assets/images/About us.png" class="w-80" />
        </div>
        <div class="col-md-6 col-sm-6">
          <div><span class="orangeText about">About </span></div>
          <h2 class="leftside mb-4 mt-4">
            <span class="lef">Mechatron Robotics</span><sup class="mb-2">&reg;</sup>
          </h2>
          <div class="left">
            <p class="text-font-p">
              Mechatron Robotics is the pioneer & leader in the field of Robotics and Artificial Intelligence and has
              trained more than <strong> 50,000+ students</strong> across the world by developing a strong project-based
              curriculum for
              the students.<strong> Mechatron Robotics</strong> started with a focus developing young minds aged
              <strong> 7 years+</strong>, to prepare them for <strong>Industry 4.0 </strong>by providing practical exposure & project-based training to
              students and developing core skills in the field of<strong> Coding, Robotics & AI.</strong>
            </p>
          </div>
          <a routerLink="/aboutus">
            <button type="button" class="my-btn mt-4" style="float: left">
              READ MORE...
            </button></a>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <section class="mg-top first-top neurapses-bg">
  <div class="container text-center g-max-width-950">
    <div class="col-md-12">
      <img
        src="../../../../../assets/images/icons/nt-logo.png"
        class="nt-logo mt-5"
      />
      <div>
        <p class="nt-logo-text p-4" style="text-align: justify">
          Neurapses Technologies is one of the leading machine learning
          companies, working on next-generation technologies to provide smart
          solutions to businesses. We develop cutting-edge solutions for every
          business problem. Our expertise extends to provide end to end AI and
          ML solutions like Automated Document processing, Image Processing,
          Information Extraction, Automation & Management of Receipts, Bills,
          Forms, Expense Management, Product Development, OCR, Data Analytics,
          and many more to give you higher productivity and increased cost
          savings. We provide various applications in the Retail, Financial
          Service, Healthcare, Manufacturing, Logistics, and Education
          industries.
        </p>
      </div>
      <div>
        <a href="https://neurapses.com/" target="blank">
          <button type="button" class="my-btn mb-5 w-45">
            GO TO THE OFFICIAL WEBSITE
          </button>
        </a>
      </div>
    </div>
  </div>
</section> -->


<div class="left-back-img mg-top first-top">
  <section class="left-back-img">
    <div class="container text-center g-max-width-950">
      <!-- <h1>Top
       <span style="color: #ff8500"> Coding, Robotics </span> and <span style="color: #ff8500">AI</span> Classes in <span style="color: #ff8500"> Australia</span>
      </h1> -->
      <h2>
        <span class="lef"> Our</span>
        <span style="color: #ff8500" class="lef"> Services</span>
        <!-- <span class="lef"> for You</span> -->
      </h2>
      <span class="text-cetner">
        We offer a wide range of services under Robotics.<br />
        Click on the images below to know more about each of the services in
        details.
      </span>

      <!-- <img alt="child" src="../../../../../assets/images/Banner.png" class="map-img"> -->
      <div class="row mt-5">
        <div class="col-md-2"></div>
        <div class="col-md-4">
          <div class="course-card">
            <div class="card-body">
              <a routerLink="/courses">
                <img class="card-img-top serviceimages" src="../../../../../assets/images/ourCourse.png"
                  alt="Card image cap" />
              </a>
              <div class="cardcolor mt-4">
                <div class="card-title text-center">Our Courses</div>
              </div>
              <p class="text-font-card">
                Year long Certified Robotics courses from Fundamental to
                Advanced level of Robotics.<br /><br />
              </p>
              <!-- <hr />
              <div class="btncenter" style="margin-bottom: 15px">
                <a routerLink="/courses">
                  <button type="button" class="btn btn-outline-light apply-now">
                    VIEW DETAILS
                  </button></a>
              </div> -->
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="course-card">
            <div class="card-body">
              <!-- routerLink="/schools" -->
              <a>
                <img class="card-img-top  serviceimages" src="../../../../../assets/images/ourprogram.png"
                  alt="Card image cap" />
              </a>
              <div class="cardcolor mt-4">
                <div class="card-title text-center">Program for Schools</div>
              </div>
              <p class="text-font-card">
                For schools, colleges and corporations short duration Robotics
                Workshops cover various topics related to Robotics, AI and ML.
              </p>
               <!-- routerLink="/schools" -->
              <!-- <hr />
              <div class="btncenter" style="margin-bottom: 15px">
                <a>
                  <button type="button" class="btn btn-outline-light apply-now">
                    VIEW DETAILS
                  </button></a>
              </div> -->
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>

        <!-- <div class="col-md-4">
          <div class="course-card">
            <div class="card-body">
              <a routerLink="/franchisee">
                <img
                  class="card-img-top"
                  src="../../../../../assets/images/Serviece 3.png"
                  width="640"
                  height="240"
                  alt="Card image cap"
                />
              </a>
              <div class="cardcolor mt-4">
                <div class="card-title text-center">Robotics Franchisee</div>
              </div>
              <p class="text-font-card mt-2">
                Partner with us as a Franchise to spread the knowledge of
                Robotics to the young, upcoming generation of Australia.
              </p>
              <hr />
              <div class="btncenter" style="margin-bottom: 15px">
                <a routerLink="/franchisee">
                  <button type="button" class="btn btn-outline-light apply-now" style="cursor: none;">
                    VIEW DETAILS
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </section>
</div>

<!-- certificate -->
<section class="mg-top first-top certificate-bg">
  <div class="container text-center">
    <div class="row" style="padding: 40px 0px 50px 0px">
      <div class="col-md-7 col-sm-7 col-xs-7 mb-7">
        <div class="row">
          <div class="col-md-9 col-sm-9 col-xs-9 mb-9 mt-3" align="left">
            <p style="line-height: 40px">
              <span class="lef">Commits to Excellence - <br /></span>
              <span class="lef">Global
                <span style="color: #ff8500" class="lef"> Accreditation </span>
              </span>
            </p>
            <p class="certificate-head">
              With STEM.org Accredited™ Experience Mechatron Robotics ensures
              quality education for its students.
            </p>
          </div>
          <div class="col-md-3 col-sm-3 col-xs-3 mb-3">
            <img src="../../../../../assets/images/logo.png" width="100%" height="auto" alt="stem-logo"
              class="mt-4 logo" />
          </div>
        </div>
        <p class="certificate-para">
          Mechatron Robotics gets recognized as Internationally accredited body
          by STEM.org. We assure the quality education to strengthen and enhance
          the growth of students to rise and expand in future with practical
          exposure to students on technological aspects with right set of
          technical & non-technical skill set. STEM.org is one of the leading,
          valuable accreditation bodies of STEM in the International Community &
          provides the leading & most affordable STEM education credentialing
          pathway for educators.
        </p>
        <br />
        <a href="https://www.credential.net/a31fbf73-0f40-43e9-b2ec-941ae49cabbb#gs.gv094a" target="blank"
          class="send-now">View Accreditation
        </a>
      </div>
      <div class="col-md-5 col-sm-5 col-xs-5 mb-5 mt-2">
        <img src="../../../../../assets/images/Group 440.png" width="100%" height="auto" alt="certificate"
          class="mt-5" />
      </div>
    </div>
  </div>
</section>

<section class="mg-top first-top right-back-img">
  <div class="container text-center g-max-width-950">
    <h2>
      <span class="lef"> Develop </span>
      <span style="color: #ff8500" class="lef"> Key Skills</span>
      <span class="lef"> from </span>
      <span style="color: #ff8500" class="lef"> Holistic Learning</span>
    </h2>
    <p class="text-cetner">
      Step into the world of futuristic courses, discovering a new passion and
      developing all of the <br />technical and non-technical skills with
      project based learning.
    </p>
    <div class="container">
      <div class="row">
        <div class="col-md-12" align="center">
          <div id="carouselExampleIndicators1" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row">
                  <div class="col-md-1"></div>
                  <div class="col-md-2 text-center mg-top">
                    <img class="img-fluid svgimg" src="../../../../../assets/images/skills 1.png" alt="First slide" />
                    <br />
                    <p class="logical">Logical Thinking</p>
                    <p class="logic mgtop orange-text" style="color: #ff8500">
                      90% <i class="fas fa-arrow-up logcl"></i>
                    </p>
                  </div>
                  <div class="col-md-1 text-center mg-top">
                    <img class="img-fluid svgimg-logo" src="../../../../../assets/images/Orange.png"
                      alt="First slide" />
                    <br />
                  </div>
                  <div class="col-md-2 text-center mg-top">
                    <img class="img-fluid svgimg" src="../../../../../assets/images/skills 2.png" alt="Second slide" />
                    <br />
                    <p class="logical">Confidence Level</p>
                    <p class="logic mgtop" style="color: #2f5698">
                      85% <i class="fas fa-arrow-up logcl"></i>
                    </p>
                  </div>
                  <div class="col-md-1 text-center mg-top">
                    <img class="img-fluid svgimg-logo" src="../../../../../assets/images/blue.png" alt="Second slide" />
                    <br />
                  </div>
                  <div class="col-md-2 text-center mg-top">
                    <img class="img-fluid svgimg" src="../../../../../assets/images/skills 3.png" alt="Second slide" />
                    <br />
                    <p class="logical">Perseverance</p>
                    <p class="logic mgtop orange-text" style="color: #ff8500">
                      89% <i class="fas fa-arrow-up logcl"></i>
                    </p>
                  </div>
                  <div class="col-md-1 text-center mg-top">
                    <img class="img-fluid svgimg-logo" src="../../../../../assets/images/Orange.png"
                      alt="Second slide" />
                    <br />
                  </div>
                  <div class="col-md-2 text-center mg-top">
                    <img class="img-fluid svgimg" src="../../../../../assets/images/skills 4.png" alt="Second slide" />
                    <br />
                    <p class="logical">Creativity</p>
                    <p class="logic mgtop" style="color: #2f5698">
                      86% <i class="fas fa-arrow-up logcl"></i>
                    </p>
                    <br />
                    <br />
                  </div>
                  <div class="col-md-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="mg-top first-top">
  <div class="images pbtm">
    <div class="container g-max-width-950">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3 col-sm-3 mtp-80">
            <span>
              <img class="img-fluid w-17 w-171" src="../../../../../assets/images/training.png" alt="First slide" />
            </span>
            <span class="log-24"> 960,000+</span>
            <p class="trin">
              <span>Training Hrs.</span><br /><span>since 2018</span>
            </p>
          </div>
          <div class="col-md-3 col-sm-3 mtp-80">
            <img class="img-fluid w-15 lss" src="../../../../../assets/images/course.png" alt="First slide" /><span
              class="log-24 lt"> 15+</span>

            <p class="trin">
              <span>Levels/Course with</span> <br />
              <span>each course of 48 Hrs.</span>
            </p>
          </div>
          <div class="col-md-3 col-sm-3 mtp-80">
            <img class="img-fluid w-17 ls" src="../../../../../assets/images/location.png" alt="First slide" /><span
              class="log-24 lt"> 30+</span>

            <p class="trin">
              <span>Globally</span>
            </p>
          </div>
          <div class="col-md-3 col-sm-3 mtp-80">
            <img class="img-fluid w-10 lti" src="../../../../../assets/images/project.png" alt="First slide" /><span
              class="log-24 lt"> 500+</span>

            <p class="trin">
              <span>International Projects</span><br /><span>published by Students</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--
<div class="mg-top first-top left-back-img">
  <section class="left-back-img">
    <div class="container text-center g-max-width-950">
      <h2>
        <span class="lef"> Our</span>
        <span style="color: #ff8500" class="lef"> Team</span>
        <span class="lef"> Members</span>
      </h2>
      <span class="text-cetner">
        Our team is composed of highly experienced individuals. They are deeply committed
        <br />
        towards their responsibilities, displaying passion, with experience in
        <br />
        management, administration, social work, marketing and more.
      </span> -->

<!-- <img alt="child" src="../../../../../assets/images/Banner.png" class="map-img"> -->
<!-- <div class="row mt-5">
        <div class="col-md-2"></div>
        <div class="col-md-4">
          <div class="course-card">
            <div class="card-body">
              <div class="container-photo">
                <img
                  class="teme-photo team-img"
                  src="../../../../../assets/images/Team member 1.png"
                  width="100"
                  alt="Card image cap"
                  style="border-radius: 5px"
                  (mouseover)="showProfileHeath()"
                  (mouseleave)="hideProfileHeath()"
                /> -->
<!-- <div class="facebook">
                  <a href="#"><i class="fab fa-facebook socal-icon"></i></a>
                </div>
                <div class="tuter">
                  <a href="#"><i class="fab fa-twitter socal-icon"></i></a>
                </div>
                <div class="insta">
                  <a href="#"><i class="fab fa-instagram socal-icon"></i></a>
                </div>
                <div class="linkin">
                  <a
                    href="https://www.linkedin.com/in/heathkirbymiller"
                    target="blank"
                    ><i class="fab fa-linkedin socal-icon"></i
                  ></a>
                </div> -->
<!-- </div>
              <div class="cardcolor mt-4" *ngIf="!showprofileHeath">
                <mat-card-title class="fontsizee" style="color: #333333"
                  >Dr. Heath Kirby-Miller
                </mat-card-title>
                <hr />
                <p
                  class="card-text text-post"
                  style="margin-bottom: 22px; font-size: 15px"
                >
                  PHD. MANAGEMENT <br />
                </p>
              </div>
              <div *ngIf="showprofileHeath" style="margin-top: 30px">
                <p class="text-font-p" style="text-align: center">
                  Dr. Heath Kirby-Miller has accumulated 25 years of business
                  knowledge across franchising, education, finance, advertising,
                  and marketing. He is the STEM partner & Master Franchisee of
                  Mechatron Robotics in Australia. He also teaches at numerous
                  colleges and Australian universities.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="course-card">
            <div class="card-body">
              <div class="container-photo">
                <img
                  class="card-img-top team-img"
                  src="../../../../../assets/images/Team member 2.png"
                  width="640"
                  alt="Card image cap"
                  style="border-radius: 5px"
                  (mouseover)="showProfileChristine()"
                  (mouseleave)="hideProfileChristine()"
                /> -->
<!-- <div class="facebook">
                <a href="#"><i class="fab fa-facebook socal-icon"></i></a>
              </div>
              <div class="tuter">
                <a href="#"><i class="fab fa-twitter socal-icon"></i></a>
              </div>
              <div class="insta">
                <a href="#"><i class="fab fa-instagram socal-icon"></i></a>
              </div>
              <div class="linkin">
                <a href="#"><i class="fab fa-linkedin socal-icon"></i></a>
              </div> -->
<!-- </div>
              <div class="cardcolor mt-4" *ngIf="!showprofileChristine">
                <mat-card-title class="fontsizee" style="color: #333333"
                  >Christine Kirby-Miller
                </mat-card-title>
                <hr />
                <mat-card-content
                  class="card-text text-post"
                  style="margin-bottom: 0px; font-size: 15px"
                >
                  HEAD OFFICE COORDINATOR, <br />
                  EDUPRENEUR
                </mat-card-content>
              </div>
              <div
                align="justify"
                *ngIf="showprofileChristine"
                style="margin-top: 30px"
              >
                <p class="text-font-p" style="text-align: center">
                  Christine is an upcoming Edupreneur who has a professional
                  degree in social work and has a passion towards Social work &
                  Education. She likes to work on solving social issues and also
                  work for the benefits of under privileged children and young
                  people in the STEM space.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2"></div> -->
<!-- <div class="col-md-4">
          <mat-card class="example-card cardhead1">
            <div class="container-photo">
              <img
                class="card-img-top team-img"
                src="../../../../../assets/images/Team member 3.png"
                width="640"
                alt="Card image cap"
                style="border-radius: 5px"
                (mouseover)="showProfileTony()"
                (mouseleave)="hideProfileTony()"
              />
              <div class="facebook">
                <a href="#"><i class="fab fa-facebook socal-icon"></i></a>
              </div>
              <div class="tuter">
                <a href="#"><i class="fab fa-twitter socal-icon"></i></a>
              </div>
              <div class="insta">
                <a href="#"><i class="fab fa-instagram socal-icon"></i></a>
              </div>
              <div class="linkin">
                <a href="#"><i class="fab fa-linkedin socal-icon"></i></a>
              </div>
            </div>
            <div class="cardcolor mt-4" *ngIf="!showprofileTony">
              <mat-card-title class="fontsizee">Tony Clemenger </mat-card-title>
              <hr />
              <mat-card-content
                class="card-text text-post"
                style="margin-bottom: 22px; font-size: 15px"
              >
                MEDIA ADVISOR <br />
              </mat-card-content>
            </div>
            <div
              align="justify"
              *ngIf="showprofileTony"
              style="margin-top: 30px"
            >
              <p class="text-font-p" style="text-align: center">
                A senior executive with experience spanning: advertising,
                marketing, sales and creative / digital / media / strategy.
                International Experience: corporate & small to medium
                enterprises profile. A leader with a career demonstrating
                outstanding performance in advertising, marketing and sales. A
                wise, educated, creative and proactive director / manager (also
                been a Chair, President, Board Member). A visionary, strategist
                and profit-driven director.
              </p>
            </div>
          </mat-card>
        </div> -->
<!-- </div>
    </div>
  </section>
</div> -->

<!-- <section id="blogs" class="g-py-10" style="margin-top: 150px">
  <div class="container text-center g-max-width-950 g-mb-50">
    <h2>
      <span class="lef">Featured </span>
      <span style="color: #ff8500" class="lef"> Posts </span>
      <span class="lef">/ </span>
      <span class="lef" style="color: #ff8500"> Blogs</span>
    </h2>
    <span class="text-center">
      Explore the latest infromation about technology trends, education.
    </span>
    <div class="row mt-5">
      <div class="col-md-6 col-sm-6 col-xs-12 mb-4">
        <div class="p-4 border">
          <img
            src="../../../../../assets/images/blog 1.png"
            width="100%"
            height="auto"
            alt=""
          />
          <p class="blog-comt-org">MAY 10, 2020</p>
          <h4 class="le-blog">
            Does our education system prepare us well for a better life ?
          </h4>
          <div style="margin-bottom: 27px">
            <p
              class="text-cetner"
              style="text-align: justify; max-height: 250px"
            >
              EA common question to all my readers ; Does our Education system
              prepare us well for practical life ? Does a student from
              prestigious colleges like IITs/IIMs know how to face catastrophic
              situations in life?
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </p>
          </div>
          <p class="mb-3"></p>
          <div class="row">
            <div class="col-md-6">
              <p class="g-color-gray-opacity-0_5 mb-0 blog-post-by mt-4">
                <strong>Posted By:</strong>
                <span class="blog-comt-blue"> Rajesh Sharma</span>
              </p> -->

<!--<p style="text-align:left;font-family: 'PT Sans Caption', sans-serif;font-size:15px;font-weight:500;" class="g-color-gray-opacity-0_5 mb-0"> <strong>Date:</strong> 1<sup>st</sup> Nov 2017 </p>-->
<!-- </div>
            <div class="col-md-6 mt-2"> -->
<!-- <a routerLink="/blogs/education-system">
                <button
                  type="button"
                  class="form-control btn franchise-button mt-3"
                >
                  Continue Reading...
                </button></a
              > -->
<!-- <p class="blog-comt-org" style="float: right">4 Comments</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-6 col-xs-12 mb-4">
        <div class="p-4 border">
          <img
            src="../../../../../assets/images/blog 2.png"
            width="100%"
            height="auto"
            alt=""
          />
          <p class="blog-comt-org">OCTOBER 12, 2021</p>
          <h4 class="le-blog">
            Engineers are made at College or at Work<br />
            Place?
          </h4>
          <div style="max-height: 150px">
            <p class="text-cetner" style="text-align: justify">
              Today we want to discuss the real definition of an engineering
              degree or in other words when a person really becomes as an
              engineer. It’s true that as soon as you graduate from the
              engineering college, you are liable to be called as an Engineer.
            </p>
          </div>
          <p></p>
          <div class="row">
            <div class="col-md-6">
              <p class="g-color-gray-opacity-0_5 mb-0 blog-post-by mt-4">
                <strong>Posted By:</strong>
                <a
                  class="decoration-none blog-comt-blue"
                  href="https://www.linkedin.com/in/banwas-nayak-8384a8b6/"
                  target="_blank"
                  >Banwas Nayak</a
                >
              </p> -->
<!-- <p style="text-align:left;font-family: 'PT Sans Caption', sans-serif;font-size:15px;font-weight:500;" class="g-color-gray-opacity-0_5 mb-0"> <strong>Date:</strong> 1<sup>st</sup> Dec 2017 </p> -->
<!-- </div>
            <div class="col-md-6"> -->
<!-- <a routerLink="/blogs/engineers">
                <button
                  type="button"
                  class="form-control btn franchise-button mt-3"
                >
                  Continue Reading...
                </button></a
              > -->
<!-- <p class="blog-comt-org" style="float: right">4 Comments</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->

<!-- traning center -->
<!-- <div
  class="
    margin_top
    container-fluid
    images
    d-flex
    justify-center
    align-items-center
  "
> -->
<div class="
    margin_top
    container-fluid
    d-flex
    justify-center
    align-items-center
  ">
  <div class="container mt-4 mb-4">
    <div class="col-md-12 text-center p-3">
      <h2 class="orangeText seaction-heading" style="font-size: 16px; font-weight: 800">
        Join Us Today
      </h2>
      <h2 class="boldText">Enrol for our latest courses, Click Below</h2>
      <a type="button" class="my-btn" href="/book-trial-class">
        BOOK TRIAL CLASS NOW
      </a>
    </div>
  </div>
</div>

<!-- FAQ -->
<div class="margin_top container" style="margin-bottom: 80px">
  <h2 class="blackText seaction-heading">
    <span class="boldText">Frequently</span> &nbsp;asked&nbsp;<span class="orangeText">Questions</span>
  </h2>

  <div class="row">
    <div class="col-md-12">
      <div class="faq">
        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
          <div class="panel">
            <div class="panel-heading" role="tab" id="headingOne">
              <h3 class="panel-title">
                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse1st"
                  aria-expanded="false" aria-controls="collapse1st"><span>What skills do the students develop at
                    Mechatron robotics?</span>
                </a>
              </h3>
            </div>
            <div id="collapse1st" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
              <div class="panel-body">
                <p>
                  Students learn various technical skills like Coding, Electronics, Mechanics, Mechanical design,
                  Arduino, IOT and many more practical courses. Apart from the technical skills students also develop
                  various non-technical skills like creativity, innovation, communication skills, logical thinking,
                  problem solving skills & teamwork.
                </p>
              </div>
            </div>
          </div>
          <div class="panel">
            <div class="panel-heading" role="tab" id="headingTwo">
              <h3 class="panel-title">
                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse2nd"
                  aria-expanded="false" aria-controls="collapse2nd">How long does it take to complete a
                  courses/levels?</a>
              </h3>
            </div>
            <div id="collapse2nd" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
              <div class="panel-body">
                <p>
                  Most of our courses are developed keeping in mind the students develop a complete understanding of the
                  topic. So, each of our courses/levels is nearly 48 hrs. long which includes an extensive number of
                  projects, assignments, quizzes & project presentations.
                </p>
              </div>
            </div>
          </div>
          <div class="panel">
            <div class="panel-heading" role="tab" id="headingThree">
              <h3 class="panel-title">
                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse3rd"
                  aria-expanded="false" aria-controls="collapse3rd">What are the benefits of learning Robotics at an
                  early age?
                </a>
              </h3>
            </div>
            <div id="collapse3rd" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
              <div class="panel-body">
                <ul>
                  <li>
                    Numerous Research suggests Robotics as one of the best and most practical ways of STEM Education.
                    Students really love it.
                  </li>
                  <li>
                    Project based education increases the logical thinking of
                    kids.
                  </li>
                  <li>
                    Projects also increases the Problem-solving abilities of
                    kids.
                  </li>
                  <li>One of the best ways of increasing IQ levels.</li>
                  <li>
                    Induces creativity as kids are motivated to use their own
                    imagination to build projects.
                  </li>
                  <li>
                    It helps develop the confidence of a child as they participate in various Robotics competitions.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="panel">
            <div class="panel-heading" role="tab" id="headingfour">
              <h3 class="panel-title">
                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse4st"
                  aria-expanded="false" aria-controls="collapse4st">How different Mechatron robotics is and why you
                  should be
                  part of it?
                </a>
              </h3>
            </div>
            <div id="collapse4st" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingfour">
              <div class="panel-body">
                <p>
                  At Mechatron, we not only develop the students in technical skills but also with various non-technical
                  skills. Our trainers provide really great knowledge with project-based learning compared to any other
                  company. Our courses are also designed in-depth and are affordable for students.
                </p>
              </div>
            </div>
          </div>
          <div class="panel">
            <div class="panel-heading" role="tab" id="headingfive">
              <h3 class="panel-title">
                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse5nd"
                  aria-expanded="false" aria-controls="collapse5nd">How can I track my child’s progress?</a>
              </h3>
            </div>
            <div id="collapse5nd" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingfive">
              <div class="panel-body">
                <p>
                  Our trainers share the progress of the child regularly with the parents. We also provide a detailed
                  evaluation on various skills like creativity, efforts made to build extra projects, teamwork,
                  presentation and communication skills, perseverance, problem solving skills and logical thinking at
                  the end of the course. We also take periodic quizzes, give assignments to assess a child's
                  understanding of the subject.
                </p>
              </div>
            </div>
          </div>
          <!-- <div class="panel">
            <div class="panel-heading" role="tab" id="headingsix">
              <h3 class="panel-title">
                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse6rd"
                  aria-expanded="false" aria-controls="collapse6rd">
                  Do you provide certificate after completion of courses?
                </a>
              </h3>
            </div>
            <div id="collapse6rd" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingsix">
              <div class="panel-body">
                <p>
                  Yes, we do provide certificates after the successful
                  completion of the course. The student needs to give a final
                  exam and a project presentation in order to earn his/her
                  certificate.
                </p>
              </div>
            </div>
          </div> -->
          <div class="panel">
            <div class="panel-heading" role="tab" id="headingseven">
              <h3 class="panel-title">
                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse7th"
                  aria-expanded="false" aria-controls="collapse7th">
                  Do the students need to be technically sound in order to join
                  online/offline courses?
                </a>
              </h3>
            </div>
            <div id="collapse7th" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headinseven">
              <div class="panel-body">
                <p>
                  No, since we provide courses from fundamental level and advanced level, any student can enroll as per
                  his/her interest. The most important criteria is to have an interest towards technology and learn the
                  range of skills that Mechatron Robotics can teach.
                </p>
              </div>
            </div>
          </div>
          <div class="panel">
            <div class="panel-heading" role="tab" id="headingeigt">
              <h3 class="panel-title">
                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse8th"
                  aria-expanded="false" aria-controls="collapse8th">
                  Do you think the course increases the out-of-school learning
                  opportunities?
                </a>
              </h3>
            </div>
            <div id="collapse8th" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingeight">
              <div class="panel-body">
                <p>
                  Yes, our courses increase the out of school learning opportunities as the school courses align with
                  the curriculum whereas our courses are more practical and framed in a way that relates to day-to-day
                  activities and the practical world. This makes students more enthusiastic towards the learning process
                  and as they can apply the knowledge and build something innovative.
                </p>
              </div>
            </div>
          </div>
          <div class="panel">
            <div class="panel-heading" role="tab" id="headingnine">
              <h3 class="panel-title">
                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse9th"
                  aria-expanded="false" aria-controls="collapse9th">
                  Do you provide kits along with the course?
                </a>
              </h3>
            </div>
            <div id="collapse9th" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingnine">
              <div class="panel-body">
                <p>
                  Kits are not required for any of the online courses. Even for
                  the Offline / Classroom based courses the training centre /
                  Lab has all the Kits and equipment for the students to learn.
                  However students can buy the kits by paying extra as per the
                  kit price.
                </p>
              </div>
            </div>
          </div>
          <div class="panel">
            <div class="panel-heading" role="tab" id="headingten">
              <h3 class="panel-title">
                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse10th"
                  aria-expanded="false" aria-controls="collapse10th">
                  Are the trainers easily accessible for the students who need
                  extra help?
                </a>
              </h3>
            </div>
            <div id="collapse10th" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingten">
              <div class="panel-body">
                <p>
                  Yes. Our trainers help students achieve. They are passionate
                  about educating the next, upcoming generation, and are
                  empowered to work with kids on an individual basis. Our
                  trainers help students to solve various tasks and challenges,
                  according to the requirements of the task they have set for
                  the kids. Globally, Mechatron Robotics students have achieved
                  many national and international level championships with the
                  help of their trainers, who are also their mentors.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="col-md-12">
          <div class="faq">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel">
                <div class="panel-heading" role="tab" id="headingfour">
                  <h3 class="panel-title">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse4st"
                      aria-expanded="false" aria-controls="collapse4st">How different Mechatron robotics is and why you should be
                     <span style="margin-left: 33px;"> part of it?</span>
                    </a>
                  </h3>
                </div>
                <div id="collapse4st" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingfour">
                  <div class="panel-body">
                    <p>At Mechatron we not only develop the students in technical things but also various non-technical skills. Our Trainers are mainly from electronics background and provide really great knowledge compared to any other company. Our Courses are also designed in-depth for 48 hours and affordable for students. Read above the 30+ reasons how we are different from any other similar company in the world.</p>

                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading" role="tab" id="headingfive">
                  <h3 class="panel-title">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse5nd"
                      aria-expanded="false" aria-controls="collapse5nd">How can I track my child’s progress?</a>
                  </h3>
                </div>
                <div id="collapse5nd" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingfive">
                  <div class="panel-body">
                    <p>Our trainers share the progress of the child regularly with the parents. We also provide a detailed evaluation on various skills like creativity, efforts taken to build extra projects, team work, presentation and communication skills, perseverance, problem solving skills and logical thinking at the end of the course. We also take periodic quizzes, give assignments to assess a child's understanding of the subject.</p>

                  </div>
                </div>
              </div>
              <div class="panel ">
                <div class="panel-heading" role="tab" id="headingsix">
                  <h3 class="panel-title">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse6rd"
                      aria-expanded="false" aria-controls="collapse6rd">
                      Do you provide certificate, after completion of courses?
                      </a>
                  </h3>
                </div>
                <div id="collapse6rd" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingsix">
                  <div class="panel-body">
                    <p>Yes, we do provide certificates after the successful completion of the course. The student needs to give a final exam and a presentation on Graduation Day in order to earn his certificate.</p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>  -->
  </div>
</div>
