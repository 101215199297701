<div class="hero-image">
  <div class="container">
    <div class="row">
      <div class="col-md-6 top-margin-top">
        <div class="d-flex flex-column" style="margin-top: 55px !important">
          <p class="top-p mt-5" style="line-height: 45px">
            <span style="color: #ff8500; font-size: 22px; font-weight: 600">About Us</span><br />
            <span class="head-text-black">Upgrading the</span><br />
            <span class="le"> Next Generation with</span><br />
            <span class="head-text-orange">Technological
              <span class="head-text-black">Advancement</span></span>
          </p>
          <br />
          <p class="banner-small-text-second">
            <span class="orangeText text-font-gray" style="
                font-family: sans-serif !important;
                font-weight: 600 !important;
              ">50,000+ Students
            </span>
            <span style="font-weight: 500 !important">
              trained and mentored globally since 2018
            </span>
          </p>
        </div>
      </div>
      <div class="col-md-6">
        <img src="../../../../../assets/images/Banner.png" alt="child" class="mtp-63 banerimg"
          style="width: 90%; margin-top: 85px !important" />
      </div>
    </div>
  </div>
</div>

<!-- about -->
<section class="first-top mg-top left-back-img">
  <div class="container text-center g-max-width-950">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6 col-sm-6">
          <img alt="child" src="../../../../../assets/images/About us.png" class="w-70 firsttop" />
        </div>
        <div class="col-md-6 col-sm-6">
          <div><span class="orangeText about">About </span></div>
          <h2 class="leftside mb-4 mt-4">
            <span class="lef">Mechatron Robotics</span><sup class="mb-2">&reg;</sup>
          </h2>
          <div class="left">
            <p class="text-font-p">
              Mechatron Robotics is the pioneer & leader in the field of Robotics and Artificial Intelligence and has
              trained more than <strong> 50,000+ students</strong> across the world by developing a strong project-based
              curriculum for
              the students.<strong> Mechatron Robotics</strong> started with a focus to develop young minds aged
              <strong> 7+,</strong>
              to prepare them for <strong>Industry 4.0 </strong>by providing practical exposure &
              project-based training to
              students and developing core skills in the field of<strong> Coding, Electronics, Robotics, Artificial Intelligence, Internet of Things, App Development, Embedded Systems using Arduino, Machine Learning, Raspberry Pi & Robot Operating System.</strong>
            </p>
          </div>

          <p class="text-font-p">
            Our global presence extends through a network of 20+ franchises, establishing a substantial footprint
           globally, with headquarters positioned in India, from where
            we steer our global operations.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Our Mission & Vision -->
<section class="first-top mg-top right-back-img">
  <div class="container g-max-width-950">
    <div class="row">
      <div class="col-md-6 col-sm-6">
        <h2>
          <span class="lef"> Our </span>
          <span style="color: #ff8500" class="lef"> Mission </span>
          <span class="lef"> & </span>
          <span style="color: #ff8500" class="lef"> Vision</span>
        </h2>
        <p class="text-font-p mt-4">
          We aspire to inspire the next generation with educational and technological advancements with the best
          platform to equip children with the right skills.
        </p>
        <div class="row mt-4">
          <div class="col-md-4 col-sm-4 col-lg-4 col-xl-4">
            <img alt="child" src="../../../../../assets/images/aboutus_page_images/Group 446.png"
              class="w-70 vis-img" />
          </div>
          <div class="col-md-8 col-sm-8">
            <p style="
                font-family: sans-serif;
                font-weight: 700;
                font-size: 18px;
                color: #333333;
              ">
              Vision
            </p>
            <p class="text-font-p">
              Our vision is to revolutionize education by cultivating a culture in which students acquire the skills to
              innovate and tackle challenges by harnessing the power of Robotics and Artificial Intelligence.
            </p>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-4 col-sm-4">
            <img alt="child" src="../../../../../assets/images/aboutus_page_images/Group 447.png"
              class="w-70 vis-img" />
          </div>
          <div class="col-md-8 col-sm-8" align="justify">
            <p style="
                font-family: sans-serif;
                font-weight: 700;
                font-size: 18px;
                color: #333333;
              ">
              Mission
            </p>
            <p class="text-font-p">
              Our mission is to offer students the finest learning experience by delivering top-tier training courses
              that nurture their creativity and empower them to excel.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-6" align="center">
        <img alt="child" src="../../../../../assets/images/aboutus_page_images/Vector Smart Object.png" class="w-70" />
      </div>
    </div>
  </div>
</section>

<!-- Value -->
<section class="mg-top first-top">
  <div class="images pbtm">
    <div class="container g-max-width-950">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3 col-sm-3 mtp-80">
            <span>
              <img class="img-fluid w-17 w-171" src="../../../../../assets/images/training.png" alt="First slide" />
            </span>
            <span class="log-24"> 960,000+</span>

            <p class="trin">
              <span>Training Hrs.</span><br /><span>since 2018</span>
            </p>
          </div>
          <div class="col-md-3 col-sm-3 mtp-80">
            <img class="img-fluid w-15 lss" src="../../../../../assets/images/course.png" alt="First slide" /><span
              class="log-24 lt"> 15+</span>

            <p class="trin">
              <span>Levels/Course with</span> <br />
              <span>each course of 48 Hrs.</span>
            </p>
          </div>
          <div class="col-md-3 col-sm-3 mtp-80">
            <img class="img-fluid w-17 ls" src="../../../../../assets/images/location.png" alt="First slide" /><span
              class="log-24 lt"> 30+</span>

            <p class="trin">
              <span>Globally</span>
            </p>
          </div>
          <div class="col-md-3 col-sm-3 mtp-80">
            <img class="img-fluid w-10 lti" src="../../../../../assets/images/project.png" alt="First slide" /><span
              class="log-24 lt"> 500+</span>

            <p class="trin">
              <span>International Projects</span><br /><span>published by Students</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- benifits -->
<section>
  <div class="container g-max-width-950">
    <br /><br /><br /><br />
    <div class="row mt-5">
      <div class="col-md-6 col-sm-6" align="center">
        <img alt="child" src="../../../../../assets/images/aboutus_page_images/group 1.png"
          class="w-70 firsttop mt-3" />
      </div>
      <div class="col-md-6 col-sm-6">
        <div>
          <div>
            <h2>
              <span class="lef"> Our core </span>
              <span style="color: #ff8500" class="lef"> values</span>
            </h2>
          </div>
          <p class="text-font-p mt-4">
            Our core values are to shape the future of young minds with quality education and inspire students,
            embracing them to drive change.
          </p>
        </div>
        <p class="mt-4">
          <span>
            <button mat-mini-fab style="color: tomato; background-color: #fff">
              <mat-icon>done</mat-icon>
            </button>
          </span>
          <span style="margin-left: 10px">
            We strive to provide the best training to young children by
            providing individual
          </span>
          <span style="margin-left: 55px;">
            attention.
          </span>
        </p>
        <p class="mt-4">
          <span>
            <button mat-mini-fab style="color: tomato; background-color: #fff">
              <mat-icon>done</mat-icon>
            </button>
          </span>
          <span style="margin-left: 10px">
            We nurture a culture of Innovation & Creativity for everyone.<br />
          </span>
          <!-- <span  style="margin-left: 65px;">

            </span> -->
        </p>
        <p class="mt-4">
          <span>
            <button mat-mini-fab style="color: tomato; background-color: #fff">
              <mat-icon>done</mat-icon>
            </button>
          </span>
          <span style="margin-left: 10px">
            We inspire every child, trainer and partner to excel in their own
            way.
          </span>
        </p>

        <p class="mt-4">
          <span>
            <button mat-mini-fab style="color: tomato; background-color: #fff">
              <mat-icon>done</mat-icon>
            </button>
          </span>
          <span style="margin-left: 10px">
            We are reliable & responsive in our actions.
          </span>
        </p>
      </div>
    </div>
  </div>
</section>

<!-- Team -->
<!-- <div class="left-back-img first-top mg-top">
  <section class="left-back-img">
    <div class="container text-center g-max-width-950">
      <br /><br /><br /><br />
      <h2>
        <span class="lef"> Our</span>
        <span style="color: #ff8500" class="lef"> Team</span>
        <span class="lef"> Members</span>
      </h2>
      <span class="text-cetner">
        Our team is composed of highly experienced individuals. They are deeply committed <br>
        towards their responsibilities, displaying passion, with experience in <br>
        management, administration, social work, marketing and more.
      </span> -->

<!-- <img alt="child" src="../../../../../assets/images/Banner.png" class="map-img"> -->
<!-- <div class="row mt-5">
        <div class="col-md-2"></div>
        <div class="col-md-4">
          <div class="course-card">
            <div class="card-body">
              <div class="container-photo">
                <img
                  class="teme-photo team-img"
                  src="../../../../../assets/images/Team member 1.png"
                  width="100"
                  alt="Card image cap"
                  style="border-radius: 5px"
                  (mouseover)="showProfileHeath()"
                  (mouseleave)="hideProfileHeath()"
                /> -->
<!-- <div class="facebook">
                  <a href="#"><i class="fab fa-facebook socal-icon"></i></a>
                </div>
                <div class="tuter">
                  <a href="#"><i class="fab fa-twitter socal-icon"></i></a>
                </div>
                <div class="insta">
                  <a href="#"><i class="fab fa-instagram socal-icon"></i></a>
                </div>
                <div class="linkin">
                  <a
                    href="https://www.linkedin.com/in/heathkirbymiller"
                    target="blank"
                    ><i class="fab fa-linkedin socal-icon"></i
                  ></a>
                </div> -->
<!-- </div>
              <div class="cardcolor mt-4" *ngIf="!showprofileHeath">
                <mat-card-title class="fontsizee" style="color: #333333;"
                  >Dr. Heath Kirby-Miller
                </mat-card-title>
                <hr />
                <p
                  class="card-text text-post"
                  style="margin-bottom: 22px; font-size: 15px"
                >
                  PHD. MANAGEMENT <br />
                </p>
              </div>
              <div *ngIf="showprofileHeath" style="margin-top: 30px">
                <p class="text-font-p" style="text-align: center">
                  Dr. Heath Kirby-Miller has accumulated 25 years of business
                  knowledge across franchising, education, finance, advertising,
                  and marketing. He is the STEM partner & Master Franchisee of
                  Mechatron Robotics in Australia. He also teaches at numerous
                  colleges and Australian universities.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="course-card">
            <div class="card-body">
              <div class="container-photo">
                <img
                  class="card-img-top team-img"
                  src="../../../../../assets/images/Team member 2.png"
                  width="640"
                  alt="Card image cap"
                  style="border-radius: 5px"
                  (mouseover)="showProfileChristine()"
                  (mouseleave)="hideProfileChristine()"
                /> -->
<!-- <div class="facebook">
                <a href="#"><i class="fab fa-facebook socal-icon"></i></a>
              </div>
              <div class="tuter">
                <a href="#"><i class="fab fa-twitter socal-icon"></i></a>
              </div>
              <div class="insta">
                <a href="#"><i class="fab fa-instagram socal-icon"></i></a>
              </div>
              <div class="linkin">
                <a href="#"><i class="fab fa-linkedin socal-icon"></i></a>
              </div> -->
<!-- </div>
              <div class="cardcolor mt-4" *ngIf="!showprofileChristine">
                <mat-card-title class="fontsizee" style="color: #333333;"
                  >Christine Kirby-Miller
                </mat-card-title>
                <hr />
                <mat-card-content
                  class="card-text text-post"
                  style="margin-bottom: 0px; font-size: 15px"
                >
                  HEAD OFFICE COORDINATOR, <br />
                  EDUPRENEUR
                </mat-card-content>
              </div>
              <div
                align="justify"
                *ngIf="showprofileChristine"
                style="margin-top: 30px"
              >
                <p class="text-font-p" style="text-align: center">
                  Christine is an upcoming Edupreneur who has a professional
                  degree in social work and has a passion towards Social work &
                  Education. She likes to work on solving social issues and also
                  work for the benefits of under privileged children and young
                  people in the STEM space.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2"></div> -->
<!-- <div class="col-md-4">
          <mat-card class="example-card cardhead1">
            <div class="container-photo">
              <img
                class="card-img-top team-img"
                src="../../../../../assets/images/Team member 3.png"
                width="640"
                alt="Card image cap"
                style="border-radius: 5px"
                (mouseover)="showProfileTony()"
                (mouseleave)="hideProfileTony()"
              />
              <div class="facebook">
                <a href="#"><i class="fab fa-facebook socal-icon"></i></a>
              </div>
              <div class="tuter">
                <a href="#"><i class="fab fa-twitter socal-icon"></i></a>
              </div>
              <div class="insta">
                <a href="#"><i class="fab fa-instagram socal-icon"></i></a>
              </div>
              <div class="linkin">
                <a href="#"><i class="fab fa-linkedin socal-icon"></i></a>
              </div>
            </div>
            <div class="cardcolor mt-4" *ngIf="!showprofileTony">
              <mat-card-title class="fontsizee">Tony Clemenger </mat-card-title>
              <hr />
              <mat-card-content
                class="card-text text-post"
                style="margin-bottom: 22px; font-size: 15px"
              >
                MEDIA ADVISOR <br />
              </mat-card-content>
            </div>
            <div
              align="justify"
              *ngIf="showprofileTony"
              style="margin-top: 30px"
            >
              <p class="text-font-p" style="text-align: center">
                A senior executive with experience spanning: advertising,
                marketing, sales and creative / digital / media / strategy.
                International Experience: corporate & small to medium
                enterprises profile. A leader with a career demonstrating
                outstanding performance in advertising, marketing and sales. A
                wise, educated, creative and proactive director / manager (also
                been a Chair, President, Board Member). A visionary, strategist
                and profit-driven director.
              </p>
            </div>
          </mat-card>
        </div> -->
<!-- </div>
    </div>
  </section>
</div> -->

<br /><br /><br /><br /><br />
