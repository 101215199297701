
export class Enquiry {
  name!: string;
  scope!: string;
  //scope: 'Demo Class' = "Demo Class";
  //assigned_to: 'Mechatron Robotics Australia' = "Mechatron Robotics Australia";
  notification_mail!: boolean ;
  father_name!: string;
  age!: number;
  // mobile_no: string;
  PHONE!: PHONE[];
  EMAIL!: EMAIL[];
  query_date: any;
  // email: string;
  query!: string;
  preferred_medium!: string;
  address!: Address;
  // utmObj: any;
  utm_source!: string;
  utm_medium!: string;
  utm_campaign!: string;
  utm_content!: string;
  utm_term!: string;
  enqCheckbox2!:boolean;
  enqCheckbox1!:boolean;

}

export class Address {
  city!: string;
  area!: string;
}

export class EMAIL {
  // ID: "514";
  VALUE_TYPE!: "WORK";
  VALUE!: String;
  TYPE_ID!: "EMAIL"
}

export class PHONE {
  // ID: "512";
  VALUE_TYPE!: "WORK";
  VALUE!: String;
  TYPE_ID!: "PHONE"
}

// export class TempModel {
//  dial: string;
//  mobile: string;
// }
