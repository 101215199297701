<div class="container">
  <div style="margin-top: 120px">
    <h3 class="main-head"><b>MECHATRON ROBOTICS – PRIVACY POLICY</b></h3>
    <br />
    <p>
      <strong>Mechatron Robotics (we, us or our),</strong> understands that protecting your personal information is
      important. This
      Privacy Policy sets out our commitment to protecting the privacy of personal information provided to us, or
      otherwise collected by us when providing our website, courses or products <strong>( Services )</strong> or when
      otherwise
      interacting with you.
    </p>
  </div>
  <ul>
    <li>
      <span class="sub-head">The information we collect</span>
      <p>
        Personal information: is information or an opinion, whether true or not and whether recorded in a material form
        or not, about an individual who is identified or reasonably identifiable. The types of personal information we
        may collect about you include:
      </p>
      <ul>
        <li class="p-text">your name;</li>
        <li class="p-text">images of you</li>
        <li class="p-text">
          username or login ID on other platforms such as Facebook and Google when you directly login through Facebook
          or Google;
        </li>
        <li class="p-text">
          demographic information such as gender, occupation, postcode and
          interests;
        </li>
        <li class="p-text">
          your contact details, including email address, street address and/or
          telephone number;
        </li>
        <li class="p-text">your date of birth;</li>
        <li class="p-text">
          your credit card or other payment details (through our third party
          payment processor);
        </li>
        <li class="p-text">your preferences and/or opinions;</li>
        <li class="p-text">
          information you provide to us, including through feedback, customer surveys or otherwise;
        </li>
        <li class="p-text">your sensitive information as set out below;</li>
        <li class="p-text">
          details of products and services we have provided to you and/or that
          you have enquired about, and our response to you;
        </li>
        <li class="p-text">
          support requests submitted to us and our response to you;
        </li>
        <li class="p-text">
          your browser session, device and network information, statistics on page views and sessions, acquisition
          sources, search queries and/or browsing behaviour;
        </li>
        <li class="p-text">
          information about your access and use of our Services, including through the use of Internet cookies, your
          communications with our online Services, the type of browser you are using, the type of operating system you
          are using and the domain name of your Internet service provider;
        </li>
        <li class="p-text">
          additional personal information that you provide to us, directly or indirectly, through your use of our
          Services, associated applications, associated social media platforms and/or accounts from which you permit us
          to collect information;
        </li>
        <li class="p-text">
          any other personal information requested by us and/or provided by you or a third party.
        </li>
      </ul>
      <p>
        <strong>Sensitive information: </strong> is a sub-set of personal information that is given a higher level of
        protection. Sensitive information means information relating to your racial or ethnic origin, political
        opinions, religion, trade union or other professional associations or memberships, philosophical beliefs, sexual
        orientation or practices, criminal records, health information or biometric information.
      </p>
      <p>
        We do not actively request sensitive information about you. If at any time we need to collect sensitive
        information about you, unless otherwise permitted by law, we will first obtain your consent and we will only use
        it as required or authorized by law.
      </p>
    </li>
    <li>
      <span class="sub-head">How we collect personal information</span>
      <p>We collect personal information in a variety of ways, including:</p>
      <ul>
        <li class="p-text">
          <strong>Directly:</strong>
          We collect personal information which you directly provide to us, including when you register for an account,
          purchase our Services, through the ‘contact us’ form on our website or when you request our assistance via
          email, or over the telephone.
        </li>
        <li class="p-text">
          <strong>Indirectly: </strong>
          We may collect personal information which you indirectly provide to us while interacting with us, such as when
          you use our website, in emails, over the telephone and in your online enquiries.
        </li>
        <li class="p-text">
          <strong>From third parties:</strong>
          We collect personal information from third parties, such as any person authorised to act on your behalf or
          authorised to provide your personal information to us (ie: your parent or guardian).
        </li>
      </ul>
    </li>
    <li>
      <span class="sub-head">Why we collect, hold, use and disclose personal information</span>
      <p>
        <strong> Personal information:</strong>
        We collect personal information from third parties, such as any person authorised to act on your behalf or
        authorised to provide your personal information to us (ie: your parent or guardian).
      </p>
      <ul>
        <li class="p-text">
          to enable you to access and use our Services, including to provide you with a login;
        </li>
        <li class="p-text">
          to provide our Services to you, including to dispatch and deliver our products to you, register your
          attendance at our courses, assess your course application and manage your course bookings;
        </li>
        <li class="p-text">
          to enable you to access and use our associated applications;
        </li>
        <li class="p-text">
          to contact and communicate with you about our Services, including in response to any support requests you
          lodge with us or other enquiries you make with us;
        </li>
        <li class="p-text">
          for internal record keeping, administrative, invoicing and billing purposes;
        </li>
        <li class="p-text">
          for analytics, market research and business development, including to operate and improve our Services,
          associated applications;
        </li>
        <li class="p-text">
          to run promotions, competitions and/or offer additional benefits to you;
        </li>
        <li class="p-text">
          for advertising and marketing, including to send you promotional information about our products and services
          and other information that we consider may be of interest to you;
        </li>
        <li class="p-text">
          to comply with our legal obligations and resolve any disputes that we may have;
        </li>
        <li class="p-text">if otherwise required or authorised by law.</li>
      </ul>
    </li>
    <li>
      <span class="sub-head">Our disclosures of personal information to third parties</span>
      <p>We may disclose personal information to:</p>
      <ul>
        <li class="p-text">
          third party service providers for the purpose of enabling them to provide their services, to us, including
          (without limitation) IT service providers, data storage, web-hosting and server providers, email marketing
          providers, debt collectors, couriers, maintenance or problem-solving providers, marketing or advertising
          providers, professional advisors and payment systems operators;
        </li>
        <li class="p-text">
          our employees, contractors and/or related entities;
        </li>
        <li class="p-text">our franchisor</li>
        <li class="p-text">
          our existing or potential agents or business partners;
        </li>
        <li class="p-text">
          anyone to whom our business or assets (or any part of them) are, or may (in good faith) be, transferred;
        </li>
        <li class="p-text">
          courts, tribunals and regulatory authorities, in the event you fail to pay for goods or services we have
          provided to you; </li>
        <li class="p-text">
          courts, tribunals, regulatory authorities and law enforcement officers, as required or authorised by law, in
          connection with any actual or prospective legal proceedings, or in order to establish, exercise or defend our
          legal rights;
        </li>
        <li class="p-text">
          third parties to collect and process data, or other relevant analytics businesses;
        </li>
        <li class="p-text">
          any other third parties as required or permitted by law, such as where we receive a subpoena.
        </li>
      </ul>
    </li>
    <li>
      <span class="sub-head">Overseas disclosure</span>
      <p class="p-text">
        We may store personal information overseas, including in India. Where we disclose your personal information to
        the third parties listed above, these third parties may also store, transfer or access personal information
        outside of Australia, including but not limited to, India.
      </p>
    </li>
    <li>
      <span class="sub-head">Your rights and controlling your personal information</span>
      <p>
        <strong> Your choice:</strong>
        Please read this Privacy Policy carefully. If you provide personal information to us, you understand we will
        collect, hold, use and disclose your personal information in accordance with this Privacy Policy. You do not
        have to provide personal information to us, however, if you do not, it may affect our ability to provide our
        Services to you and your use of our Services.
      </p>
      <p>
        <strong>Information from third parties:</strong>
        If we receive personal information about you from a third party, we will protect it as set out in this Privacy
        Policy. If you are a third party providing personal information about somebody else, you represent and warrant
        that you have such person’s consent to provide the personal information to us.
      </p>
      <p>
        <strong>Anonymity:</strong>
        Where practicable we will give you the option of not identifying yourself or using a pseudonym in your dealings
        with us.
      </p>
      <p>
        <strong> Restrict and unsubscribe:</strong>
        To object to processing for direct marketing/unsubscribe from our email database or opt-out of communications
        (including marketing communications), please contact us using the details below or opt-out using the opt-out
        facilities provided in the communication
      </p>
      <p>
        <strong>Access:</strong>
        You may request access to the personal information that we hold about you. An administrative fee may be payable
        for the provision of such information. Please note, in some situations, we may be legally permitted to withhold
        access to your personal information. If we cannot provide access to your information, we will advise you as soon
        as reasonably possible and provide you with the reasons for our refusal and any mechanism available to complain
        about the refusal. If we can provide access to your information in another form that still meets your needs,
        then we will take reasonable steps to give you such access.
      </p>
      <p>
        <strong>Correction:</strong>
        If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant or
        misleading, please contact us using the details below. We will take reasonable steps to promptly correct any
        information found to be inaccurate, out of date, incomplete, irrelevant or misleading. Please note, in some
        situations, we may be legally permitted to not correct your personal information. If we cannot correct your
        information, we will advise you as soon as reasonably possible and provide you with the reasons for our refusal
        and any mechanism available to complain about the refusal.
      </p>
      <p>
        <strong>Complaints:</strong>
        If you wish to make a complaint, please contact us using the details below and provide us with full details of
        the complaint. We will promptly investigate your complaint and respond to you, in writing, setting out the
        outcome of our investigation and the steps we will take in response to your complaint. If you are not satisfied
        with our response, you also have the right to contact the Office of the Australian Information Commissioner.
      </p>
    </li>
    <li>
      <span class="sub-head">Storage and security</span>
      <p>
        We are committed to ensuring that the personal information we collect is secure. In order to prevent
        unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures,
        to safeguard and secure personal information and protect it from misuse, interference, loss and unauthorised
        access, modification and disclosure.
      </p>
      <p>
        While we are committed to security, we cannot guarantee the security of any information that is transmitted to
        or by us over the Internet. The transmission and exchange of information is carried out at your own risk.
      </p>
    </li>
    <li>
      <span class="sub-head">Cookies</span>
      <p>
        We may use cookies on our website from time to time. Cookies are text files placed in your computer's browser to
        store your preferences. Cookies, by themselves, do not tell us your email address or other personally
        identifiable information. However, they do recognise you when you return to our online website and allow third
        parties, such as Google and Facebook, to cause our advertisements to appear on your social media and online
        media feeds as part of our retargeting campaigns. If and when you choose to provide our online website with
        personal information, this information may be linked to the data stored in the cookie.
      </p>
      <p>
        You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or
        some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you
        may not be able to access all or parts of our website.
      </p>
    </li>
    <li>
      <span class="sub-head">Links to other websites</span>
      <p>
        Our website may contain links to other party’s websites. We do not have any control over those websites and we
        are not responsible for the protection and privacy of any personal information which you provide whilst visiting
        those websites. Those websites are not governed by this Privacy Policy.
      </p>
    </li>
    <li>
      <span class="sub-head">Personal information from social network accounts</span>
      <p>
        If you connect your account with us to a social network account, such as Facebook or Google, we will collect
        your personal information from the social network. We will do this in accordance with the privacy settings you
        have chosen on that social network.
      </p>
      <p>
        The personal information that we may receive includes your name, ID, user name, handle, profile picture, gender,
        age, language, list of friends or follows and any other personal information you choose to share.
      </p>
      <p>
        We use the personal information we receive from the social network to create a profile for you on our platform
        and provide the Services to you.
      </p>
      <p>
        If you agree, we may also use your personal information to give you updates on the social network which might
        interest you. We will not post to your social network without your permission
      </p>
      <p>
        Where we have accessed your personal information through your Facebook account, you have the right to request
        the deletion of personal information that we have been provided by Facebook. To submit a request for the
        deletion of personal information we acquired from Facebook, please send us an email at the address at the end of
        this Privacy Policy and specify in your request which personal information you would like deleted. If we deny
        your request for the deletion of personal information, we will explain why.
      </p>
    </li>
    <li>
      <span class="sub-head">Amendments</span>
      <p>
        We may, at any time and at our discretion, vary this Privacy Policy by publishing the amended Privacy Policy on
        our website. We recommend you check our website regularly to ensure you are aware of our current Privacy Policy.
      </p>
    </li>
  </ul>
  <br />
  <p><strong>For any questions and notices, please contact us at:</strong></p>
  <!-- <p class="sub-head">
    Mechatron Robotics Australia Pty Ltd (ABN 99 654 594 064)
  </p>
  <p>
    <strong>Address :</strong>191 Balaclava Road, Caulfield North, Victoria
    3161, Australia
  </p>
  <p><strong>Phone Number : </strong>03 9524 3115</p> -->
  <p style="margin-bottom: 80px;"><strong>Email : </strong>info@mechatronrobotics.com</p>
  <!-- <p><strong>© LegalVision ILP Pty Ltd</strong></p> -->
</div>