<div class="hero-image">
  <div class="container">
    <div class="row">
      <div class="col-md-6 top-margin-top">
        <div class="d-flex flex-column" style="margin-top: 0px !important">
          <p class="top-p mt-5">
            <span class="head-text-black">Australia's
              <span class="head-text-orange">Most Trusted </span>destination</span>
            <span class="head-text-black">
              for students to get
              <span class="head-text-orange">Practical </span>&
            </span>
            <span class="head-text-black"><span class="head-text-orange">Personalised </span>training. </span><br />
          </p>
          <p class="banner-small-text-second mt-2">
            Learn from the expert faculties of Robotics. Arduino, IOT, Coding,
            Electronics & many more.
          </p>
          <!-- <p class="banner-small-text-second mt-2">
            Top Robotics, Python classes, AI and Coding Classes for kids.
          </p> -->
          <div>
            <button type="button" class="my-btn mt-5" (click)="scroll(get_start)">
              Get Started
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <img src="../../../../../assets/images/About us.png" alt="child" class="w-80"
          style="margin-top: 100px !important" />
      </div>
    </div>
  </div>
</div>

<!-- Our Course -->
<section #get_start>
  <br><br><br><br>
  <div class="container text-center g-max-width-950">
    <div class="text-center">
      <span class="lef"> Our </span>
      <span style="color: #ff8500" class="lef" #get_start> Courses </span>
    </div>
    <div class="tab-content"  *ngIf="course_loader">
      <div class="container">
        <div class="row">
          <div class="col-md-12 p-0">
            <div class="mt-3 tab-card">
              <div class="tab-card-header">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="online-tab" data-toggle="tab" href="#online" role="tab"
                      aria-controls="online" aria-selected="true">Online Course</a>
                  </li>
                  <li class="nav-item" *ngIf="offlineCourses.length > 0">
                    <a class="nav-link" id="offline-tab" data-toggle="tab" href="#offline" role="tab"
                      aria-controls="offline" aria-selected="false">Offline Course</a>
                  </li>
                  <!-- <li class="nav-item" *ngIf="selfPlaceCourses.length > 0">
                    <a class="nav-link" id="self-paced-tab" data-toggle="tab" href="#self-paced" role="tab"
                      aria-controls="self-paced" aria-selected="false">Self  Paced</a>
                  </li>
                  <li class="nav-item" *ngIf="hybridCourses.length > 0">
                    <a class="nav-link" id="professional-tab" data-toggle="tab" href="#professional" role="tab"
                      aria-controls="professional" aria-selected="false">Hybrid Courses</a>
                  </li>
                  <li class="nav-item" *ngIf="integratedCourses.length > 0">
                    <a class="nav-link" id="integrated-tab" data-toggle="tab" href="#integrated" role="tab"
                      aria-controls="integrated" aria-selected="false">Integrated Courses</a>
                  </li> -->

                  <!-- <li class="nav-item">
                    <a class="nav-link" id="bundle-tab" data-toggle="tab" href="#bundle" role="tab" aria-controls="bundle"
                      aria-selected="false">Bundle Courses</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="internships-tab" data-toggle="tab" href="#internships" role="tab"
                      aria-controls="internships" aria-selected="false">Internships</a>
                  </li> -->
                </ul>
              </div>

              <div class="tab-content mt-2" id="myTabContent" *ngIf="onlineCourses">
                <!-- online-tab -->
                <div class="tab-pane fade active show" id="online" role="tabpanel" aria-labelledby="online-tab" >
                  <div class="row col-md-12 p-0">
                    <div class="col-md-3 col-sm-6" *ngFor="let item of onlineCourses; index as i" id="item{{ i }}">
                      <div class="all-courses">
                        <div class="thumb-wrapper" (click)="
                            viewDetails(
                             item
                            )
                          " style="cursor: pointer">
                          <div *ngIf="item?.images">
                            <img [src]="getCourseImage(item?.images)" width="100%" class="h-205"
                              alt="course_image" />
                          </div>
                          <div *ngIf="!item?.images">
                            <img  src="/assets/images/courses/defaultpic.png" alt="not available" class="img-fluid h-205" />
                          </div>


                          <div class="levelnew" *ngIf="item?.course?.level">
                            Level {{ item?.course?.level }}
                          </div>
                          <div class="thumb-content innnercard">
                            <div class="d-flex flex-column align-items-center">
                              <h4 class="card-headingnew boldText text-capitalize">
                                {{ item?.title }}
                              </h4>

                              <!-- <div class="d-flex justify-content-between align-items-center mt-3">
                                <p class="boldText">
                                  <i class="fas fa-user mr-2"></i>{{ item?.min_age }}Yrs +
                                </p>
                                <p class="boldText">
                                  <i class="fas fa-stopwatch mr-2"></i> 48 hours
                                </p>
                                <p class="Online-course" *ngIf="item?.course?.type == 'Online'">
                                  <i class="fas fa-laptop-code mr-1"></i>Online
                                </p>
                                <p class="Offline-course" *ngIf="item?.course?.type == 'Class Room'">
                                  <i class="fas fa-users-class mr-1"></i>Offline
                                </p>
                              </div> -->

                              <div class="row d-flex align-center justify-content-center">
                                <div class="">
                                  <app-rating max="5" [setRating]="item?.average_rating"
                                    style="cursor: default"></app-rating>
                                </div>
                                <div class="ml-2">
                                  <span class="starclrbl">{{
                                    item?.average_rating
                                    ? item.average_rating
                                    : "0"
                                    }}<span class="starclrblk">({{
                                      item?.average_rating?.total
                                      ? item.average_rating.tota
                                      : "0"
                                      }})</span>
                                  </span>
                                </div>
                              </div>
                              <br />
                            </div>

                            <!-- <p class="courseview mt-3">Course Overview</p>
                          <ul class="cousre-list">
                            <li *ngFor="let overview of item.overview">
                              <i class="fa fa-check mr-1"></i>{{ overview }}
                            </li>
                          </ul> -->

                            <button type="button " class="align-items-center mb-1 justify-center btn-viewCourse boldText"
                               (click)="
                            viewDetails(
                             item
                            )
                              ">
                              View Course Details
                            </button>
                            <hr class="underline d-none" />
                            <!-- <div class="d-none">
                              <p class="course-price boldText m-0">
                                &#x20B9; 21,800
                              </p>
                              <del style="margin-top: 6px; margin-left: 24px">&#x20B9;23,800</del>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- offline-tab -->
                <div class="tab-pane fade p-3" id="offline" role="tabpanel" aria-labelledby="offline-tab">
                  <div class="row col-md-12 p-0">
                    <div class="col-md-3 col-sm-6" *ngFor="let item of offlineCourses; index as i" id="item{{ i }}">
                      <div class="all-courses">
                        <div class="thumb-wrapper" (click)="
                        viewDetails(
                         item
                        )
                          " style="cursor: pointer">
                          <div *ngIf="item?.images">
                            <img [src]="getCourseImage(item?.images)" width="100%" class="h-205"
                              alt="course_image" />
                          </div>
                          <div *ngIf="!item?.images">
                            <img  src="/assets/images/courses/defaultpic.png" alt="not available" class="img-fluid h-205" />
                          </div>

                          <div class="levelnew">
                            Level {{ item?.course?.level }}
                          </div>
                          <div class="thumb-content innnercard">
                            <div class="d-flex flex-column align-items-center">
                              <h4 class="card-headingnew boldText text-capitalize">
                                {{ item?.title }}
                              </h4>

                              <!-- <div class="d-flex justify-content-between align-items-center mt-3">
                                <p class="boldText">
                                  <i class="fas fa-user mr-2"></i>{{ item?.min_age }}Yrs +
                                </p>
                                <p class="boldText">
                                  <i class="fas fa-stopwatch mr-2"></i> 48 hours
                                </p>
                                <p class="Online-course" *ngIf="item?.course?.type == 'Online'">
                                  <i class="fas fa-laptop-code mr-1"></i>Online
                                </p>
                                <p class="Offline-course" *ngIf="item?.course?.type == 'Class Room'">
                                  <i class="fas fa-users-class mr-1"></i>Offline
                                </p>
                              </div> -->

                              <div class="row d-flex align-center justify-content-center">
                                <div class="">
                                  <app-rating max="5" [setRating]="item?.average_rating"
                                    style="cursor: default"></app-rating>
                                </div>
                                <div class="ml-2">
                                  <span class="starclrbl">{{
                                    item?.average_rating
                                    ? item.average_rating
                                    : "0"
                                    }}<span class="starclrblk">({{
                                      item?.average_rating?.total
                                      ? item.average_rating.tota
                                      : "0"
                                      }})</span>
                                  </span>
                                </div>
                              </div>
                              <br />
                            </div>

                            <!-- <p class="courseview mt-3">Course Overview</p>
                          <ul class="cousre-list">
                            <li *ngFor="let overview of item.overview">
                              <i class="fa fa-check mr-1"></i>{{ overview }}
                            </li>
                          </ul> -->

                            <button type="button " class="align-items-center mb-1 justify-center btn-viewCourse boldText"
                            (click)="
                            viewDetails(
                             item
                            )
                              ">
                              View Course Details
                            </button>
                            <hr class="underline d-none" />
                            <!-- <div class="d-none">
                              <p class="course-price boldText m-0">
                                &#x20B9; 21,800
                              </p>
                              <del style="margin-top: 6px; margin-left: 24px">&#x20B9;23,800</del>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- self-paced-tab -->
                <div class="tab-pane fade p-3" id="self-paced" role="tabpanel" aria-labelledby="self-paced-tab">
                  <div class="row col-md-12 p-0">
                    <div class="col-md-3 col-sm-6" *ngFor="let item of selfPlaceCourses; index as i" id="item{{ i }}">
                      <div class="all-courses">
                        <div class="thumb-wrapper" (click)="
                        viewDetails(
                         item
                        )

                          " style="cursor: pointer">
                          <div *ngIf="item?.images">
                            <img [src]="getCourseImage(item?.images)" width="100%" class="h-205"
                              alt="course_image" />
                          </div>
                          <div *ngIf="!item?.images">
                            <img  src="/assets/images/courses/defaultpic.png" alt="not available" class="img-fluid h-205" />
                          </div>


                          <div class="levelnew">
                            Level {{ item?.course?.level }}
                          </div>
                          <div class="thumb-content innnercard">
                            <div class="d-flex flex-column align-items-center">
                              <h4 class="card-headingnew boldText text-capitalize">
                                {{ item?.title }}
                              </h4>

                              <!-- <div class="d-flex justify-content-between align-items-center mt-3">
                                <p class="boldText">
                                  <i class="fas fa-user mr-2"></i>{{ item?.min_age }}Yrs +
                                </p>
                                <p class="boldText">
                                  <i class="fas fa-stopwatch mr-2"></i> 48 hours
                                </p>
                                <p class="Online-course" *ngIf="item?.course?.type == 'Online'">
                                  <i class="fas fa-laptop-code mr-1"></i>Online
                                </p>
                                <p class="Offline-course" *ngIf="item?.course?.type == 'Class Room'">
                                  <i class="fas fa-users-class mr-1"></i>Offline
                                </p>
                              </div> -->

                              <div class="row d-flex align-center justify-content-center">
                                <div class="">
                                  <app-rating max="5" [setRating]="item?.average_rating"
                                    style="cursor: default"></app-rating>
                                </div>
                                <div class="ml-2">
                                  <span class="starclrbl">{{
                                    item?.average_rating
                                    ? item.average_rating
                                    : "0"
                                    }}<span class="starclrblk">({{
                                      item?.average_rating?.total
                                      ? item.average_rating.tota
                                      : "0"
                                      }})</span>
                                  </span>
                                </div>
                              </div>
                              <br />
                            </div>

                            <!-- <p class="courseview mt-3">Course Overview</p>
                          <ul class="cousre-list">
                            <li *ngFor="let overview of item.overview">
                              <i class="fa fa-check mr-1"></i>{{ overview }}
                            </li>
                          </ul> -->

                            <button type="button " class="align-items-center mb-1 justify-center btn-viewCourse boldText"
                            (click)="
                            viewDetails(
                             item
                            )
                              ">
                              View Course Details
                            </button>
                            <hr class="underline d-none" />
                            <!-- <div class="d-none">
                              <p class="course-price boldText m-0">
                                &#x20B9; 21,800
                              </p>
                              <del style="margin-top: 6px; margin-left: 24px">&#x20B9;23,800</del>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- professional-tab -->
                <div class="tab-pane fade p-3" id="professional" role="tabpanel" aria-labelledby="professional-tab">
                  <div class="row col-md-12 p-0">
                    <div class="col-md-3 col-sm-6" *ngFor="let item of hybridCourses; index as i" id="item{{ i }}">
                      <div class="all-courses">
                        <div class="thumb-wrapper" (click)="
                        viewDetails(
                         item
                        )
                          " style="cursor: pointer">
                          <div *ngIf="item?.images">
                            <img [src]="getCourseImage(item?.images)" width="100%" class="h-205"
                              alt="course_image" />
                          </div>
                          <div *ngIf="!item?.images">
                            <img  src="/assets/images/courses/defaultpic.png" alt="not available" class="img-fluid h-205" />
                          </div>

                          <div class="levelnew">
                            Level {{ item?.course?.level }}
                          </div>
                          <div class="thumb-content innnercard">
                            <div class="d-flex flex-column align-items-center">
                              <h4 class="card-headingnew boldText text-capitalize">
                                {{ item?.title }}
                              </h4>

                              <!-- <div class="d-flex justify-content-between align-items-center mt-3">
                                <p class="boldText">
                                  <i class="fas fa-user mr-2"></i>{{ item?.min_age }}Yrs +
                                </p>
                                <p class="boldText">
                                  <i class="fas fa-stopwatch mr-2"></i> 48 hours
                                </p>
                                <p class="Online-course" *ngIf="item?.course?.type == 'Online'">
                                  <i class="fas fa-laptop-code mr-1"></i>Online
                                </p>
                                <p class="Offline-course" *ngIf="item?.course?.type == 'Class Room'">
                                  <i class="fas fa-users-class mr-1"></i>Offline
                                </p>
                              </div> -->

                              <div class="row d-flex align-center justify-content-center">
                                <div class="">
                                  <app-rating max="5" [setRating]="item?.average_rating"
                                    style="cursor: default"></app-rating>
                                </div>
                                <div class="ml-2">
                                  <span class="starclrbl">{{
                                    item?.average_rating
                                    ? item.average_rating
                                    : "0"
                                    }}<span class="starclrblk">({{
                                      item?.average_rating?.total
                                      ? item.average_rating.tota
                                      : "0"
                                      }})</span>
                                  </span>
                                </div>
                              </div>
                              <br />
                            </div>

                            <!-- <p class="courseview mt-3">Course Overview</p>
                          <ul class="cousre-list">
                            <li *ngFor="let overview of item.overview">
                              <i class="fa fa-check mr-1"></i>{{ overview }}
                            </li>
                          </ul> -->

                            <button type="button " class="align-items-center mb-1 justify-center btn-viewCourse boldText"
                            (click)="
                            viewDetails(
                             item
                            )
                              ">
                              View Course Details
                            </button>
                            <hr class="underline d-none" />
                            <!-- <div class="d-none">
                              <p class="course-price boldText m-0">
                                &#x20B9; 21,800
                              </p>
                              <del style="margin-top: 6px; margin-left: 24px">&#x20B9;23,800</del>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                 <!-- integrated-tab -->
                 <div class="tab-pane fade p-3" id="integrated" role="tabpanel" aria-labelledby="integrated-tab">
                  <div class="row col-md-12 p-0">
                    <div class="col-md-3 col-sm-6" *ngFor="let item of integratedCourses; index as i" id="item{{ i }}">
                      <div class="all-courses">
                        <div class="thumb-wrapper" (click)="
                        viewDetails(
                         item
                        )
                          " style="cursor: pointer">
                           <!-- item.seo.url -->
                          <div *ngIf="item?.images">
                            <img [src]="getCourseImage(item?.images)" width="100%" class="h-205"
                              alt="course_image" />
                          </div>
                          <div *ngIf="!item?.images">
                            <img  src="/assets/images/courses/defaultpic.png" alt="not available" class="img-fluid h-205" />
                          </div>

                          <div class="levelnew">
                            Level {{ item?.course?.level }}
                          </div>
                          <div class="thumb-content innnercard">
                            <div class="d-flex flex-column align-items-center">
                              <h4 class="card-headingnew boldText text-capitalize">
                                {{ item?.title }}
                              </h4>

                              <!-- <div class="d-flex justify-content-between align-items-center mt-3">
                                <p class="boldText">
                                  <i class="fas fa-user mr-2"></i>{{ item?.min_age }}Yrs +
                                </p>
                                <p class="boldText">
                                  <i class="fas fa-stopwatch mr-2"></i> 48 hours
                                </p>
                                <p class="Online-course" *ngIf="item?.course?.type == 'Online'">
                                  <i class="fas fa-laptop-code mr-1"></i>Online
                                </p>
                                <p class="Offline-course" *ngIf="item?.course?.type == 'Class Room'">
                                  <i class="fas fa-users-class mr-1"></i>Offline
                                </p>
                              </div> -->

                              <div class="row d-flex align-center justify-content-center">
                                <div class="">
                                  <app-rating max="5" [setRating]="item?.average_rating"
                                    style="cursor: default"></app-rating>
                                </div>
                                <div class="ml-2">
                                  <span class="starclrbl">{{
                                    item?.average_rating
                                    ? item.average_rating
                                    : "0"
                                    }}<span class="starclrblk">({{
                                      item?.average_rating?.total
                                      ? item.average_rating.tota
                                      : "0"
                                      }})</span>
                                  </span>
                                </div>
                              </div>
                              <br />
                            </div>

                            <!-- <p class="courseview mt-3">Course Overview</p>
                          <ul class="cousre-list">
                            <li *ngFor="let overview of item.overview">
                              <i class="fa fa-check mr-1"></i>{{ overview }}
                            </li>
                          </ul> -->

                            <button type="button " class="align-items-center mb-1 justify-center btn-viewCourse boldText"
                              (click)="
                                viewDetails(
                                  item
                                )
                              ">
                              View Course Details
                            </button>
                            <hr class="underline d-none" />
                            <!-- <div class="d-none">
                              <p class="course-price boldText m-0">
                                &#x20B9; 21,800
                              </p>
                              <del style="margin-top: 6px; margin-left: 24px">&#x20B9;23,800</del>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- bundle-tab -->
                <div class="tab-pane fade p-3" id="bundle" role="tabpanel" aria-labelledby="bundle-tab">
                  bundle-tab data will be here
                </div>

                <!-- internships-tab -->
                <div class="tab-pane fade p-3" id="internships" role="tabpanel" aria-labelledby="internships-tab">
                  internships-tab data will be here
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5" *ngIf="!course_loader">
      <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12">
        <div class="d-flex align-items-center">
          <strong>Courses Loading...</strong>
          <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Training  -->
<!-- <section class="mg-top first-top  ">
  <div class="images pbtm">
     <div class="container g-max-width-950">
        <div class="col-md-12">
           <div class="row">
              <div class="col-md-3 col-sm-3 mtp-80">
                 <span>
                 <img
                    class="img-fluid w-17 w-171"
                    src="../../../../../assets/images/training.png"
                    alt="First slide"
                    />
                 </span>
                 <span class="log-24"> 960,000+</span>
                 <p class="trin">
                    <span>Training Hrs.</span><br /><span>since 2018</span>
                 </p>
              </div>
              <div class="col-md-3 col-sm-3 mtp-80">
                 <img
                    class="img-fluid w-15 lss"
                    src="../../../../../assets/images/course.png"
                    alt="First slide"
                    /><span class="log-24 lt"> 10+</span>
                 <p class="trin">
                    <span>Levels/Course with</span> <br />
                    <span>each course of 48 Hrs.</span>
                 </p>
              </div>
              <div class="col-md-3 col-sm-3 mtp-80">
                 <img
                    class="img-fluid w-17 ls"
                    src="../../../../../assets/images/location.png"
                    alt="First slide"
                    /><span class="log-24 lt"> 15+</span>
                 <p class="trin">
                    <span>Different Locations</span><br /><span
                       >in India & Abroad</span
                       >
                 </p>
              </div>
              <div class="col-md-3 col-sm-3 mtp-80">
                 <img
                    class="img-fluid w-10 lti"
                    src="../../../../../assets/images/project.png"
                    alt="First slide"
                    /><span class="log-24 lt"> 500+</span>
                 <p class="trin">
                    <span>International Projects</span><br /><span
                       >published by Students</span
                       >
                 </p>
              </div>
           </div>
        </div>
     </div>
  </div>
</section> -->

<div class="container-fluid left-back-img mg-top">
  <div class="container margin_top">
    <div class="row">
      <div class="col-md-6">
        <h2 class="blackText seaction-heading seaction-heading-start">
          <span class="boldText"> Why</span><span class="orangeText">&nbsp;Students</span>&nbsp;Choose&nbsp;<span
            class="orangeText">Mechatron Robotics?</span>
        </h2>
        <p class="main-feature-paragraph mt-4 pr-3">
          With millions of courses available worldwide it is important that you
          choose the right learning path with a multi disciplinary approach to
          acquire the right skillsets.
        </p>
        <div class="row mt-5 main-feature">
          <div class="col-md-12 d-flex justify-content-between">
            <div class="d-flex">
              <img class="img-fluid main-icons" src="../../../../../../assets/images/new-courses/cerificate.png"
                alt="student certificate of Mechatron Robotics" />
              <p class="trin mt-0 ml-3">
                360 degree <br />
                Learning
              </p>
            </div>

            <div class="d-flex">
              <img class="img-fluid main-icons" src="../../../../../../assets/images/new-courses/support.png"
                alt="support hours of Mechatron Robotics" />
              <p class="trin mt-0 ml-4">
                <!-- Expert <br> Faculty -->
                48 Hrs of In <br />
                depth Content
              </p>
            </div>
          </div>
          <div class="col-md-12 mt-4 d-flex justify-content-between">
            <div class="d-flex">
              <img class="img-fluid main-icons" src="../../../../../../assets/images/new-courses/research.png"
                alt="research level Mechatron Robotics" />
              <p class="trin mt-0 ml-4">
                <!-- Personalised <br> Training -->
                Level wise <br />
                Approach
              </p>
            </div>

            <div class="d-flex pr-2">
              <img class="img-fluid main-icons ml-2" src="../../../../../../assets/images/new-courses/options.png"
                alt="options of Mechatron Robotics" />
              <p class="trin mt-0 ml-4">
                <!-- Level wise <br> Approach -->
                Personalised <br />
                Training
              </p>
            </div>
          </div>
          <div class="col-md-12 mt-4 d-flex justify-content-between">
            <div class="d-flex">
              <img class="img-fluid main-icons" src="../../../../../../assets/images/new-courses/supervision.png"
                alt="supervision of Mechatron Robotics" />
              <p class="trin mt-0 ml-4">
                <!-- 48 Hrs of In <br> depth Content -->
                Expert <br />
                Faculty
              </p>
            </div>

            <div class="d-flex">
              <img class="img-fluid main-icons" src="../../../../../../assets/images/new-courses/professional.png"
                alt="professional of Mechatron Robotics" />
              <p class="trin mt-0 ml-3">
                Online / Offline <br />
                Trainings
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <img class="img-fluid" src="../../../../../../assets/images/new-courses/main-feature.png"
          alt="mail_feature of Mechatron Robotics" />
      </div>
    </div>
  </div>
</div>

<div class="container-fluid right-back-img" #table_top>
  <div class="container text-center margin_top">
    <h2 class="blackText seaction-heading">
      How are&nbsp;<span class="boldText">we</span><span class="orangeText">&nbsp;Different&nbsp;</span>From&nbsp;<span
        class="boldText">Others ?</span>
    </h2>

    <div class="table-conatainer">
      <table class="ml-3 mr-3 mt-5">
        <thead>
          <tr>
            <th class="boldText" style="background-color: #2f5698 !important; color: #fff">
              Comparison of Course/Training
            </th>
            <th class="boldText" style="
                color: #2f5698 !important ;
                background-color: #f7f7f7 !important;
              ">
              Mechatron Robotics
            </th>
            <th class="boldText" style="
                color: #2f5698 !important ;
                background-color: #f7f7f7 !important;
              ">
              Others Classes
            </th>
          </tr>
        </thead>

        <tr>
          <td>
            1. Focus on holistic & practical knowledge on Mechanics, Electronics
            and Coding. &nbsp;
          </td>
          <td><i class="fal fa-check-circle"></i></td>
          <td><i class="fal fa-times-circle"></i></td>
        </tr>
        <tr>
          <td>
            2. Competition Training for Students to Participate in Various
            Competitions.
          </td>
          <td><i class="fal fa-check-circle"></i></td>
          <td><i class="fal fa-check-circle"></i></td>
        </tr>
        <tr>
          <td>
            3. Experienced Trainers with years of experiences in the same field.
          </td>
          <td><i class="fal fa-check-circle"></i></td>
          <td><i class="fal fa-check-circle"></i></td>
        </tr>
        <tr>
          <td>
            4. Team Based Learning / Group Learning / Group Project Building.
          </td>
          <td><i class="fal fa-check-circle"></i></td>
          <td><i class="fal fa-times-circle"></i></td>
        </tr>
        <tr>
          <td>5. Regular Quizzes, Assignments and Project Presentations.</td>
          <td><i class="fal fa-check-circle"></i></td>
          <td><i class="fal fa-times-circle"></i></td>
        </tr>
        <tr>
          <td>6. Affordable Course Fee (Value for Money).</td>
          <td><i class="fal fa-check-circle"></i></td>
          <td><i class="fal fa-times-circle"></i></td>
        </tr>
        <tr>
          <td>7. Courses for Both School , College and Professionals.</td>
          <td><i class="fal fa-check-circle"></i></td>
          <td><i class="fal fa-check-circle"></i></td>
        </tr>
        <tr>
          <td>8. Internship Opportunity For Students.</td>
          <td><i class="fal fa-check-circle"></i></td>
          <td><i class="fal fa-times-circle"></i></td>
        </tr>
        <tr>
          <td>
            9. Certified Curriculum with Practical Projects – 90% Projects, 10%
            Theory.
          </td>
          <td><i class="fal fa-check-circle"></i></td>
          <td><i class="fal fa-times-circle"></i></td>
        </tr>
        <tr>
          <td>
            10. 95% Students usually complete all courses from basic to
            advanced.
          </td>
          <td><i class="fal fa-check-circle"></i></td>
          <td><i class="fal fa-times-circle"></i></td>
        </tr>
        <tr>
          <td>
            11. Support students to publish projects in national and
            international Platforms.
          </td>
          <td><i class="fal fa-check-circle"></i></td>
          <td><i class="fal fa-times-circle"></i></td>
        </tr>
        <!-- <tr>
                  <td>12. Course on Robotics</td>
                  <td><i class="fal fa-check-circle"></i></td>
                  <td><i class="fal fa-times-circle"></i></td>
              </tr>
              <tr>
                  <td>13. Course on Robot Operating System – Advanced Robotics</td>
                  <td><i class="fal fa-check-circle"></i></td>
                  <td><i class="fal fa-times-circle"></i></td>
              </tr>
              <tr>
                  <td>14. Course on AI for Kids</td>
                  <td><i class="fal fa-check-circle"></i></td>
                  <td><i class="fal fa-times-circle"></i></td>
              </tr>
              <tr>
                  <td>15. Classroom and Online Both Options available</td>
                  <td><i class="fal fa-check-circle"></i></td>
                  <td><i class="fal fa-times-circle"></i></td>
              </tr>
              <tr id='show_more_row'>
                  <td colspan="3" (click)='show_more()' style="cursor: pointer;" class='text-center orangeText'>See
                      All
                      Features <i class=" ml-2 far fa-chevron-down"></i></td>
              </tr> -->
      </table>
      <!-- <table class="mr-3 ml-3 mb-3" id="more-columns" style="display: none; margin-top: -48px;">
              <thead style="visibility: hidden;">
                  <tr>
                      <th class=" boldText" style="background-color: #2f5698 !important; color: #fff">
                          Comparison of
                          Course/Training</th>
                      <th class="boldText" style='color:#2f5698 !important ;background-color: #f7f7f7 !important;'>
                          Mechatron Robotics</th>
                      <th class="boldText " style='color:#2f5698 !important ;background-color: #f7f7f7 !important;'>
                          Others
                          Classes</th>
                  </tr>
              </thead>
              <tr>
                  <td>16. Inhouse Developed Project Kits through research and development.</td>
                  <td><i class="fal fa-check-circle"></i></td>
                  <td><i class="fal fa-times-circle"></i></td>
              </tr>
              <tr>
                  <td>17. 7+ Years of Experience in the field of Robotics, AI and Coding</td>
                  <td><i class="fal fa-check-circle"></i></td>
                  <td><i class="fal fa-times-circle"></i></td>
              </tr>
              <tr>
                  <td>18. Competition Training for Students to Participate in Various Competitions</td>
                  <td><i class="fal fa-check-circle"></i></td>
                  <td><i class="fal fa-times-circle"></i></td>
              </tr>
              <tr>
                  <td>19. Experienced trainers with years on experiences in the same field.</td>
                  <td><i class="fal fa-check-circle"></i></td>
                  <td><i class="fal fa-times-circle"></i></td>
              </tr>
              <tr>
                  <td>20. Experience of Participating in Participating in Various Robotics Competitions.</td>
                  <td><i class="fal fa-check-circle"></i></td>
                  <td><i class="fal fa-times-circle"></i></td>
              </tr>
              <tr>
                  <td>21. Experience of Teaching Students of IIT</td>
                  <td><i class="fal fa-check-circle"></i></td>
                  <td><i class="fal fa-times-circle"></i></td>
              </tr>
              <tr>
                  <td>22. Team Based Learning / Group Learning / Group Project Building.</td>
                  <td><i class="fal fa-check-circle"></i></td>
                  <td><i class="fal fa-times-circle"></i></td>
              </tr>
              <tr>
                  <td>23. Regular Quizzes, Assignments and Project Presentations.</td>
                  <td><i class="fal fa-check-circle"></i></td>
                  <td><i class="fal fa-times-circle"></i></td>
              </tr>
              <tr>
                  <td>24. Experienced trainers with years on experiences in the same field.</td>
                  <td><i class="fal fa-check-circle"></i></td>
                  <td><i class="fal fa-times-circle"></i></td>
              </tr>
              <tr>
                  <td>25. Affordable Course Fee (Value for Money).</td>
                  <td><i class="fal fa-check-circle"></i></td>
                  <td><i class="fal fa-times-circle"></i></td>
              </tr>
              <tr>
                  <td>26. Courses for Both School , College and Professionals.</td>
                  <td><i class="fal fa-check-circle"></i></td>
                  <td><i class="fal fa-times-circle"></i></td>
              </tr>
              <tr>
                  <td>27. Internship Opportunity For Students.</td>
                  <td><i class="fal fa-check-circle"></i></td>
                  <td><i class="fal fa-times-circle"></i></td>
              </tr>
              <tr>
                  <td>28. 15+ Training Centers to Learn Robotics in India & Abroad</td>
                  <td><i class="fal fa-check-circle"></i></td>
                  <td><i class="fal fa-times-circle"></i></td>
              </tr>
              <tr>
                  <td>29. Certified Curriculum with Practical Projects – 90% Projects, 10% Theory</td>
                  <td><i class="fal fa-check-circle"></i></td>
                  <td><i class="fal fa-times-circle"></i></td>
              </tr>
              <tr>
                  <td>30. 95% Students usually complete all courses from basic to advanced.</td>
                  <td><i class="fal fa-check-circle"></i></td>
                  <td><i class="fal fa-times-circle"></i></td>
              </tr>
              <tr>
                  <td>31. Support students to publish projects in national and international Platforms.</td>
                  <td><i class="fal fa-check-circle"></i></td>
                  <td><i class="fal fa-times-circle"></i></td>
              </tr>
               </span>

              <tr id='show_less_row' style="display: none;">
                  <td colspan="3" (click)="show_less();scroll(table_top)" style="cursor: pointer;"
                      class='text-center orangeText'>
                      See
                      Less
                      Features
                      <i class=" ml-2 far fa-chevron-up"></i>
                  </td>
              </tr>
          </table> -->
    </div>
  </div>
</div>

<div class="mg-top first-top left-back-img">
  <div class="container text-center margin_top">
    <h2 class="blackText seaction-heading">
      <span class="boldText">Project&nbsp;</span>Videos&nbsp;<span class="boldText">of</span>&nbsp;our<span
        class="orangeText">&nbsp; Jr. Scientists</span>
    </h2>

    <div id="myCarousel" class="carousel slide" data-ride="carousel" data-interval="0">
      <!-- Wrapper for carousel items -->
      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="row mt-4">
            <div class="col-md-6 col-sm-6 video-div-left">
              <div class="card video-card">
                <!-- <img class="img-fluid video-img" src="../../../../../../assets/images/new-courses/videofirst.png"
                                      alt=""> -->
                <iframe class="img-fluid video-img" src="https://www.youtube.com/embed/uRjdkVH34DM"
                  title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
                <!-- <p class="text-center video-text">Know more about us through the videos</p> -->
              </div>
            </div>

            <div class="col-md-6 col-sm-6 video-div-right">
              <div class="card video-card">
                <!-- <img class="img-fluid video-img" src="../../../../../../assets/images/new-courses/videofirst.png"
                                      alt=""> -->
                <iframe class="img-fluid video-img" src="https://www.youtube.com/embed/OXhwMVyZAn4"
                  title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
                <!-- <p class="text-center video-text">Know more about us through the videos</p> -->
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="row mt-4">
            <div class="col-md-6 col-sm-6 video-div-left">
              <div class="card video-card">
                <!-- <img class="img-fluid video-img" src="../../../../../../assets/images/new-courses/videofirst.png"
                                      alt=""> -->
                <iframe class="img-fluid video-img" src="https://www.youtube.com/embed/mNJ-nYtfm2w"
                  title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
                <!-- <p class="text-center video-text">Know more about us through the videos</p> -->
              </div>
            </div>

            <div class="col-md-6 col-sm-6 video-div-right">
              <div class="card video-card">
                <!-- <img class="img-fluid video-img" src="../../../../../../assets/images/new-courses/videofirst.png"
                                      alt=""> -->
                <iframe class="img-fluid video-img" src="https://www.youtube.com/embed/80pjYX32W6c"
                  title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
                <!-- <p class="text-center video-text">Know more about us through the videos</p> -->
              </div>
            </div>
          </div>
        </div>
        <!-- Carousel controls -->
        <a class="carousel-control-prev" href="#myCarousel" data-slide="prev">
          <i class="fa fa-angle-left"></i>
        </a>
        <a class="carousel-control-next" href="#myCarousel" data-slide="next">
          <i class="fa fa-angle-right"></i>
        </a>
      </div>
    </div>
  </div>
  <!-- </div> -->

  <!-- feedback -->
  <!-- <div class="right-back-img mg-top first-top">
    <section class="margin_top">
      <div class="container">
        <h2 class="blackText seaction-heading">
          <span class="orangeText">Feedback</span>&nbsp;from&nbsp;<span
            class="boldText"
            >our</span
          ><span class="orangeText">&nbsp;Students&nbsp;</span>and<span
            class="boldText"
            >&nbsp;their&nbsp;</span
          >parents
        </h2>
        <p class="text-center main-feature-paragraph mt-4">
          Get to know from our students about the transformation they have
          experienced and the benefits they have <br />
          received at Mechatron Robotics
        </p>
        <div class="col-md-12 mt-5 test">
          <div class="row">
            <div
              id="carouselExampleControls-one"
              class="carousel slide courl"
              data-ride="carousel"
            >
              <ol class="carousel-indicators">
                <li
                  data-target="#carouselExampleControls-one"
                  data-slide-to="0"
                  class="active"
                ></li>
                <li
                  data-target="#carouselExampleControls-one"
                  data-slide-to="1"
                ></li>
                <li
                  data-target="#carouselExampleControls-one"
                  data-slide-to="2"
                ></li>
                <li
                  data-target="#carouselExampleControls-one"
                  data-slide-to="3"
                ></li>
              </ol>
              <div class="carousel-inner" role="listbox">
                <div class="container">
                  <div class="carousel-item active">
                    <div class="row m-0">
                      <div class="col-md-6">
                        <div class="feedback-card">
                          <div class="d-flex flex-column msg">
                            <p
                              class="main-feature-paragraph testimonial-detail"
                            >
                              "My son has completed Electronics and Arduino
                              basics at Mechatron Robotics. I am very happy with
                              the curriculum and the activities of the course
                              where the child gets ample scope for exploring new
                              ideas and concepts. The mentor helps to build the
                              problem solving skills amongst students which is
                              the best part of the course. The course has also
                              provided a platform to develop students
                              presentation skills. Thanks to Mechatron
                              Robotics!"
                            </p>
                          </div>
                          <br />
                          <div
                            class="
                              d-flex
                              justify-content-between
                              align-items-center
                              ml-2
                              photos
                            "
                          >
                            <div
                              class="
                                d-flex
                                justify-content-between
                                align-items-center
                              "
                            >
                              <img
                                class="rounded img-fluid"
                                src="../../../../../../assets/images/new-courses/feedback/anayashrivastav.png"
                                alt="Parents review about Mechatron Robotics"
                              />
                              <div
                                class="
                                  d-flex
                                  flex-column
                                  align-items-start
                                  ml-2
                                "
                              >
                                <h3 class="boldText">Ananya Shrivastava</h3>
                                <p class="feedback-fooer">(Parent, Guwahati)</p>
                              </div>
                            </div>
                            <i class="fas fa-quote-right fa-4x"></i>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="feedback-card">
                          <div class="d-flex flex-column msg">
                            <p
                              class="main-feature-paragraph testimonial-detail"
                            >
                              "I attended the workshop on Machine learning and
                              Arduino by Mechatron Robotics at IIT Kharagpur.
                              The entire workshop and faculty staff were
                              excellent. It was interesting as we got some good
                              hands-on training. I loved the way of teaching
                              i.e., learning by doing. The topics covered were
                              very relevant as per the industrial requirements.
                              I would like to attend more workshops & courses
                              offered by Mechatron."
                            </p>
                          </div>
                          <br />
                          <div
                            class="
                              d-flex
                              justify-content-between
                              align-items-center
                              mt-2
                              photos
                            "
                          >
                            <div
                              class="
                                d-flex
                                justify-content-between
                                align-items-center
                              "
                            >
                              <img
                                class="rounded img-fluid"
                                src="../../../../../../assets/images/new-courses/feedback/deepak.png"
                                alt="Student review of Mechatron Robotics"
                              />
                              <div
                                class="
                                  d-flex
                                  flex-column
                                  align-items-start
                                  ml-2
                                "
                              >
                                <h3 class="boldText">Deepak Gautam</h3>
                                <p class="feedback-fooer">
                                  (Student, IIT Kharagpur )
                                </p>
                              </div>
                            </div>
                            <i class="fas fa-quote-right fa-4x"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="carousel-item">
                    <div class="row m-0">
                      <div class="col-md-6">
                        <div class="feedback-card">
                          <div class="d-flex flex-column msg">
                            <p
                              class="main-feature-paragraph testimonial-detail"
                            >
                              "My son is loving the Coding classes at Mechatron
                              Robotics, the trainer always tries to make the
                              basics clear, he questions and gives them a chance
                              to find out solutions for the errors. In next few
                              levels I want my child to learn electronics and
                              Arduino. The trainer is fantastic who makes the
                              kids strong in fundamentals before getting to high
                              end concepts."
                            </p>
                          </div>
                          <br />
                          <div
                            class="
                              d-flex
                              justify-content-between
                              align-items-center
                              mt-2
                              photos
                            "
                          >
                            <div
                              class="
                                d-flex
                                justify-content-between
                                align-items-center
                              "
                            >
                              <img
                                class="rounded img-fluid"
                                src=".../../../../../../assets/images/new-courses/feedback/manjildas.png"
                                alt="Parents review about Mechatron Robotics"
                              />
                              <div
                                class="
                                  d-flex
                                  flex-column
                                  align-items-start
                                  ml-2
                                "
                              >
                                <h3 class="boldText">Mr. Manjil Das</h3>
                                <p class="feedback-fooer">
                                  ( Parent, Guwahati)
                                </p>
                              </div>
                            </div>
                            <i class="fas fa-quote-right fa-4x"></i>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="feedback-card">
                          <div class="d-flex flex-column msg">
                            <p
                              class="main-feature-paragraph testimonial-detail"
                            >
                              "It was a new experience for me which helped me
                              encourage myself for improving my knowledge in
                              electronics(robotics) as well as in coding. The
                              trainers appointed for the workshop held at St.
                              Mira's College For girls were very enthusiastic
                              and energetic. I'm willing to add up more
                              knowledge about electronics which will definitely
                              help me in future."
                            </p>
                          </div>
                          <br />
                          <div
                            class="
                              d-flex
                              justify-content-between
                              align-items-center
                              mt-2
                              photos
                            "
                          >
                            <div
                              class="
                                d-flex
                                justify-content-between
                                align-items-center
                              "
                            >
                              <img
                                class="rounded img-fluid"
                                src="../../../../../../assets/images/new-courses/feedback/uzma.png"
                                alt="Student review of Mechatron Robotics"
                              />
                              <div
                                class="
                                  d-flex
                                  flex-column
                                  align-items-start
                                  ml-2
                                "
                              >
                                <h3 class="boldText">Uzma Shaikh, Student</h3>
                                <p class="feedback-fooer">
                                  (St. Mira’s College)
                                </p>
                              </div>
                            </div>
                            <i class="fas fa-quote-right fa-4x"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="row m-0">
                      <div class="col-md-6">
                        <div class="feedback-card">
                          <div class="d-flex flex-column msg">
                            <p
                              class="main-feature-paragraph testimonial-detail"
                            >
                              "We were in India for a vacation, and I wanted my
                              son to develop basic robotics skills. Was
                              extremely happy with the course offered. Would
                              readily recommend to anyone who is looking for a
                              great starter course on electronics and robotics.
                              We will surely return to Mechatron Robotics next
                              year for their advanced courses. Wishing them all
                              the very best."
                            </p>
                          </div>
                          <br />
                          <div
                            class="
                              d-flex
                              justify-content-between
                              align-items-center
                              mt-2
                              photos
                            "
                          >
                            <div
                              class="
                                d-flex
                                justify-content-between
                                align-items-center
                              "
                            >
                              <img
                                class="rounded img-fluid"
                                src="../../../../../../assets/images/new-courses/feedback/deepali.png"
                                alt="Parents review about Mechatron Robotics"
                              />
                              <div
                                class="
                                  d-flex
                                  flex-column
                                  align-items-start
                                  ml-2
                                "
                              >
                                <h3 class="boldText">Deepali Maganti</h3>
                                <p class="feedback-fooer">(Singapore)</p>
                              </div>
                            </div>
                            <i class="fas fa-quote-right fa-4x"></i>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="feedback-card">
                          <div class="d-flex flex-column msg">
                            <p
                              class="main-feature-paragraph testimonial-detail"
                            >
                              "The best Robotics coaching available for kids.
                              They not only coach, but also help you in planning
                              your child’s journey right from the basic
                              electronics understanding to the advanced level
                              Robotics. The mentors are awesome. I'm really
                              delighted that I enrolled my son for the courses
                              offered by them. Long way to Go!! "
                            </p>
                          </div>
                          <br />
                          <div
                            class="
                              d-flex
                              justify-content-between
                              align-items-center
                              mt-2
                              photos
                            "
                          >
                            <div
                              class="
                                d-flex
                                justify-content-between
                                align-items-center
                              "
                            >
                              <img
                                class="rounded img-fluid"
                                src="../../../../../../assets/images/new-courses/feedback/mandeep.png"
                                alt="Parents review about Mechatron Robotics"
                              />
                              <div
                                class="
                                  d-flex
                                  flex-column
                                  align-items-start
                                  ml-2
                                "
                              >
                                <h3 class="boldText">Mandeep Singh Chahal</h3>
                                <p class="feedback-fooer">(Chandigarh)</p>
                              </div>
                            </div>
                            <i class="fas fa-quote-right fa-4x"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div class="row m-0">
                      <div class="col-md-6">
                        <div class="feedback-card">
                          <div class="d-flex flex-column msg">
                            <p
                              class="main-feature-paragraph testimonial-detail"
                            >
                              "I started my 10-year-old son's journey in
                              Robotics by enrolling him in 48 hours Electronics
                              and Circuit Program and now he is learning the
                              Basic Arduino course. Being an experienced
                              Automation Engineer and after 5 months of
                              association, I want to thank the Mechatron team
                              for their hard work and commitment in providing
                              high value learning experiences for my son and
                              help him build a strong foundation in technology
                              skill."
                            </p>
                          </div>
                          <br />
                          <div
                            class="
                              d-flex
                              justify-content-between
                              align-items-center
                              mt-2
                              photos
                            "
                          >
                            <div
                              class="
                                d-flex
                                justify-content-between
                                align-items-center
                              "
                            >
                              <img
                                class="rounded img-fluid"
                                src="../../../../../../assets/images/new-courses/feedback/ankit.png"
                                alt="Parents review about Mechatron Robotics"
                              />
                              <div
                                class="
                                  d-flex
                                  flex-column
                                  align-items-start
                                  ml-2
                                "
                              >
                                <h3 class="boldText">Ankit Srivastav</h3>
                                <p class="feedback-fooer">(Parent, Mumbai)</p>
                              </div>
                            </div>
                            <i class="fas fa-quote-right fa-4x"></i>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="feedback-card">
                          <div class="d-flex flex-column msg">
                            <p
                              class="main-feature-paragraph testimonial-detail"
                            >
                              "Last year I got a chance to meet Mechatron
                              Robotics team. I was amazed to see kids (I believe
                              not more than 10 years old) taking full interest
                              in these courses, were very enthusiastic to learn
                              & accomplish practical assignments. They were not
                              in a mood to go back to their homes. There was a
                              real connection between them and trainers. I
                              believe this is best opportunity to grasp things
                              practically instead of sitting at home & playing
                              games or watching tv. Keep it up Guys!"
                            </p>
                          </div>
                          <br />
                          <div
                            class="
                              d-flex
                              justify-content-between
                              align-items-center
                              mt-2
                              photos
                            "
                          >
                            <div
                              class="
                                d-flex
                                justify-content-between
                                align-items-center
                              "
                            >
                              <img
                                class="rounded img-fluid"
                                src="../../../../../../assets/images/new-courses/feedback/mahinder.png"
                                alt="Parents review about Mechatron Robotics"
                              />
                              <div
                                class="
                                  d-flex
                                  flex-column
                                  align-items-start
                                  ml-2
                                "
                              >
                                <h3 class="boldText">Maninder Garg</h3>
                                <p class="feedback-fooer">
                                  ( Parent, Amsterdam)
                                </p>
                              </div>
                            </div>
                            <i class="fas fa-quote-right fa-4x"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
  <!-- <div class="carousel-item">
                                      <div class="row m-0">

                                          <div class="col-md-6 ">
                                              <div class="feedback-card">
                                                  <div class="d-flex flex-column">

                                                      <p class="main-feature-paragraph" style="text-align: justify; height: 25vh;">
                                                          "The Robotics workshop was simply
                                                          excellent.
                                                          The way Robotics was taught was unique by the trainers.
                                                          I learned circuit designing. It should be conducted every
                                                          year
                                                          in
                                                          our
                                                          school.
                                                          We really enjoyed and learnt new things with you."
                                                      </p>
                                                  </div>

                                                  <div
                                                      class="d-flex  justify-content-between align-items-center mt-2">
                                                      <div
                                                          class="d-flex   justify-content-between align-items-center">
                                                          <img class="rounded"
                                                              src="../../../../../../assets/images/new-courses/profile.png"
                                                              alt="">
                                                          <div class="d-flex flex-column align-items-start ml-2 ">
                                                              <h3 class="boldText">John Doe</h3>
                                                              <p class="feedback-fooer">(Student, Australia)</p>
                                                          </div>

                                                      </div>
                                                      <i class="fas fa-quote-right fa-4x "></i>
                                                  </div>
                                              </div>


                                          </div>
                                          <div class="col-md-6 ">
                                              <div class="feedback-card">
                                                  <div class="d-flex flex-column">

                                                      <p class="main-feature-paragraph" style="text-align: justify; height: 25vh;">
                                                          "The Robotics workshop was simply
                                                          excellent.
                                                          The way Robotics was taught was unique by the trainers.
                                                          I learned circuit designing. It should be conducted every
                                                          year
                                                          in
                                                          our
                                                          school.
                                                          We really enjoyed and learnt new things with you."

                                                      </p>

                                                  </div>

                                                  <div
                                                      class="d-flex  justify-content-between align-items-center mt-2">
                                                      <div
                                                          class="d-flex   justify-content-between align-items-center">
                                                          <img class="rounded"
                                                              src="../../../../../../assets/images/new-courses/profile.png"
                                                              alt="">
                                                          <div class="d-flex flex-column align-items-start ml-2 ">
                                                              <h3 class="boldText">John Doe</h3>
                                                              <p class="feedback-fooer">(Student, Australia)</p>
                                                          </div>

                                                      </div>
                                                      <i class="fas fa-quote-right fa-4x "></i>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="carousel-item">
                                      <div class="row m-0">

                                          <div class="col-md-6 ">
                                              <div class="feedback-card">
                                                  <div class="d-flex flex-column">

                                                      <p class="main-feature-paragraph" style="text-align: justify; height: 25vh;">
                                                          "The Robotics workshop was simply
                                                          excellent.
                                                          The way Robotics was taught was unique by the trainers.
                                                          I learned circuit designing. It should be conducted every
                                                          year
                                                          in
                                                          our
                                                          school.
                                                          We really enjoyed and learnt new things with you."
                                                      </p>
                                                  </div>

                                                  <div
                                                      class="d-flex  justify-content-between align-items-center mt-2">
                                                      <div
                                                          class="d-flex   justify-content-between align-items-center">
                                                          <img class="rounded"
                                                              src="../../../../../../assets/images/new-courses/profile.png"
                                                              alt="">
                                                          <div class="d-flex flex-column align-items-start ml-2 ">
                                                              <h3 class="boldText">John Doe</h3>
                                                              <p class="feedback-fooer">(Student, Australia)</p>
                                                          </div>

                                                      </div>
                                                      <i class="fas fa-quote-right fa-4x "></i>
                                                  </div>
                                              </div>


                                          </div>
                                          <div class="col-md-6 ">
                                              <div class="feedback-card">
                                                  <div class="d-flex flex-column">

                                                      <p class="main-feature-paragraph" style="text-align: start;">
                                                          "The Robotics workshop was simply
                                                          excellent.
                                                          The way Robotics was taught was unique by the trainers.
                                                          I learned circuit designing. It should be conducted every
                                                          year
                                                          in
                                                          our
                                                          school.
                                                          We really enjoyed and learnt new things with you."

                                                      </p>

                                                  </div>

                                                  <div
                                                      class="d-flex  justify-content-between align-items-center mt-2">
                                                      <div
                                                          class="d-flex   justify-content-between align-items-center">
                                                          <img class="rounded"
                                                              src="../../../../../../assets/images/new-courses/profile.png"
                                                              alt="">
                                                          <div class="d-flex flex-column align-items-start ml-2 ">
                                                              <h3 class="boldText">John Doe</h3>
                                                              <p class="feedback-fooer">(Student, Australia)</p>
                                                          </div>

                                                      </div>
                                                      <i class="fas fa-quote-right fa-4x "></i>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div> -->
  <!-- </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div> -->

  <!-- traning center -->
  <!-- <div class="margin_top container-fluid traning-back  d-flex justify-center align-items-center">
      <div class="container mt-5 mb-5">
          <div class="col-md-12 text-center p-3">
              <h2 class="orangeText seaction-heading" style="font-size: 16px;">Join Us Today</h2>
              <h2 class="boldText">To Locate Your Nearest Training Center, Click Below</h2>

              <button type="button" class="buttonaction-course  mt-2 mb-2 w-45" routerLink="/trainingcenter">Go to
                  Training Center
              </button>
          </div>
      </div>
  </div> -->

  <!-- traning center -->
  <div class="
  margin_top
  container-fluid
  images
  d-flex
  justify-center
  align-items-center
">
    <div class="container mt-4 mb-4">
      <div class="col-md-12 text-center p-3">
        <h2 class="orangeText seaction-heading" style="font-size: 16px; font-weight: 800">
          Join Us Today
        </h2>
        <h2 class="boldText">Enrol for our latest courses, Click Below</h2>
        <a type="button" class="my-btn" href="/book-trial-class">
          BOOK TRIAL CLASS NOW
        </a>
      </div>
    </div>
  </div>

  <!-- FAQ -->
  <div class="margin_top container" style="margin-bottom: 80px">
    <h2 class="blackText seaction-heading">
      <span class="boldText">Frequently</span> &nbsp;asked&nbsp;<span class="orangeText">Questions</span>
    </h2>
    <div class="row mt-5 mb-5">
      <div class="col-md-12">
        <div class="faq">
          <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
            <div class="panel">
              <div class="panel-heading" role="tab" id="headingOne">
                <h3 class="panel-title">
                  <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse1st"
                    aria-expanded="false" aria-controls="collapse1st"><span>What skills do the students develop at
                      Mechatron
                      robotics?</span>
                  </a>
                </h3>
              </div>
              <div id="collapse1st" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                <div class="panel-body">
                  <p>
                    Students learn various technical skills like Coding,
                    Electronics, Mechanics, Mechanical design, Arduino, IOT and
                    many more practical courses. Apart from the technical skills
                    students also develop various non -technical skills like
                    creativity, innovation, communication skills, logical
                    thinking, problem solving skills & teamwork.
                  </p>
                </div>
              </div>
            </div>
            <div class="panel">
              <div class="panel-heading" role="tab" id="headingTwo">
                <h3 class="panel-title">
                  <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse2nd"
                    aria-expanded="false" aria-controls="collapse2nd">How long does it take to complete a course /
                    level?</a>
                </h3>
              </div>
              <div id="collapse2nd" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                <div class="panel-body">
                  <p>
                    Most of our courses are developed keeping in mind the
                    students develop a complete understanding of the topic. So,
                    each of our course / level is nearly 48 hrs. long which
                    includes extensive number of projects, assignments, quizzes
                    & project presentations.
                  </p>
                </div>
              </div>
            </div>
            <div class="panel">
              <div class="panel-heading" role="tab" id="headingThree">
                <h3 class="panel-title">
                  <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse3rd"
                    aria-expanded="false" aria-controls="collapse3rd">What are the benefits of learning Robotics at an
                    early age?
                  </a>
                </h3>
              </div>
              <div id="collapse3rd" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                <div class="panel-body">
                  <ul>
                    <li>
                      Numerous Research suggests Robotics as one of the best and
                      practical ways of STEM Education. Students really love it.
                    </li>
                    <li>
                      Project based education increases the logical thinking of
                      kids.
                    </li>
                    <li>
                      Projects also increases the Problem-solving abilities of
                      kids.
                    </li>
                    <li>One of the best ways of increasing IQ levels.</li>
                    <li>
                      Induces creativity as kids are motivated to use their own
                      imagination to build projects.
                    </li>
                    <li>
                      Helps develops the confidence of a child as they
                      participate in various Robotics competitions.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="panel">
              <div class="panel-heading" role="tab" id="headingfour">
                <h3 class="panel-title">
                  <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse4st"
                    aria-expanded="false" aria-controls="collapse4st">How different Mechatron robotics is and why you
                    should be
                    part of it?
                  </a>
                </h3>
              </div>
              <div id="collapse4st" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingfour">
                <div class="panel-body">
                  <p>
                    At Mechatron, we not only develop the students in technical
                    skills but also with various non-technical skills. Our
                    trainers are mainly from electronics background and provide
                    really great knowledge compared to any other company. Our
                    courses are also designed in-depth for 48 hours and are
                    affordable for students.
                  </p>
                </div>
              </div>
            </div>
            <div class="panel">
              <div class="panel-heading" role="tab" id="headingfive">
                <h3 class="panel-title">
                  <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse5nd"
                    aria-expanded="false" aria-controls="collapse5nd">How can I track my child’s progress?</a>
                </h3>
              </div>
              <div id="collapse5nd" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingfive">
                <div class="panel-body">
                  <p>
                    Our trainers share the progress of the child regularly with
                    the parents. We also provide a detailed evaluation on
                    various skills like creativity, efforts taken to build extra
                    projects, team work, presentation and communication skills,
                    perseverance, problem solving skills and logical thinking at
                    the end of the course. We also take periodic quizzes, give
                    assignments to assess a child's understanding of the
                    subject.
                  </p>
                </div>
              </div>
            </div>
            <div class="panel">
              <div class="panel-heading" role="tab" id="headingsix">
                <h3 class="panel-title">
                  <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse6rd"
                    aria-expanded="false" aria-controls="collapse6rd">
                    Do you provide certificate, after completion of courses?
                  </a>
                </h3>
              </div>
              <div id="collapse6rd" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingsix">
                <div class="panel-body">
                  <p>
                    Yes, we do provide certificates after the successful completion of the course. The student needs to
                    give a final exam and a project presentation in order to earn his/her certificate.
                  </p>
                </div>
              </div>
            </div>
            <div class="panel">
              <div class="panel-heading" role="tab" id="headingseven">
                <h3 class="panel-title">
                  <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse7th"
                    aria-expanded="false" aria-controls="collapse7th">
                    Do the students need to be technically sound in order to
                    avail online/ offline courses?
                  </a>
                </h3>
              </div>
              <div id="collapse7th" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headinseven">
                <div class="panel-body">
                  <p>
                    No, since we provide the courses from fundamental level to
                    advanced level, any student can enrol as per his/her
                    interest. Most important criteria is they should have
                    interest towards technology & learn these skills.
                  </p>
                </div>
              </div>
            </div>
            <div class="panel">
              <div class="panel-heading" role="tab" id="headingeigt">
                <h3 class="panel-title">
                  <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse8th"
                    aria-expanded="false" aria-controls="collapse8th">
                    Do you think the course increases the out-of-school learning
                    opportunities?
                  </a>
                </h3>
              </div>
              <div id="collapse8th" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingeight">
                <div class="panel-body">
                  <p>
                    Yes, our courses increase the out of school learning
                    opportunities as the school courses are designed according
                    to a certain curriculum whereas our courses are more
                    practical and framed in a way that relates to day-to-day
                    activities and the practical world. This makes students more
                    enthusiastic towards the learning process and as they can
                    apply the knowledge and build something innovative.
                  </p>
                </div>
              </div>
            </div>
            <div class="panel">
              <div class="panel-heading" role="tab" id="headingnine">
                <h3 class="panel-title">
                  <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse9th"
                    aria-expanded="false" aria-controls="collapse9th">
                    Do you provide kits along with the course?
                  </a>
                </h3>
              </div>
              <div id="collapse9th" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingnine">
                <div class="panel-body">
                  <p>
                    Kits are not required for any of the online courses. However
                    students can buy the kits by paying extra as per the kit
                    price. And we deliver it to their home. Even for the Offline
                    / Classroom based courses the training centre / Lab has all
                    the Kits and equipment for the students to learn. However
                    students can buy the kits by paying extra as per the kit
                    price.
                  </p>
                </div>
              </div>
            </div>
            <div class="panel">
              <div class="panel-heading" role="tab" id="headingten">
                <h3 class="panel-title">
                  <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse10th"
                    aria-expanded="false" aria-controls="collapse10th">
                    Are the trainers easily accessible for the students who need
                    extra help?
                  </a>
                </h3>
              </div>
              <div id="collapse10th" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingten">
                <div class="panel-body">
                  <p>
                    Yes. Our trainers help students achieve extra mile and extra
                    projects, by mentoring them. In case of any competitions,
                    our trainers help students to solve various tasks and
                    challenges as per the guidelines of the competition. Our
                    students have achieved many national and international level
                    championships with the help of their mentors.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-md-12">
              <div class="faq">
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                  <div class="panel">
                    <div class="panel-heading" role="tab" id="headingfour">
                      <h3 class="panel-title">
                        <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse4st"
                          aria-expanded="false" aria-controls="collapse4st">How different Mechatron robotics is and why you should be
                         <span style="margin-left: 33px;"> part of it?</span>
                        </a>
                      </h3>
                    </div>
                    <div id="collapse4st" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingfour">
                      <div class="panel-body">
                        <p>At Mechatron we not only develop the students in technical things but also various non-technical skills. Our Trainers are mainly from electronics background and provide really great knowledge compared to any other company. Our Courses are also designed in-depth for 48 hours and affordable for students. Read above the 30+ reasons how we are different from any other similar company in the world.</p>

                      </div>
                    </div>
                  </div>
                  <div class="panel">
                    <div class="panel-heading" role="tab" id="headingfive">
                      <h3 class="panel-title">
                        <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse5nd"
                          aria-expanded="false" aria-controls="collapse5nd">How can I track my child’s progress?</a>
                      </h3>
                    </div>
                    <div id="collapse5nd" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingfive">
                      <div class="panel-body">
                        <p>Our trainers share the progress of the child regularly with the parents. We also provide a detailed evaluation on various skills like creativity, efforts taken to build extra projects, team work, presentation and communication skills, perseverance, problem solving skills and logical thinking at the end of the course. We also take periodic quizzes, give assignments to assess a child's understanding of the subject.</p>

                      </div>
                    </div>
                  </div>
                  <div class="panel ">
                    <div class="panel-heading" role="tab" id="headingsix">
                      <h3 class="panel-title">
                        <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse6rd"
                          aria-expanded="false" aria-controls="collapse6rd">
                          Do you provide certificate, after completion of courses?
                          </a>
                      </h3>
                    </div>
                    <div id="collapse6rd" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingsix">
                      <div class="panel-body">
                        <p>Yes, we do provide certificates after the successful completion of the course. The student needs to give a final exam and a presentation on Graduation Day in order to earn his certificate.</p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>  -->
    </div>
  </div>
</div>
