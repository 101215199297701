import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aboutus-page',
  templateUrl: './aboutus-page.component.html',
  styleUrls: ['./aboutus-page.component.css']
})
export class AboutusPageComponent implements OnInit {
  showprofileHeath = false;
  showprofileChristine = false;
  showprofileTony = false;
  constructor() { }

  ngOnInit(): void {
    ​//window.scrollTo(0, 0);
  }
  showProfileHeath(){
    this.showprofileHeath=true
  }
  hideProfileHeath(){
    this.showprofileHeath=false
  }

  showProfileChristine(){
    this.showprofileChristine=true
  }
  hideProfileChristine(){
    this.showprofileChristine=false
  }

  showProfileTony(){
    this.showprofileTony=true
  }
  hideProfileTony(){
    this.showprofileTony=false
  }

}
