<header id="js-header" class="u-header u-header--sticky-top u-header--change-logo" data-header-fix-moment="0">
    <div class="u-header__section bg-grey g-transition-0_3 g-py-7 g-py-0--md "
        data-header-fix-moment-exclude="bg-grey g-py-23--md"
        data-header-fix-moment-classes="u-shadow-v19 bg-grey g-py-15--md">
        <nav class="navbar navbar-expand-lg navbar-light bg-light navbar-expand-lg g-py-0">
            <div class="container g-pos-rel">
                <a routerLink="/" fragment="home" href="#home" class="js-go-to navbar-brand mr-0 logo"
                    data-type="static">
                    <img class="d-block g-width-150 g-width-160--md m-top--35"
                        src="../../../../assets/images/icons/logo.png" alt="Image Description"
                        data-header-fix-moment-exclude="d-block" data-header-fix-moment-classes="d-none"
                        style="padding: 5px 0px 5px 0px;width: 80% !important;">
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse align-items-center flex-sm-row" id="navbarNavDropdown"
                    style="margin-top: -8px;">
                    <ul id="js-scroll-nav"
                        class="js-scroll-nav navbar-nav text-uppercase g-font-weight-600 g-font-size-14 g-letter-spacing-1 g-pt-20 g-pt-0--lg ml-auto font-signika">
                        <li class="nav-item g-mx-12--lg g-mb-7 g-mb-0--lg">
                            <a routerLink="/" class="nav-link p-0 case">Home</a>
                        </li>
                        <li class="nav-item g-mx-12--lg g-mb-7 g-mb-0--lg">
                            <a routerLink="/courses" class="nav-link p-0 case">Our Courses</a>
                        </li>

                        <li class="nav-item g-mx-12--lg g-mb-7 g-mb-0--lg">
                            <a class="nav-link p-0 case" routerLink='/aboutus'>About Us</a>
                        </li>

                        <!-- <li class="nav-item g-mx-12--lg g-mb-7 g-mb-0--lg">
                            <a class="nav-link p-0 case" routerLink="/contactus">Contact Us</a>
                        </li> -->


                        <!-- <li class="nav-item g-mx-12--lg g-mb-7 g-mb-0--lg">
                          <a routerLink="/franchisee" class="nav-link p-0 case">Franchisee</a>
                      </li> -->
                        <!-- <li class="nav-item g-mx-12--lg g-mb-7 g-mb-0--lg">
                          <a routerLink="/schools" class="nav-link p-0 case" >Schools</a>
                      </li> -->
                        <!-- <li class="nav-item g-mx-12--lg g-mb-7 g-mb-0--lg dropdown">

                          <a href="#" class="nav-link p-0 " id="navbarDropdown dropdown-toggle" data-toggle="dropdown"
                              aria-haspopup="true" aria-expanded="false">Our Services <i
                                  class="fa fa-angle-down"></i>
                          </a>
                          <div class="dropdown-menu headermenu1" aria-labelledby="navbarDropdown">

                              <a class="dropdown-item view-effect" routerLink="/offerings/training"> Training
                                  Courses</a>
                              <a class="dropdown-item view-effect" routerLink="/offerings/workshop">Robotics
                                  Workshop</a>
                              <a class="dropdown-item view-effect" routerLink="/offerings/online">Online Robotics</a>
                              <a class="dropdown-item view-effect" routerLink="/offerings/courses">Courses</a>
                              <a class="dropdown-item view-effect" routerLink="/offerings/robotics-labs">Robotics
                                  Labs</a>
                              <a class="dropdown-item view-effect" routerLink="/offerings/kits">Robot Part & Kits</a>
                              <a class="dropdown-item view-effect" routerLink="/offerings/roboticsfranchise">Robotics
                                  Franchise</a>
                              <a class="dropdown-item view-effect" routerLink="/offerings/competition">Robotics
                                  Competition</a>
                          </div>

                      </li> -->

                        <!-- dropdown options -->
                        <!-- <li class="nav-item g-mx-12--lg g-mb-7 g-mb-0--lg dropdown">
                            <a href="#" class="nav-link p-0 case" id="navbarDropdown dropdown-toggle"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">More <i
                                    class="fa fa-angle-down"></i></a>
                            <div class="dropdown-menu headermenu1" aria-labelledby="navbarDropdown">
                                <a class="dropdown-item view-effect case" routerLink='/aboutus'>About Us</a>
                                <a routerLink='/trainingcenter' class="dropdown-item view-effect">Training Centers</a>
                              <a class="dropdown-item view-effect" routerLink='/' fragment="events"
                                  href="#events">Events</a>
                                <a class="dropdown-item view-effect" routerLink='/' fragment="reviews"
                                  href="#reviews">Reviews</a>
                                <a class="dropdown-item view-effect case" routerLink="/careers">Careers</a>
                                <a class="dropdown-item view-effect" routerLink="/blogs/blog">Blogs</a>
                                <a class="dropdown-item view-effect case" routerLink="/sponsors">Sponsors</a>
                            </div>
                        </li> -->



                        <li class="nav-item g-mx-12--lg g-mb-7 g-mb-0--lg">
                            <a routerLink="/book-trial-class" class="home-top-btn"><button
                                    class="btn-book-class font11 fontfamily"> Book Trial Class </button></a>
                        </li>
                        <!-- <li class="nav-item g-mx-12--lg g-mb-7 g-mb-0--lg" *ngIf="!isLoggedIn">
                          <a href="https://mechatronrobotics.com/login" class="home-top-btn"> <button
                                  class="btn-login-class font11 fontfamily">Login</button> </a>
                      </li> -->


                        <li class="nav-item  g-mx-12--lg g-mb-7 g-mb-0--lg" *ngIf="isLoggedIn">
                            <a class="home-top-btn" href="" data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <span *ngIf="currentUser">
                                    <h4 style="float:left; font-size: 15px; text-transform: none;">{{nameInitials}}</h4>
                                </span>
                                <span *ngIf="!currentUser">
                                    <div class="u-img"> NA</div>
                                </span>
                                <span *ngIf="userDetailsProfile.profile_pic" class="ml-2">
                                    <img src="{{userDetailsProfile.profile_pic}}" alt="user" class="img-circle"
                                        width="35px" height='35px' style="position: relative; top: -5px;" />
                                </span>
                                <span *ngIf="!userDetailsProfile.profile_pic" class="ml-2">
                                    <span class="img-circle"
                                        style="font-size: 25px; color: #2F5698; position: relative; top: -7px;"> <i
                                            class="fa fa-user" aria-hidden="true" style="position: relative;"></i>
                                    </span>
                                </span>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right ">
                                <ul class="dropdown-user" style="padding-left: 20px;">
                                    <li>
                                        <div class="dw-user-box pr-5 pt-2 " style="
                                  transform: translate(0px, 0px);">
                                            <div class="u-text m-nav-desktop-li-bg">
                                                <h5 style="cursor: pointer;font-size: 15px;" (click)="goToDashboard()">
                                                    <i class="fal fa-chart-bar " aria-hidden="true"
                                                        style="width: 25px;"></i> Dashboard
                                                </h5>
                                                <h5 style="cursor: pointer;font-size: 15px;"
                                                    (click)="routeToViewContact()">
                                                    <i class="fal fa-user " aria-hidden="true" style="width: 25px;"></i>
                                                    My
                                                    Profile
                                                </h5>
                                                <h5 *ngIf='!isAdmin' style="cursor: pointer;"
                                                    (click)='onMyAccountSelect()'
                                                    style="font-size: 15px; cursor: pointer"> <i
                                                        class="fal fa-user-circle" aria-hidden="true"
                                                        style="width: 25px;"></i> My Account</h5>
                                                <h5 style="cursor: pointer;font-size: 15px;" (click)="changePassword()">
                                                    <i class="fal fa-key" aria-hidden="true" style="width: 25px;"></i>
                                                    Change Password
                                                </h5>
                                            </div>

                                        </div>
                                        <a (click)="logout()"
                                            style="cursor: pointer; line-height: 2.75rem; color: #9A0000;">
                                            <i class="fal fa-power-off"></i> Logout</a>
                                </ul>
                                <hr style="margin: 0; position: relative; top: -40px;" />

                            </div>
                        </li>

                        <li class="nav-item g-mx-12--lg g-mb-7 g-mb-0--lg dropdown globIcon">
                            <a href="#" class="nav-link p-0 case" id="navbarDropdown dropdown-toggle"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                                    class="far fa-globe"></i></a>
                            <div class="dropdown-menu headermenu1" aria-labelledby="navbarDropdown">
                                <a class="dropdown-item" href="https://mechatronrobotics.com.au/" target="_blank">
                                    <img src="../../../../assets/images/australia-flag.png" alt="">
                                    <span class="ml-2">Australia</span>
                                </a>
                                <a class="dropdown-item" href="https://mechatronrobotics.com/" target="_blank">
                                    <img src="../../../../assets/images/india-flag.png" alt="">
                                    <span class="ml-2">India</span>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
                <button id="toggle-nav"
                    class="navbar-toggler btn g-line-height-1 g-brd-none g-pa-0 g-pos-abs g-top-15 g-right-0"
                    type="button" aria-label="Toggle navigation" aria-expanded="false" aria-controls="navBar"
                    data-toggle="collapse" data-target="#navBar1,#navBar2">
                    <span class="hamburger hamburger--slider">
                        <span class="hamburger-box">
                            <span class="hamburger-inner"></span>
                        </span>
                    </span>
                </button>
            </div>
        </nav>
    </div>
</header>