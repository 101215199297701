export class Careers {
  name!: String;
  email!: String;
  mobile!: String;
  alt_mobile!: String;
  country!: String;
  location!: String;
  account!: String;
  account_name!: String;
  position!: String;
  resume!:String;
  notes!:String;
}
