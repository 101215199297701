
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CourseDataService } from '../../course-data.service';

@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.css']
})
export class CourseDetailsComponent implements OnInit {
  productTitle: string;
  courseTitle: string;
  productData: any;
  storagepath: any;
  course_loader=false;
  courseReviews: any = [];
  mostHelpfulReview: any = [];
  ratingPercentage: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private courseData:CourseDataService,
  ) {
    this.productTitle = this.activatedRoute.snapshot.params.title;
    this.courseTitle = this.activatedRoute.snapshot.params.category;
    this.getCourseDetailsbytitle(this.productTitle);
  }

  ngOnInit(): void {
  }

  getCourseDetailsbytitle(title: any) {
    this.course_loader=false
    let urlString = title.includes('&');
    let newUrl: any;
    if(urlString) {
      newUrl = title.replace('&','%26')
    }else {
      newUrl = title;
    }
    this.courseData.filterProductByTitleLog(newUrl).subscribe((res: any) => {
      this.productData = res['data'][0];
      this.storagepath = res['storage_path'];
      if(this.productData){
        this.ratingPercentage = Object.keys(this.productData?.rating_percents).map((key) => this.productData.rating_percents[key]);

      // this.loader = false
      this.getCourseReviews(this.productData._id);
      this.getMostHelpfulReview(this.productData._id);
      }
       if(!this.productData) {
    this.course_loader=true;

       }
    this.course_loader=true;
      },
    error =>{
      console.log(error.message)
      this.course_loader = true;
    }
    )
  }


  getCourseReviews(id: any) {
    this.courseData.getReviewListByProductId(id).subscribe((res: any) => {
      if (res) {
        this.courseReviews = res.data;
      }
    });
  }

  viewCourseImage(url: any) {
    let result;
    url?.forEach((element: any) => {
      if(!element.url ||element.url == null || element.url == '') {
        result = element.url;
      }
      else {
        result = element.url;
      }
    });
    let defaultImg;
    if (result == null || result == '' || result == undefined) {
      defaultImg = '/assets/images/courses/defaultpic.png';
      return defaultImg;
    }

    else {

      return this.storagepath + result;
    }
  }



  getMostHelpfulReview(id: any) {
    this.courseData.mostHelfulreview(id).subscribe((res: any) => {
      if (res) {
        this.mostHelpfulReview = res.data[0];
      }
    })
  }



}
