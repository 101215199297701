import { Component, OnInit } from '@angular/core';
import {MatDialogRef, MatDialogModule} from '@angular/material/dialog';
@Component({
  selector: 'app-launching-dialoge',
  templateUrl: './launching-dialoge.component.html',
  styleUrls: ['./launching-dialoge.component.css']
})
export class LaunchingDialogeComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LaunchingDialogeComponent>,
  ) { }

  ngOnInit(): void {
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
