<div class="hero-image">
  <div class="container">
     <div class="row">
        <div class="col-md-6 top-margin-top">
           <div class="d-flex flex-column" style="margin-top: 50px !important">
              <p class="top-p mt-5">
                 <span class="head-text-black"
                    >Do You
                 <span
                    class="head-text-join"
                    >WANT TO JOIN US?</span
                    ></span
                    >
                 <span class="le" style="font-size: 30px">
                 Make your career  with</span
                    ><br />
                 <span
                    class="head-text-orange"
                    style="
                    font-size: 50px;
                    padding-bottom: 10px;
                    border-bottom: 6px solid #ff8500;
                    line-height: 48px;
                    "
                    >Roboti</span
                    >
                 <span class="head-text-orange" style="font-size: 50px"
                    >cs & AI</span
                    >
              </p>
              <!-- <div class="mt-5">
                 <button type="button" class="my-btn mt-5">Get Started</button>
              </div> -->
           </div>
        </div>
        <div class="col-md-6">
           <img
              src="../../../../../assets/images/career_page_asset/Group 224.png"
              alt="child"
              class="mtp-70 banerimg"
              style="margin-top: 85px !important"
              />
        </div>
     </div>
  </div>
</div>

<!-- Difference -->
<section class="mg-top  right-back-img">
  <div class="container text-center g-max-width-950">
     <div class="row">
        <div class="col-md-7 col-sm-7 col-lg-7 col-xl-7">
           <div class="row">
              <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6 text-center">
                 <div class="card">
                    <div class="card-body">
                       <img
                          alt="child"
                          src="../../../../../assets/images/career_page_asset/Vector Smart Object-4.png"
                          />
                       <div class="card-title mt-3">Fun at Work</div>
                       <p class="text-font-p" style="text-align: center;">
                          A friendly and fun environment to make employees less
                          stressed, more productive, more creative, and more engaged.
                       </p>
                    </div>
                 </div>
              </div>
              <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6 mt-4">
                 <div class="card">
                    <div class="card-body">
                       <img
                          alt="child"
                          src="../../../../../assets/images/career_page_asset/Vector Smart Object-3.png"
                          />
                       <div class="card-title mt-3">Great co-worker</div>
                       <p class="text-font-p" style="text-align: center;">
                          A team with passionate, professional members who believe in
                          teamwork with coordination, and cooperation, to accomplish the
                          company's goals.
                       </p>
                    </div>
                 </div>
              </div>
           </div>
           <div class="row mt-2">
              <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                 <div class="card">
                    <div class="card-body">
                       <img
                          alt="child"
                          src="../../../../../assets/images/career_page_asset/Vector Smart Object-2.png"
                          />
                       <div class="card-title mt-3">Education Opportunity</div>
                       <p class="text-font-p" style="text-align: center;">
                          What you can learn is more important than what you know. We
                          provide the opportunity to learn and grow with every phase of
                          work.
                       </p>
                    </div>
                 </div>
              </div>
              <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6 mt-4">
                 <div class="card">
                    <div class="card-body">
                       <img
                          alt="child"
                          src="../../../../../assets/images/career_page_asset/Vector Smart Object.png"
                          />
                       <div class="card-title mt-3">Performance Award</div>
                       <p class="text-font-p" style="text-align: center;">
                          We believe that hard work pays off when it is recognized. We
                          respect and appreciate the hard work and dedication of our
                          employees by awarding their performance.
                       </p>
                    </div>
                 </div>
              </div>
           </div>
        </div>
        <div class="col-md-5 col-sm-5 col-lg-5 col-xl-5 mt-5">
           <div align="left" class="mt-5" >
           <h2>
              <span style="color: #ff8500" class="lef"> Join </span>
              <span class="lef">Mechatron Robotics</span>
              <sup class="mb-2">&reg;</sup><br />
              <span class="lef"> & </span>
              <span style="color: #ff8500" class="lef"> Make</span>
              <span class="lef"> a </span>
              <span style="color: #ff8500" class="lef"> Difference</span>
           </h2>
         </div>
           <p class="text-font-p mt-5" style="line-height: 30px; font-size: 16px;font-weight: 600;">
              Mechatron Robotics Australia offers a wide variety of opportunities to build a
              career. We provide hands-on experience to work on projects related to
              different aspects to extend student's learning experience. We provide a
              friendly environment to learn and grow, encourage candidates to
              showcase their talent with their right skill set, and work with
              passionate team members.
           </p>
        </div>
     </div>
  </div>
</section>

<!-- Employees & Trainers -->
<!-- <section class="mg-top">
  <div class="container">
     <br /><br /><br /><br />
     <div class="row">
        <div class="col-md-12 col-sm-12 photo-card">
           <div class="row mt-5">
              <div class="col-md-1 col-sm-1"></div>
              <div class="col-md-8 col-sm-8">
                 <span class="mt-5">
                 <span style="color: #ff8500" class="lef"> Hear </span>
                 <span class="lef"> from our </span>
                 <span style="color: #ff8500" class="lef"> Employees </span> -->
                 <!-- <span class="lef"> & </span>
                 <span style="color: #ff8500" class="lef"> Trainers</span> -->
                 <!-- </span>
                 <p
                    class="text-font-p mt-3"
                    style="font-size: 15px; font-weight: 600"
                    >
                    "Technology provides that avenue to compensate in areas where
                    something might be more difﬁcult. <br />
                    It's really cool to be able to help to enable people."
                 </p>
                 <p class="trin">
                    <span>
                     <hr style="width:10%;text-align:left;margin-left:0"> <span>John Doe</span>
                    </span>

                 </p>
                 <span style="color: #2f5698">Senior Software Engineer</span>
                 <p class="mt-5">
                    <span>
                    <img
                       alt="child"
                       src="../../../../../assets/images/career_page_asset/Group 414.png"
                       class="w-90 firsttop roud-photo"
                       />
                    <img
                       alt="child"
                       src="../../../../../assets/images/career_page_asset/Group 418.png"
                       class="w-90 firsttop roud-photo"
                       />
                    <img
                       alt="child"
                       src="../../../../../assets/images/career_page_asset/Group 414.png"
                       class="w-90 firsttop roud-photo"
                       />
                    <img
                       alt="child"
                       src="../../../../../assets/images/career_page_asset/Group 414.png"
                       class="w-90 firsttop roud-photo"
                       />
                    <img
                       alt="child"
                       src="../../../../../assets/images/career_page_asset/Group 414.png"
                       class="w-90 firsttop roud-photo"
                       />
                    </span>
                    <span>
                    <i
                       class="fas fa-quote-right fa-4x"
                       style="float: right; font-size: 100px"
                       ></i>
                    </span>
                 </p>
              </div>
              <div class="col-md-3 col-sm-3">
                 <img
                    alt="child"
                    src="../../../../../assets/images/career_page_asset/Group 417.png"
                    class="w-90 firsttop"
                    />
              </div>
           </div>
        </div>
     </div>
  </div>
</section> -->

<!-- Apply Now -->
<div class="left-back-img mg-top">
  <section class="left-back-img">
     <div class="container g-max-width-950">
        <div class="text-center">
           <h2>
              <span style="color: #ff8500" class="lef"> We Are Hiring!! </span>
              <span class="lef"> Find The Right Job for You </span>
           </h2>
           <p class="text-font-p text-center" style="font-size: 18px">
              The best way to predict the future is to create it. <br />
              Create your future, grow with us. Build your career.
           </p>
        </div>
        <div class="card mt-5">
           <div class="card-body mt-3">
              <div class="card-title">
                 <div class="row">
                    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                       <span lass="lef" style="font-size: 20px">Robotics Engineer</span>
                    </div>
                    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                       <button class="btn btn-defualt bay-btn">
                       <i class="fa fa-undo" aria-hidden="true"></i> Posted 7 days ago
                       </button>
                    </div>
                 </div>
              </div>
              <div class="row">
                 <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                    <span class="trin-gray">3.6</span>
                    <span class="trin-gray"
                       ><i
                       class="fa fa-star"
                       style="color: #ff8500; margin-left: 5px"
                       aria-hidden="true"
                       ></i
                       ></span>
                    <span class="trin-gray" style="color: #2f5698; margin-left: 10px"
                       >(11700 Review)</span
                       >
                 </div>
                 <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                    <span class="trin-gray">
                    <i class="fa fa-map-marker" aria-hidden="true"></i
                       ></span>
                    <span class="trin-gray"> Australia</span>
                 </div>
              </div>
              <!-- <div class="row mt-3">
                 <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                    <span class="trin-gray"
                       ><i class="fa fa-calendar-check-o" aria-hidden="true"></i
                       ></span>
                    <span class="trin-gray">Application End : November 10, 2021</span>
                 </div>
                 <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                    <span class="trin-gray">
                    <i class="fa fa-usd" aria-hidden="true"></i
                       ></span>
                    <span class="trin-gray"> 1000/-2000 /month</span>
                 </div>
              </div> -->
              <div class="row mt-3">
                 <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                    <span class="trin-gray" style="color: #333333"
                       ><i class="fa fa-file-text-o" aria-hidden="true"></i
                       ></span>
                    <span class="trin-gray" style="color: #333333"
                       >Required Key Skills
                    </span>
                    <span class="trin-gray" style="color: #333333"
                       ><i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    </span>
                    <ol class="fa-ul">
                       <li>
                          <span class="fa-li"
                             ><i
                             class="fa fa-check-circle-o"
                             style="color: #333333"
                             aria-hidden="true"
                             ></i></span
                             >Should be Electronics Engineer with good communication skills.
                       </li>
                       <li>
                          <span class="fa-li"
                             ><i
                             class="fa fa-check-circle-o"
                             style="color: #333333"
                             aria-hidden="true"
                             ></i></span
                             >Engineers who are pursuing Btech / BE are also eligible.
                       </li>
                       <li>
                          <span class="fa-li"
                             ><i
                             class="fa fa-check-circle-o"
                             style="color: #333333"
                             aria-hidden="true"
                             ></i></span
                             >Experience of making some robots in college based on Arduino
                          platform.
                       </li>
                       <li>
                          <span class="fa-li"
                             ><i
                             class="fa fa-check-circle-o"
                             style="color: #333333"
                             aria-hidden="true"
                             ></i></span
                             >Must have good Programming skills
                       </li>
                       <li>
                          <span class="fa-li"
                             ><i
                             class="fa fa-check-circle-o"
                             style="color: #333333"
                             aria-hidden="true"
                             ></i></span
                             >Preference will be given to candidates who are living near. <br>
                          <!-- <span class="apply-now">APPLY NOW </span> -->
                       </li>
                    </ol>
                 </div>
              </div>
           </div>
           <div class="card-border"></div>
        </div>
        <div class="card mt-5">
           <div class="card-body mt-3">
              <div class="card-title">
                 <div class="row">
                    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                       <span lass="lef" style="font-size: 20px"> Sales and Marketing Partner</span>
                    </div>
                    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                       <button class="btn btn-defualt bay-btn">
                       <i class="fa fa-undo" aria-hidden="true"></i> Posted 7 days ago
                       </button>
                    </div>
                 </div>
              </div>
              <div class="row mt-4">
                 <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                    <span class="trin-gray">3.6</span>
                    <span class="trin-gray"
                       ><i
                       class="fa fa-star"
                       style="color: #ff8500; margin-left: 5px"
                       aria-hidden="true"
                       ></i
                       ></span>
                    <span class="trin-gray" style="color: #2f5698; margin-left: 10px"
                       >(11700 Review)</span
                       >
                 </div>
                 <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                    <span class="trin-gray">
                    <i class="fa fa-map-marker" aria-hidden="true"></i
                       ></span>
                    <span class="trin-gray"> Australia</span>
                 </div>
              </div>
              <!-- <div class="row mt-3">
                 <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                    <span class="trin-gray"
                       ><i class="fa fa-calendar-check-o" aria-hidden="true"></i
                       ></span>
                    <span class="trin-gray">Application End : November 10, 2021</span>
                 </div>
                 <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                    <span class="trin-gray">
                    <i class="fa fa-usd" aria-hidden="true"></i
                       ></span>
                    <span class="trin-gray"> 11,00,000 - 13,00,000 PA</span>
                 </div>
              </div> -->
              <div class="row mt-3">
                 <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                    <span class="trin-gray" style="color: #333333"
                       ><i class="fa fa-file-text-o" aria-hidden="true"></i
                       ></span>
                    <span class="trin-gray" style="color: #333333"
                       >Required Key Skills
                    </span>
                    <span class="trin-gray" style="color: #333333"
                       ><i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    </span>
                    <ol class="fa-ul">
                       <li>
                          <span class="fa-li"
                             ><i
                             class="fa fa-check-circle-o"
                             style="color: #333333"
                             aria-hidden="true"
                             ></i></span
                             >Must have a passion in sales and marketing to perform all the
                          required activities.
                       </li>
                       <li>
                          <span class="fa-li"
                             ><i
                             class="fa fa-check-circle-o"
                             style="color: #333333"
                             aria-hidden="true"
                             ></i></span
                             >1-2 years of sales & marketing experience.
                       </li>
                       <li>
                          <span class="fa-li"
                             ><i
                             class="fa fa-check-circle-o"
                             style="color: #333333"
                             aria-hidden="true"
                             ></i></span
                             >Fresher looking for internship can also apply.
                       </li>
                       <li>
                          <span class="fa-li"
                             ><i
                             class="fa fa-check-circle-o"
                             style="color: #333333"
                             aria-hidden="true"
                             ></i></span
                             >Must have a willingness to learn and put creative ideas into
                          work.
                       </li>
                       <li>
                          <span class="fa-li"
                             ><i
                             class="fa fa-check-circle-o"
                             style="color: #333333"
                             aria-hidden="true"
                             ></i></span
                             >Graduate with B.Tech& MBA from a reputed college shall be
                          preferred. <br> <br>
                          <!-- <span class="apply-now">APPLY NOW </span> -->
                       </li>
                    </ol>
                 </div>
              </div>
           </div>
           <div class="card-border"></div>
        </div>
        <div class="card mt-5">
           <div class="card-body mt-3">
              <div class="card-title">
                 <div class="row">
                    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                       <span lass="lef" style="font-size: 20px">Full-time Internship</span>
                    </div>
                    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                       <button class="btn btn-defualt bay-btn">
                       <i class="fa fa-undo" aria-hidden="true"></i> Posted 7 days ago
                       </button>
                    </div>
                 </div>
              </div>
              <div class="row mt-4">
                 <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                    <span class="trin-gray">3.6</span>
                    <span class="trin-gray"
                       ><i
                       class="fa fa-star"
                       style="color: #ff8500; margin-left: 5px"
                       aria-hidden="true"
                       ></i
                       ></span>
                    <span class="trin-gray" style="color: #2f5698; margin-left: 10px"
                       >(11700 Review)</span
                       >
                 </div>
                 <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                    <span class="trin-gray">
                    <i class="fa fa-map-marker" aria-hidden="true"></i
                       ></span>
                    <span class="trin-gray"> Australia</span>
                 </div>
              </div>
              <!-- <div class="row mt-3">
                 <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                    <span class="trin-gray"
                       ><i class="fa fa-calendar-check-o" aria-hidden="true"></i
                       ></span>
                    <span class="trin-gray">Application End : November 10, 2021</span>
                 </div>
                 <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                    <span class="trin-gray">
                    <i class="fa fa-usd" aria-hidden="true"></i
                       ></span>
                    <span class="trin-gray"> 1000/-2000 /month</span>
                 </div>
              </div> -->
              <div class="row mt-3">
                 <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12">
                    <span class="trin-gray" style="color: #333333"
                       ><i class="fa fa-file-text-o" aria-hidden="true"></i
                       ></span>
                    <span class="trin-gray" style="color: #333333"
                       >Required Key Skills
                    </span>
                    <span class="trin-gray" style="color: #333333"
                       ><i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    </span>
                    <ol class="fa-ul">
                       <li>
                          <span class="fa-li"
                             ><i
                             class="fa fa-check-circle-o"
                             style="color: #333333"
                             aria-hidden="true"
                             ></i></span
                             >Mechatron Robotics offers a wide variety of opportunities to
                          explore and grow. Our internships are designed with hands-on
                          experience to work on projects with team members from all
                          different departments, with the hope of helping young
                          engineers/MBAs build conﬁdence and trust in themselves as they
                          mature with their careers. We care about our clients at
                          Mechatron Robotics, and with an encouraging environment, we
                          strive to make sure that our applicants have fun while they
                          learn or maybe even get inspired. <br />
                          <!-- <span
                             class="apply-now"
                             >APPLY NOW
                          </span> -->
                       </li>
                    </ol>
                 </div>
              </div>
           </div>
           <div class="card-border"></div>
        </div>
     </div>
  </section>
</div>

<section class="mg-top">
  <div class="container g-max-width-950">
     <div align="center">
        <h2>
           <span class="lef"> Send us your </span>
           <span style="color: #ff8500" class="lef"> Resume</span>
        </h2>
        <p>
          Connect to great job opportunities by submitting your resume, <br> the ﬁrst step towards your career.
        </p>
     </div>
     <div class="row mt-5">
      <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12">
        <div class="wrapper">
          <div id="one">
            <div>
              <span style="color: #FFF" class="lef title-font"> Career & Professional <br> Development</span><br><br><br>
              <span class="trin mt-5" style="color: #FFF">australia@mechatronrobotics.com.au</span><br>
              <p class="mt-5" style="text-align: justify;color: #FFF">
                Mechatron Robotics is involved in the training and nurturing of young minds in the ﬁeld of Robotics. We are looking for passionate, dedicated engineer trainers with sound knowledge of Electronics and Robotics.
              </p>
              <p class="mt-5" style="text-align: justify;color: #FFF">
                We invite B.Tech /B.E (Electronics)/ Interns and freshers who have keen interest in robotics and have developed various robots during the engineering course to join us.              </p>
            </div>
          </div>
          <form #form="ngForm">
          <div id="two">
            <div class="row mt-3">
              <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                   <!-- <div class="form-group"> -->
                <label for="email">Name:  <span class="text-danger">*</span></label>
                <input type="text" [(ngModel)]="careers.name" class="form-control"   #enqName='ngModel'
                pattern="[A-Za-z\s]+" required id="name" placeholder="Enter Name" name="name">
                <div *ngIf="enqName.invalid" class="text-danger small">
                  <div *ngIf='enqName.touched || enqName.dirty || form?.submitted'>
                    <div *ngIf="enqName.errors?.required"> {{formValidationErrors.required}} </div>
                    <div *ngIf="enqName.errors?.pattern">
                      {{formValidationErrors.name}}
                    </div>
                  </div>
                <!-- </div> -->
              </div>
              </div>
              <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                <label for="email">Mobile No.:  <span class="text-danger">*</span></label>
                <input type="tel"  [(ngModel)]="careers.mobile" class="form-control" id="mobile" required placeholder="Enter Mobile Number" name="mobile"  #mobile='ngModel'>
                <div *ngIf="mobile.invalid" class="text-danger small">
                  <div *ngIf='mobile.touched || mobile.dirty || form.submitted'>
                    <div *ngIf="mobile.errors?.required"> {{formValidationErrors.required}} </div>
                    <!-- <div *ngIf="phone.errors.pattern"> {{formValidationErrors.mobile}} </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                <label for="email">Alternate Number:</label>
                <input type="tel"  [(ngModel)]="careers.alt_mobile" class="form-control" id="alt_mobile" placeholder="Enter Alternate Number" name="alt_mobile">
              </div>
              <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                <label for="email">Email: <span class="text-danger">*</span></label>
                <input type="email"  [(ngModel)]="careers.email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                required
                #enqEmail="ngModel" class="form-control" id="email" placeholder="Enter email" name="email">
                <div *ngIf="enqEmail.invalid" class="text-danger small">
                  <div *ngIf='enqEmail.touched || enqEmail.dirty || form.submitted'>
                    <div *ngIf="enqEmail.errors?.pattern"> {{formValidationErrors.email}} </div>
                    <div *ngIf="enqEmail.errors?.required"> {{formValidationErrors.required}} </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                <label for="email">Position: <span class="text-danger">*</span></label>
                <input type="text"  [(ngModel)]="careers.position" class="form-control" id="position" placeholder="Select Department" name="position"  #enqRoal="ngModel" required>
                <div *ngIf="enqRoal.invalid" class="text-danger small">
                  <div *ngIf='enqRoal.touched || enqRoal.dirty || form.submitted'>
                    <!-- <div *ngIf="enqRoal.errors?.pattern"> {{formValidationErrors.email}} </div> -->
                    <div *ngIf="enqRoal.errors?.required"> {{formValidationErrors.required}} </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                <label for="email"> Location: <span class="text-danger">*</span></label>
                <input type="text"  [(ngModel)]="careers.location" class="form-control" id="location" placeholder="Select Location" name="location" #location="ngModel" required>
                <div *ngIf="location.invalid" class="text-danger small">
                  <div *ngIf='location.touched || location.dirty || form.submitted'>
                    <!-- <div *ngIf="location.errors?.pattern"> {{formValidationErrors.email}} </div> -->
                    <div *ngIf="location.errors?.required"> {{formValidationErrors.required}} </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8 col-sm-6 col-lg-6 col-xl-6">
                <label for="email">Select Resume :  <span class="text-danger">*</span></label>
                <!-- <input type="file"  [(ngModel)]="careers.resume" class="form-control" name="resume" id="resume" placeholder="Select Department" > -->
                <input type="file" required #inputImage="ngModel" style="display: none;" accept="pdf/*"
                  (change)="onImageSelected($event)" name="inputImage" required [(ngModel)]="selectedImageName"
                  #selectImage  value={this.state.image} />
                <button class="add-more-quiz-btn ml-4" (click)="selectImage.click()">
                  Upload Pdf
                </button> <span class="text-danger">*</span>
                <div *ngIf="inputImage.invalid && (inputImage.touched || inputImage.dirty)">
                  <div *ngIf="inputImage.errors?.required">
                    <p> please select Resume </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-lg-6 col-xl-6">

              </div>
            </div>
            <div class="row mt-5">
              <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
                <button  class="send-now"  *ngIf="!onSubmitBtn" [disabled]="form.invalid" (click)="onSubmit()">SUBMIT</button>
                <button class="my-btn-don" type="button" disabled *ngIf="onSubmitBtn">
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  sending...
                </button>
              </div>
              <div class="col-md-6 col-sm-6 col-lg-6 col-xl-6">
              </div>
            </div>
          </div>
          </form>
        </div>
      </div>
     </div>
  </div>
</section>
<br><br><br><br>
