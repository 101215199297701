<div class="container">
  <div style="margin-top: 120px">
    <h3 class="main-head"><b>TERMS AND CONDITIONS</b></h3>
  </div>
  <ul>
    <li>
      <span class="sub-head">Introduction</span>
      <ul>
        <li class="p-text">
          This website <strong>(Site)</strong>  is operated by <strong>Mechatron Robotics Australia Pty
            Ltd</strong>  (ABN 99 654 594 064) ( <strong>we, our</strong>  or <strong>us</strong> ). These terms and conditions
           <strong>(Terms)</strong> are between us and <strong>you</strong> , the person placing an order for an
          online course or robotics kit, together referred to as products,
          through the Site.
        </li>
      </ul>
    </li>
    <li>
      <span class="sub-head mt-5">Use of the Site</span><br />
      <ul>
        <li class="p-text">
          You accept these Terms by placing an order via the Site.
        </li>
        <li class="p-text">
          If you are accepting these Terms as the legal guardian for your child,
          you represent and warrant that you are the child’s legal guardian, and
          can act on behalf of, and make decisions for, the child.
        </li>
        <li class="p-text">
          You must not use the Site and/or place an order for products through
          the Site unless you are at least 16 years old.
        </li>
        <li class="p-text">
          When using the Site, you must not do or attempt to do anything that is
          unlawful or inappropriate, including:
          <ul>
            <li>
              anything that would constitute a breach of an individual’s privacy
              (including uploading private or personal information without an
              individual's consent) or any other legal rights;
            </li>
            <li>
              using the Site to defame, harass, threaten, menace or offend any
              person;
            </li>
            <li>using the Site for unlawful purposes;</li>
            <li>interfering with any user of the Site;</li>
            <li>
              tampering with or modifying the Site (including by transmitting
              viruses and using trojan horses);
            </li>
            <li>using the Site to send unsolicited electronic messages;</li>
            <li>
              using data mining, robots, screen scraping or similar data
              gathering and extraction tools on the Site; or facilitating or
              assisting a third party to do any of the above acts.
            </li>
          </ul>
        </li>
      </ul>
    </li>
    <li>
      <span class="sub-head">Accounts</span>
      <ul>
        <li class="p-text">
          You may purchase products from us without an account, or you may
          choose to create an account with us which allows you to review your
          order history and course information and preferences.
        </li>
        <li class="p-text">
          You must ensure that any personal information you give to us when
          creating an account is accurate and up-to-date. All personal
          information that you give to us will be treated in accordance with our
          Privacy Policy.
        </li>
        <li class="p-text">
          It is your responsibility to keep your account details confidential.
          You are responsible for all activity on your account, including
          purchases made using your account details. We reserve the right to
          terminate your account for failure to comply with these Terms.
        </li>
      </ul>
    </li>
    <li>
      <span class="sub-head">Orders</span>
      <ul>
        <li class="p-text">
          You may order products from us as set out on the Site. If you place an
          order for products on the Site, you are making an order to purchase
          the product(s) for the price listed on the Site (including the
          delivery fees or other applicable charges and taxes).
        </li>
        <li class="p-text">
          We may, at our absolute discretion, accept or reject an order. If we
          need to reject your order, we will notify you within a reasonable time
          after your order is placed. Once we accept an order, a binding
          agreement is formed for the supply of products to you in accordance
          with these Terms.
        </li>
        <li class="p-text">
          It is your responsibility to check the order details, including
          selected products, delivery details and pricing, before you submit
          your order through the Site.
        </li>
        <li class="p-text">
          When you order and pay on the Site and your payment has been
          validated, we will provide you with an order confirmation email.
        </li>
        <li class="p-text">
          All purchases made through the Site are subject to availability. We do
          our best to keep products in stock and to keep the Site up to date
          with the availability of products.
        </li>
      </ul>
    </li>
    <li>
      <span class="sub-head">Price and payments</span>
      <ul>
        <li class="p-text">
          You must pay us the purchase price of each product you order, plus any
          applicable delivery costs as set out on the Site (the <strong>Price</strong> ) in
          accordance with this clause. All amounts are stated in Australian
          dollars and are inclusive of Australian GST (where applicable).
        </li>
        <li class="p-text">
          You must pay the Price upfront using one of the methods set out on the
          Site.
        </li>
        <li class="p-text">
          You must not pay, or attempt to pay, the Price by fraudulent or
          unlawful means. If you make a payment by debit card or credit card,
          you warrant that you are authorised to use the debit card or credit
          card to make the payment.
        </li>
        <li class="p-text">
          The payment methods we offer are set out on the Site. We may offer
          payment through a third-party provider for example Stripe or PayPal.
          You acknowledge and agree that we have no control over the actions of
          the third-party provider, and your use of the third-party payment
          method may be subject to additional terms and conditions.
        </li>
        <li class="p-text">
          We do not store any credit card details, and all payment information
          is collected and stored through our third-party payment processor.
        </li>
        <li class="p-text">
          Where you order the products for delivery outside Australia, you may
          need to pay custom charges or taxes in addition to the Price.
        </li>
        <li class="p-text">
          We may from time to time issue promotional discount codes for certain
          products on the Site.
        </li>
        <li class="p-text">
          To claim the discount, you must enter the promotional discount code at
          the time of submitting your order through the Site.
        </li>
        <li class="p-text">
          The conditions of use relating to promotional discount codes will be
          set out on the Site. We may also from time to time run competitions on
          the Site or on social media. These competitions are subject to terms
          and conditions which will be made available on the Site at the time of
          the competition.
        </li>
      </ul>
    </li>
    <li>
      <span class="sub-head">Online Courses</span>
      <ul>
        <li class="p-text">
          You must not disclose your account details to any third party or to
          any persons other than the individual who will take the course, where
          you are not the individual who will take the course (
           <strong>Participant</strong>).
        </li>
        <li class="p-text">
          You agree that you or the Participant will login to the course (using
          the details we provide to you) at the start of the course.
        </li>
        <li class="p-text">
          If you or the Participant are unable to attend a live online course,
          please contact us immediately and we may, at our discretion, enrol you
          in another course session (if available).
        </li>
        <li class="p-text">
          In the unlikely event that we are unable to deliver a live online
          course to you (for example: our instructor is sick and cannot deliver
          the scheduled course), we will arrange for you to join the next
          available live online course.
        </li>
        <li class="p-text">
          When interacting with live courses on the Site, you must be careful
          about the types of personal information that you share.
        </li>
        <li class="p-text">
          You or the Participant must have a suitable device (such as a tablet
          or computer) and a strong internet connection to watch the course on
          the Site.
        </li>
        <li class="p-text">
          To ensure the course is enjoyable for all participants and that every
          participant is able to learn in a safe, positive and friendly
          environment, you or the Participant must not:
          <ul>
            <li>bully other participants;</li>
            <li>
              disobey instructions (including any safety instructions); or
            </li>
            <li>make inappropriate gestures or comments during the course.</li>
          </ul>
        </li>
      </ul>
    </li>
    <li>
      <span class="sub-head"
        >Delivery, title and risk (only applicable to physical products such as
        robotics kits)
      </span>
      <ul>
        <li class="p-text">
          If possible we will deliver the products to the delivery address you
          provide when making your order.
        </li>
        <li class="p-text">
          Delivery costs are set out on the Site and are to be paid in addition
          to the Price.
        </li>
        <li class="p-text">
          We normally dispatch products within 48 hours of receiving an order,
          unless otherwise noted on the Site. Any delivery periods displayed on
          the Site are estimates only, based on the information provided by the
          delivery company.
        </li>
        <li class="p-text">
          If you need to change the delivery day or delivery address, please
          notify us immediately in writing.
        </li>
        <li class="p-text">
          We deliver the products using a range of delivery methods. If
          applicable, you will need to sign for deliveries of our products.
        </li>
        <li class="p-text">
          Title to the products will remain with us until we have delivered the
          products to your delivery address. Until title passes, you must not do
          anything which seeks to create an encumbrance, lien, charge or other
          interest in or over the products.
        </li>
        <li class="p-text">
          Risk in the products will pass to you as soon as they are sent to the
          delivery address you provided in your order.
        </li>
      </ul>
    </li>
    <li>
      <span class="sub-head">Intellectual property </span>
      <ul>
        <li class="p-text">
          All intellectual property (including copyright) developed, adapted,
          modified or created by us or our personnel (including in connection
          with the Terms, any content on the Site, and the products) ( <strong>Our
            Intellectual Property</strong> ) will at all times vest, or remain vested, in
          us.
        </li>
        <li class="p-text">
          We authorise you to use Our Intellectual Property solely for your own
          personal, non-commercial use, and in the manner in which it was
          intended to be used.
        </li>
        <li class="p-text">
          You must not, without our prior written consent:
          <ul>
            <li>
              copy, in whole or in part, any of Our Intellectual Property;
            </li>
            <li>
              reproduce, retransmit, distribute, disseminate, sell, publish,
              broadcast or circulate any of Our Intellectual Property to any
              third party; or
            </li>
            <li>
              breach any intellectual property rights connected with the Site or
              the products, including (without limitation) altering or modifying
              any of Our Intellectual Property; causing any of Our Intellectual
              Property to be framed or embedded in another website; or creating
              derivative works from any of Our Intellectual Property.
            </li>
          </ul>
        </li>
        <li class="p-text">
          Nothing in the above clause restricts your ability to publish, post or
          repost Our Intellectual Property on your social media page or blog,
          provided that:
          <ul>
            <li>
              you do not assert that you are the owner of Our Intellectual
              Property;
            </li>
            <li>
              unless explicitly agreed by us in writing, you do not assert that
              you are endorsed or approved by us;
            </li>
            <li>
              you do not damage or take advantage of our reputation, including
              in a manner that is illegal, unfair, misleading or deceptive; and
            </li>
            <li>you comply with all other terms of these Terms.</li>
          </ul>
        </li>
      </ul>
    </li>
    <li>
      <span class="sub-head">Content you upload</span>
      <ul>
        <li class="p-text">
          We encourage you to interact with the Site and with us on social
          media! You may be permitted to post, upload, publish, submit or
          transmit relevant information and content (
           <strong>User Content</strong> ) on the Site.
          We may run campaigns via the Site and via social media that encourage
          you to post User Content on social media using specific hashtags (#)
          (<strong>Tag</strong>).
        </li>
        <li class="p-text">
          If you make any User Content available on or through the Site,
          including on social media using a Tag, you grant to us a worldwide,
          irrevocable, perpetual, non-exclusive, transferable, royalty-free
          licence to use the User Content, with the right to use, view, copy,
          adapt, modify, distribute, license, transfer, communicate, publicly
          display, publicly perform, transmit, stream, broadcast, access, or
          otherwise exploit such User Content on, through or by means of the
          Site and our social media platforms.
        </li>
        <li class="p-text">
          You agree that you are solely responsible for all User Content that
          you make available on or through the Site, including on social media
          using a Tag. You represent and warrant that:
          <ul>
            <li>
              you are either the sole and exclusive owner of all User Content or
              you have all rights, licences, consents and releases that are
              necessary to grant to us the rights in such User Content (as
              contemplated by these Terms);
            </li>
            <li>
              neither the User Content nor the posting, uploading, publication,
              submission or transmission of the User Content or our use of the
              User Content on, through or by means of our Site (including on
              social media) will infringe, misappropriate or violate a third
              party’s intellectual property rights, or rights of publicity or
              privacy, or result in the violation of any applicable law or
              regulation.
            </li>
          </ul>
        </li>
        <li class="p-text">
          We do not endorse or approve, and are not responsible for, any User
          Content. We may, at any time (at our sole discretion), remove any User
          Content.
        </li>
      </ul>
    </li>
    <li>
      <span class="sub-head">Australian Consumer Law </span>
      <ul>
        <li class="p-text">
          Nothing in these Terms attempts to modify or exclude the conditions,
          warranties and undertakings, and other legal rights, under the
          Australian Consumer Law. Any and all other warranties or conditions
          which are not guaranteed by the Australian Consumer Law are expressly
          excluded where permitted, except to the extent such warranties and
          conditions are fully expressed in these Terms.
        </li>
        <li class="p-text">
          Where you return products to us to seek an Australian Consumer Law
          remedy, you will need to cover any associated costs (for example
          delivery costs) of you returning the products to us.
        </li>
        <li class="p-text">
          Where your claim is a valid claim under the Australian Consumer Law,
          we will refund your return delivery costs and, depending on the
          failure, either dispatch a new or repaired replacement product or
          refund you the Price of the relevant product. Please contact us for
          further information.
        </li>
      </ul>
    </li>
    <li>
      <span class="sub-head"
        >Returns (only applicable to physical products such as robotics kits)
      </span>
      <ul>
        <li class="p-text">
          We offer exchanges (subject to stock availability) for change of mind
          where we determine (at our absolute discretion):
          <ul>
            <li>
              you have provided the proof of purchase and you purchased the
              products within 7 days of the request for an exchange or refund;
            </li>
            <li>
              the products are in their original condition and have not been
              used, damaged, tampered with, altered, connected, installed or
              attempted to be connected or installed
            </li>
            <li>
              the products are in their original undamaged packaging with all
              product tags still intact;
            </li>
            <li>
              the products are not sale items, custom-made, special buy products
              or gift vouchers.
            </li>
          </ul>
        </li>
        <li class="p-text">
          We will not cover the costs of delivery or re-delivery for a change of
          mind return. You must cover these yourself.
        </li>
        <li class="p-text">
          You may have rights under the Australian Consumer Law (see below) in
          addition to this clause.
        </li>
      </ul>
    </li>
    <li>
      <span class="sub-head">Limitations </span>
      <ul>
        <li class="p-text">
          You may have rights under the Australian Consumer Law (see above), and
          nothing in this Limitations clause attempts to modify or exclude those
          rights.
        </li>
        <li class="p-text">
          Despite anything to the contrary, to the maximum extent permitted by
          law:
          <ul>
            <li>
              our maximum aggregate liability arising from or in connection with
              the Terms (including the products and/or the subject matter of the
              Terms) will be limited to, and must not exceed, the portion of the
              Price paid by you to us for the products the subject of the
              relevant claim;
            </li>
            <li>
              we will not be liable to you for any loss of profit (including
              anticipated profit), loss of benefit (including anticipated
              benefit), loss of revenue, loss of business, loss of goodwill,
              loss of opportunity, loss of savings (including anticipated
              savings), loss of reputation, loss of use and/or loss or
              corruption of data,
            </li>
          </ul>
        </li>
        <li class="p-text">
          whether under statute, contract, equity, tort (including negligence),
          indemnity or otherwise.
        </li>
        <li class="p-text">
          Despite anything to the contrary, to the maximum extent permitted by
          law, we will not be liable for, and you waive and release us from and
          against any liability caused or contributed to by, arising from or
          connected with any event or circumstance which is beyond our
          reasonable control including but not limited to, acts of God including
          fire, hurricane, typhoon, earthquake, landslide, tsunami, mudslide or
          other catastrophic natural disaster, civil riot, civil rebellion,
          revolution, terrorism, insurrection, militarily usurped power, act of
          sabotage, act of a public enemy, war (whether declared or not) or
          other like hostilities, ionising radiation, contamination by
          radioactivity, nuclear, chemical or biological contamination, any
          widespread illness, quarantine or government sanctioned ordinance or
          shutdown, pandemic (including COVID-19 and any variations or mutations
          to this disease or illness) or epidemic.
        </li>
      </ul>
    </li>
    <li>
      <span class="sub-head">Limitations </span>
      <ul>
        <li class="p-text">
          <strong>Disputes:</strong> Neither party may commence court proceedings relating to any
          dispute arising from, or in connection with, these Terms without first
          meeting with the other party to seek (in good faith) to resolve that
          dispute (unless that party is seeking urgent interlocutory relief or
          the dispute relates to compliance with this clause).
        </li>
        <li class="p-text">
          <strong>Notices:</strong> Any notice given under these Terms must be in writing
          addressed to us at the details set out below or to you at the details
          provided when you submitted your order or in your account. Any notice
          may be sent by standard post or email, and will be deemed to have been
          served on the expiry of 48 hours in the case of post, or at the time
          of transmission in the case of transmission by email.
        </li>
        <li class="p-text">
          <strong>Feedback and complaints:</strong> We are always looking to improve our
          services. If you have any feedback or a complaint, please notify us on
          our contact details below and we will take reasonable steps to address
          any concerns you have.
        </li>
        <li class="p-text">
          <strong>Assignment:</strong> You must not assign any rights or obligations under these
          Terms, whether in whole or in part, without our prior written consent.
        </li>
        <li class="p-text">
          <strong>Entire agreement: </strong>The Terms contain the entire understanding and
          agreement between you and us in respect of their subject matter.
        </li>
        <li class="p-text">
          <strong>Amendment: </strong>We may, at any time and at our discretion, vary these Terms
          by publishing varied terms on the Site. Prior to placing an order, we
          recommend you carefully read the terms that are in effect at that time
          to ensure you understand and agree to them. For any order that has
          been accepted by us, the terms and conditions that apply will be the
          ones that were in effect (and which you agreed to) when you placed
          your order.
        </li>
        <li class="p-text">
          <strong>Governing law:</strong> These Terms are governed by the laws of Victoria. Each
          Party irrevocably and unconditionally submits to the exclusive
          jurisdiction of the courts operating in Victoria and any courts
          entitled to hear appeals from those courts and waives any right to
          object to proceedings being brought in those courts. The Site may be
          accessed in Australia and overseas. We make no representation that the
          Site complies with the laws (including intellectual property laws) of
          any country outside of Australia. If you access the Site from outside
          Australia, you do so at your own risk and are responsible for
          complying with the laws in the place you access the Site.
        </li>
        <li class="p-text">
          <strong>Third party sites: </strong>The Site may contain links to websites operated by
          third parties. Unless we tell you otherwise, we do not control,
          endorse or approve, and are not responsible for, the content on those
          websites. We recommend that you make your own investigations with
          respect to the suitability of those websites. If you purchase goods or
          services from a third party website linked from the Site, such third
          party provides the goods and services to you, not us. We may receive a
          benefit (which may include a referral fee or a commission) should you
          visit certain third-party websites via a link on the Site (Affiliate
          Link) or for featuring certain products or services on the Site. We
          will make it clear by notice to you which (if any) products or
          services we receive a benefit to feature on the Site, or which (if
          any) third party links are Affiliate Links.
        </li>
      </ul>
    </li>
  </ul>
  <br>
  <p> <strong>For any questions and notices, please contact us at:</strong></p>
  <p class="sub-head">
    Mechatron Robotics Australia Pty Ltd (ABN 99 654 594 064)
  </p>
  <p>
    <strong>Address :</strong>191 Balaclava Road, Caulfield North, Victoria 3161,
    Australia
  </p>
  <p><strong>Phone Number : </strong>03 9524 3115</p>
  <p><strong>Email : </strong>australia@mechatronrobotics.com.au</p>
  <p><strong>© LegalVision ILP Pty Ltd</strong></p>
</div>
