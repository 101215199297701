<div class="hero-image">
  <div class="container">
    <div class="row">
      <div class="col-md-6 top-margin-top">
        <div class="d-flex flex-column" style="margin-top: 50px !important">
          <p class="top-p mt-5">
            <span class="head-text-black">Looking to upgrade</span>
            <span style="margin-bottom: 10px">
              <span class="le"> your</span><br />
              <span class="head-text-orange">School</span>
            </span>
            <span style="margin-bottom: 10px">
              <span class="le"> with</span>
              <span class="head-text-black"> technology <br />courses</span>
              <span class="le"> like</span>
              <span class="head-text-orange"> Robotics & AI?</span>
            </span>
          </p>

          <div>
            <div>
              <button
                type="button"
                class="my-btn mt-5"
                (click)="scroll(get_start)"
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <img
          src="../../../../../assets/images/school_page_images/Group 414.png"
          alt="child"
          class="mtp-63 banerimg"
          style="width: 90%; margin-top: 85px !important"
        />
      </div>
    </div>
  </div>
</div>
<br /><br /><br />
<!-- About us -->
<section class="mg-top first-top right-back-img">
  <div class="container text-center g-max-width-950">
    <div class="row">
      <div class="col-md-2 col-sm-2 col-lg-2 col-xl-2">
        <img
          alt="child"
          src="../../../../../assets/images/school_page_images/comma-left.PNG"
          class="left-comma"
        />
        <!-- <i class="fas fa-quote-left fa-4x left-comma" s></i> -->
      </div>
      <div class="col-md-8 col-sm-8 col-lg-8 col-xl-8" align="center">
        <h3>
          <span style="color: #ff8500; font-weight: 800; font-size: 15px">
            About Us</span
          >
        </h3>
        <h2>
          <span class="lef">Mechatron Robotics</span
          ><sup class="mb-2">&reg;</sup>
        </h2>
        <p class="text-font-p text-center">
          Mechatron Robotics is a next generation EdTech company of Australia.
          Our prime focus is to prepare students for Industry 4.0 with quality
          programs for schools that aim to transform the traditional classroom
          into a dynamic one by imparting STEM education with practical training
          on technology topics such as robotics, artificial intelligence, coding
          and IOT and make them problem solvers and innovators of the future.
        </p>
      </div>
      <div class="col-md-2 col-sm-2 col-lg-2 col-xl-2">
        <img
          alt="child"
          src="../../../../../assets/images/school_page_images/comma-right.PNG"
          class="rigth-comma"
        />
      </div>
    </div>
  </div>
</section>

<!-- Our program -->
<div class="left-back-img" #get_start>
  <section class="left-back-img">
    <div class="container text-center g-max-width-950">
      <br /><br /><br /><br />
      <h2>
        <span class="lef"> Our</span>
        <span style="color: #ff8500" class="lef"> Programs</span>
        <span class="lef"> for</span>
        <span style="color: #ff8500" class="lef"> Schools</span>
      </h2>
      <span class="text-cetner">
        We are building the most effective and innovative programs with
        technology.
        <br />
        We offer a wide range of programs for schools.
      </span>
      <div class="row mt-5">
        <div class="col-md-4">
          <div class="course-card">
            <div class="card-body">
              <div class="container-photo">
                <img
                  class="card-img-top team-img"
                  src="../../../../../assets/images/school_page_images/Group 96 copy-5.png"
                  width="640"
                  alt="Card image cap"
                  style="border-radius: 5px"
                />
              </div>
              <div class="cardcolor mt-4">
                <div class="card-title text-center">Courses for students</div>
              </div>
              <div class="cardcolor mt-4">
                <p class="text-font-card">
                  Our courses are designed in a level-based approach from
                  Beginner to Intermediate with depth of content and
                  personalised training to acquire the right skills set.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="course-card">
            <div class="card-body">
              <div class="container-photo">
                <img
                  class="card-img-top team-img"
                  src="../../../../../assets/images/school_page_images/Group 96 copy-2.png"
                  width="640"
                  alt="Card image cap"
                  style="border-radius: 5px"
                />
              </div>
              <div class="cardcolor mt-4">
                <div class="card-title text-center">
                  Trainer skill development
                </div>
              </div>
              <div class="cardcolor mt-4">
                <p class="text-font-card">
                  In this program, trainers are given proper training to conduct
                  classes. Training focuses on technical skills as well as
                  development of other skills, including communication,
                  confidence, imagination and innovation to help students in
                  their understanding of technologies.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="course-card">
            <div class="card-body">
              <div class="container-photo">
                <img
                  class="card-img-top team-img"
                  src="../../../../../assets/images/school_page_images/Group 96 copy-3.png"
                  width="640"
                  alt="Card image cap"
                  style="border-radius: 5px"
                />
              </div>
              <div class="cardcolor mt-2">
                <div class="card-title text-center">
                  Competitions
                </div>
              </div>
              <div class="cardcolor mt-4">
                <p class="text-font-card">
                  Competitions mainly focus on Skill Development, Multi-Tasking,
                  Teamwork, Technical exposure and Problem Solving. The tracks
                  are primarily designed to evaluate a Team on the basis of
                  their Team-Work and Problem-solving capabilities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-2"></div>
        <div class="col-md-4">
          <div class="course-card">
            <div class="card-body">
              <div class="container-photo">
                <img
                  class="card-img-top team-img"
                  src="../../../../../assets/images/school_page_images/Group 96 copy-2.png"
                  width="640"
                  alt="Card image cap"
                  style="border-radius: 5px"
                />
              </div>
              <div class="cardcolor mt-4">
                <div class="card-title text-center">
                  Online workshop
                </div>
              </div>
              <div class="cardcolor mt-4">
                <p class="text-font-card">
                  We conduct workshops to provide opportunities for students to
                  grow with technology. Our workshops are on various topics such
                  as Arduino, robotics, AI and ML. Students develop technical
                  skills required for the competitive atmosphere such as
                  automation and programming languages to test robotics.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="course-card">
            <div class="card-body">
              <div class="container-photo">
                <img
                  class="card-img-top team-img"
                  src="../../../../../assets/images/school_page_images/Group 96 copy.png"
                  width="640"
                  alt="Card image cap"
                  style="border-radius: 5px"
                />
              </div>
              <div class="cardcolor mt-4">
                <div class="card-title text-center">
                  Robotics Lab
                </div>
              </div>
              <div class="cardcolor mt-4">
                <p class="text-font-card">
                  We support schools and colleges by establishing Robotics Lab
                  (Atal Tinkering Labs) based on the guidelines. We help schools
                  by providing them the required training, tools and material to
                  operationalize the complete set up.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
  </section>
</div>

<section class="mg-top first-top">
  <div class="images pbtm">
    <div class="container g-max-width-950">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3 col-sm-3 mtp-80">
            <span>
              <img
                class="img-fluid w-17 w-171"
                src="../../../../../assets/images/training.png"
                alt="First slide"
              />
            </span>
            <span class="log-24"> 960,000+</span>

            <p class="trin">
              <span>Training Hrs.</span><br /><span>since 2013</span>
            </p>
          </div>
          <div class="col-md-3 col-sm-3 mtp-80">
            <img
              class="img-fluid w-15 lss"
              src="../../../../../assets/images/course.png"
              alt="First slide"
            /><span class="log-24 lt"> 10+</span>

            <p class="trin">
              <span>Levels/Course with</span> <br />
              <span>each course of 48 Hrs.</span>
            </p>
          </div>
          <div class="col-md-3 col-sm-3 mtp-80">
            <img
              class="img-fluid w-17 ls"
              src="../../../../../assets/images/location.png"
              alt="First slide"
            /><span class="log-24 lt"> 15+</span>

            <p class="trin">
              <span>Globally</span
              >
            </p>
          </div>
          <div class="col-md-3 col-sm-3 mtp-80">
            <img
              class="img-fluid w-10 lti"
              src="../../../../../assets/images/project.png"
              alt="First slide"
            /><span class="log-24 lt"> 500+</span>

            <p class="trin">
              <span>International Projects</span><br /><span
                >published by Students</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="left-back-img mg-top">
  <section class="left-back-img">
    <div class="container g-max-width-950">
      <br /><br /><br /><br />
      <div class="row mt-5">
        <div class="col-md-6 col-sm-6 mt-5">
          <img
            alt="child"
            src="../../../../../assets/images/school_page_images/Group 8.png"
            class="w-80 firsttop mt-2"
            width="90%"
          />
        </div>
        <div class="col-md-6 col-sm-6">
          <div align="left">
            <h2>
              <span class="lef"> Benefits </span>
              <span class="le"> of the</span>
              <span style="color: #ff8500" class="lef"> Program</span>
            </h2>
          </div>
          <p class="mt-4">
            <span>
              <button
                mat-mini-fab
                style="color: tomato; background-color: #fff"
              >
                <mat-icon>done</mat-icon>
              </button>
            </span>
            <span style="margin-left: 10px">
              Experienced and Qualified trainers with interactive and innovative
              <br />
            </span>
            <span style="margin-left: 55px"> approaches. </span>
          </p>
          <p class="mt-4">
            <span>
              <button
                mat-mini-fab
                style="color: tomato; background-color: #fff"
              >
                <mat-icon>done</mat-icon>
              </button>
            </span>
            <span style="margin-left: 10px">
              Curriculum for different age groups from primary through to
              secondary<br />
            </span>
            <span style="margin-left: 55px"> school. </span>
          </p>

          <p class="mt-4">
            <span>
              <button
                mat-mini-fab
                style="color: tomato; background-color: #fff"
              >
                <mat-icon>done</mat-icon>
              </button>
            </span>
            <span style="margin-left: 10px">
              Young Kids work with electronic circuits, learn designing and much
              more. <br />
            </span>
          </p>
          <p class="mt-4">
            <span>
              <button
                mat-mini-fab
                style="color: tomato; background-color: #fff"
              >
                <mat-icon>done</mat-icon>
              </button>
            </span>
            <span style="margin-left: 10px">
              All the levels involve conceptual and practical aspects with a
              holistic <br />
            </span>
            <span style="margin-left: 55px"> approach. </span>
          </p>
          <p class="mt-4">
            <span>
              <button
                mat-mini-fab
                style="color: tomato; background-color: #fff"
              >
                <mat-icon>done</mat-icon>
              </button>
            </span>
            <span style="margin-left: 10px">
              Trainers support beyond the class-room to reinforce concepts.
            </span>
          </p>
          <p class="mt-4">
            <span>
              <button
                mat-mini-fab
                style="color: tomato; background-color: #fff"
              >
                <mat-icon>done</mat-icon>
              </button>
            </span>
            <span style="margin-left: 10px">
              Kids build their own projects and overcome challenges with
              technical and
            </span>
            <span style="margin-left: 55px"> non-technical skills. </span>
          </p>
          <p class="mt-4">
            <span>
              <button
                mat-mini-fab
                style="color: tomato; background-color: #fff"
              >
                <mat-icon>done</mat-icon>
              </button>
            </span>
            <span style="margin-left: 10px">
              Various quiz competitions are conducted throughout the duration of
              courses.
            </span>
          </p>
        </div>
      </div>
    </div>
  </section>
</div>

<div class="mg-top">
  <section class="">
    <div class="container g-max-width-950">
      <br /><br /><br /><br />
      <div align="center">
        <h2>
          <span class="lef"> What </span>
          <span style="color: #ff8500" class="lef"> Value</span>
          <span class="lef"> do we bring for </span>
          <span style="color: #ff8500" class="lef"> Schools?</span>
        </h2>
      </div>
      <div class="row mt-5">
        <div class="col-md-3 col-sm-3" align="center">
          <img
            alt="child"
            src="../../../../../assets/images/school_page_images/Group 422.png"
            class="w-70 firsttop"
          />
          <p class="value-card-tite mt-3">Technical Expertise for students</p>
          <p class="text-font-p" style="text-align: center">
            Being a technology driven company with expertise in AI, Machine
            Learning, Robotics, IOT & many Coding Languages, we provide a very
            strong Australian aligned curriculum for students with 10+ Levels of
            Training (Basic to Advanced).
          </p>
        </div>
        <div class="col-md-1 col-sm-1" align="center">
          <img
            alt="child"
            src="../../../../../assets/images/school_page_images/Group 430.png"
            class="join-img"
          />
        </div>
        <div class="col-md-3 col-sm-3" align="center">
          <img
            alt="child"
            src="../../../../../assets/images/school_page_images/Group 423-2.png"
            class="w-70 firsttop"
          />
          <p class="value-card-tite mt-3">Experience</p>
          <p class="text-font-p" style="text-align: center">
            We have been in this market since 2018 and have spent nearly
            960,000+ Hrs. of Training with 50,000+ students across 12+ Levels &
            have published more than 500+ projects in international communities.
          </p>
        </div>
        <div class="col-md-1 col-sm-1" align="center">
          <img
            alt="child"
            src="../../../../../assets/images/school_page_images/Group 431.png"
            class="join-img"
          />
        </div>
        <div class="col-md-3 col-sm-3" align="center">
          <img
            alt="child"
            src="../../../../../assets/images/school_page_images/Group 424-2.png"
            class="w-70 firsttop"
          />
          <p class="value-card-tite mt-3">
            Software / Learning Management System
          </p>
          <p class="text-font-p" style="text-align: center">
            We provide a complete tool to manage your day-to-day activities like
            Creating Classes, Mark Attendance, Registering Students, Generating
            Certificates and many more.
          </p>
        </div>
        <div class="col-md-1 col-sm-1" align="center"></div>
      </div>
      <div class="row mt-5">
        <div class="col-md-2 col-sm-2" align="center"></div>
        <div class="col-md-3 col-sm-3" align="center">
          <img
            alt="child"
            src="../../../../../assets/images/school_page_images/Group 423.png"
            class="w-70 firsttop"
          />
          <p class="value-card-tite mt-3">
            Equal focus on non-technical skills
          </p>
          <p class="text-font-p" style="text-align: center">
            Mechatron Robotics Australia not only focuses on technical skills
            but also adds great value in upgrading some of the important
            non-technical skills like Communication, Creativity, Team-Work,
            Perseverance and Problem Solving.
          </p>
        </div>
        <div class="col-md-1 col-sm-1" align="center">
          <img
            alt="child"
            src="../../../../../assets/images/school_page_images/Group 431.png"
            class="join-img"
          />
        </div>
        <div class="col-md-3 col-sm-3" align="center">
          <img
            alt="child"
            src="../../../../../assets/images/school_page_images/Group 422.png"
            class="w-70 firsttop"
          />
          <p class="value-card-tite mt-3">Collaboration & Teamwork</p>
          <p class="text-font-p" style="text-align: center">
            We work with a tech community of students, other partners and
            trainers where we learn from each other, share ideas, listen to
            stories, build team projects, do presentations, play quizzes & do
            many more fun activities.
          </p>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- Register center -->
<!-- <div
  class="
    margin_top
    container-fluid
    images
    d-flex
    justify-center
    align-items-center
  "
>
  <div class="container mt-5 mb-5">
    <div class="col-md-12 text-center p-3">
      <div align="center">
        <h2>
          <span style="color: #ff8500" class="lef"> Register </span>
          <span class="lef"> for trainers </span>
          <span style="color: #ff8500" class="lef"> Development </span>
          <span class="lef"> Program </span>
        </h2>
      </div>
      <p class="value-card-tite">Level up in the field of technology</p>
      <a
        type="button"
        class="my-btn"
        href="https://mechatronrobotics.com/book-trial-class"
        target="blank"
      >
        ENQUIRE NOW
      </a>
    </div>
  </div>
</div> -->

<!-- Proragm Skill -->
<section class="mg-top first-top">
  <div class="container text-center g-max-width-950">
    <div class="row">
      <div class="col-md-2 col-sm-2 col-lg-2 col-xl-2">
        <img
          alt="child"
          src="../../../../../assets/images/school_page_images/comma-left.PNG"
          class="left-comma"
        />
      </div>
      <div class="col-md-8 col-sm-8 col-lg-8 col-xl-8" align="center">
        <div align="center">
          <h2>
            <span class="lef"> Trainers</span>
            <span style="color: #ff8500" class="lef"> Skills Development </span>
            <span class="lef"> Program </span>
          </h2>
        </div>
        <p class="value-card-tite" style="font-size: 15px">
          Get trained from our experts, upskill for your future, and get
          recognized
        </p>
        <p class="text-font-p text-center">
          In this program, trainers are given training to conduct classes with
          technical and professional development training. Trainer Skills
          Development also considers other required skills, including
          Communication, Confidence, Imagination and Innovation to help students
          in their understanding of technologies.
        </p>
      </div>
      <div class="col-md-2 col-sm-2 col-lg-2 col-xl-2">
        <img
          alt="child"
          src="../../../../../assets/images/school_page_images/comma-right.PNG"
          class="rigth-comma"
        />
      </div>
    </div>
  </div>
</section>

<!-- Our program -->
<div class="right-back-img">
  <section class="right-back-img">
    <div class="container g-max-width-950">
      <br /><br /><br /><br />
      <div class="row mt-5">
        <div class="col-md-6">
          <div align="left">
            <h2>
              <span class="lef"> Why is it </span>
              <span style="color: #ff8500" class="lef"> Important</span>
              <span class="lef"> for</span><br />
              <span style="color: #ff8500" class="lef"> Trainers</span>
              <span class="lef"> to upgrade</span>
              <span style="color: #ff8500" class="lef"> Skills?</span>
            </h2>
          </div>
          <p class="mt-4">
            <span>
              <button
                mat-mini-fab
                style="color: tomato; background-color: #fff"
              >
                <mat-icon>done</mat-icon>
              </button>
            </span>
            <span style="margin-left: 10px">
              To provide a high quality of education and handle questions posed
              <br />
            </span>
            <span style="margin-left: 55px">
              by students with confidence.
            </span>
          </p>
          <p class="mt-4">
            <span>
              <button
                mat-mini-fab
                style="color: tomato; background-color: #fff"
              >
                <mat-icon>done</mat-icon>
              </button>
            </span>
            <span style="margin-left: 10px">
              Trainer development improves skills and self-development. As a
              result,
              <br />
            </span>
            <span style="margin-left: 55px">
              students will be more confident and their learning will flourish.
            </span>
          </p>
          <p class="mt-4">
            <span>
              <button
                mat-mini-fab
                style="color: tomato; background-color: #fff"
              >
                <mat-icon>done</mat-icon>
              </button>
            </span>
            <span style="margin-left: 10px">
              Skilled trainers are more confident and the wellbeing of their
              students is <br />
            </span>
            <span style="margin-left: 55px"> improved. </span>
          </p>
        </div>
        <div class="col-md-6" align="centre">
          <img
            alt="child"
            src="../../../../../assets/images/school_page_images/Group 9.png"
            class="w-80 firsttop"
            width="75%"
          />
        </div>
      </div>
    </div>
  </section>
</div>

<!-- help -->
<div
  class="
    margin_top
    container-fluid
    images
    d-flex
    justify-center
    align-items-center
  "
>
  <div class="container mt-5 mb-5">
    <div class="col-md-12 text-center p-3">
      <div align="center">
        <span style="color: #ff8500; font-weight: 800; font-size: 15px">
          Join Today</span
        >
        <h2>
          <span class="lef"> Talk to us about how we can help you </span>
        </h2>
      </div>
      <a routerLink="/contactus" type="button" class="my-btn">
        GET STARTED NOW
      </a>
    </div>
  </div>
</div>

<!-- FAQ -->
<div class="left-back-img">
  <div class="margin_top container" style="margin-bottom: 80px">
    <h2 class="blackText seaction-heading">
      <span class="boldText">Frequently</span> &nbsp;asked&nbsp;<span
        class="orangeText"
        >Questions</span
      >
    </h2>

    <div class="row">
      <div class="col-md-12">
        <div class="faq">
          <div
            class="panel-group"
            id="accordion"
            role="tablist"
            aria-multiselectable="true"
          >
            <div class="panel">
              <div class="panel-heading" role="tab" id="headingOne">
                <h3 class="panel-title">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse1st"
                    aria-expanded="false"
                    aria-controls="collapse1st"
                    ><span
                      >What skills do the students develop at Mechatron
                      robotics?</span
                    >
                  </a>
                </h3>
              </div>
              <div
                id="collapse1st"
                class="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingOne"
              >
                <div class="panel-body">
                  <p>
                    Students learn various technical skills like Coding,
                    Electronics, Mechanics, Mechanical design, Arduino, IOT and
                    many more practical courses. Apart from the technical skills
                    students also develop various non-technical skills like
                    creativity, innovation, communication skills, logical
                    thinking, problem solving skills & teamwork.
                  </p>
                </div>
              </div>
            </div>
            <div class="panel">
              <div class="panel-heading" role="tab" id="headingTwo">
                <h3 class="panel-title">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse2nd"
                    aria-expanded="false"
                    aria-controls="collapse2nd"
                    >How long does it take to complete a courses/levels?</a
                  >
                </h3>
              </div>
              <div
                id="collapse2nd"
                class="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingTwo"
              >
                <div class="panel-body">
                  <p>
                    Most of our courses are developed keeping in mind the
                    students develop a complete understanding of the topic. So,
                    each of our courses/levels is nearly 48 hrs. long which
                    includes an extensive number of projects, assignments,
                    quizzes & project presentations.
                  </p>
                </div>
              </div>
            </div>
            <div class="panel">
              <div class="panel-heading" role="tab" id="headingThree">
                <h3 class="panel-title">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse3rd"
                    aria-expanded="false"
                    aria-controls="collapse3rd"
                    >What are the benefits of learning Robotics at an early age?
                  </a>
                </h3>
              </div>
              <div
                id="collapse3rd"
                class="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingThree"
              >
                <div class="panel-body">
                  <ul>
                    <li>
                      Numerous Research suggests Robotics as one of the best and
                      practical ways of STEM Education. Students really love it.
                    </li>
                    <li>
                      Project based education increases the logical thinking of
                      kids.
                    </li>
                    <li>
                      Projects also increases the Problem-solving abilities of
                      kids.
                    </li>
                    <li>One of the best ways of increasing IQ levels.</li>
                    <li>
                      Induces creativity as kids are motivated to use their own
                      imagination to build projects.
                    </li>
                    <li>
                      Helps develops the confidence of a child as they
                      participate in various Robotics competitions.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="panel">
              <div class="panel-heading" role="tab" id="headingfour">
                <h3 class="panel-title">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse4st"
                    aria-expanded="false"
                    aria-controls="collapse4st"
                    >How different Mechatron robotics is and why you should be
                    part of it?
                  </a>
                </h3>
              </div>
              <div
                id="collapse4st"
                class="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingfour"
              >
                <div class="panel-body">
                  <p>
                    At Mechatron, we not only develop the students in technical
                    skills but also with various non-technical skills. Our
                    trainers are mainly from electronics background and provide
                    really great knowledge compared to any other company. Our
                    courses are also designed in-depth for 48 hours and are
                    affordable for students.
                  </p>
                </div>
              </div>
            </div>
            <div class="panel">
              <div class="panel-heading" role="tab" id="headingfive">
                <h3 class="panel-title">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse5nd"
                    aria-expanded="false"
                    aria-controls="collapse5nd"
                    >How can I track my child’s progress?</a
                  >
                </h3>
              </div>
              <div
                id="collapse5nd"
                class="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingfive"
              >
                <div class="panel-body">
                  <p>
                    Our trainers share the progress of the child regularly with
                    the parents. We also provide a detailed evaluation on
                    various skills like creativity, efforts taken to build extra
                    projects, team work, presentation and communication skills,
                    perseverance, problem solving skills and logical thinking at
                    the end of the course. We also take periodic quizzes, give
                    assignments to assess a child's understanding of the
                    subject.
                  </p>
                </div>
              </div>
            </div>
            <div class="panel">
              <div class="panel-heading" role="tab" id="headingsix">
                <h3 class="panel-title">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse6rd"
                    aria-expanded="false"
                    aria-controls="collapse6rd"
                  >
                    Do you provide certificate after completion of courses?
                  </a>
                </h3>
              </div>
              <div
                id="collapse6rd"
                class="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingsix"
              >
                <div class="panel-body">
                  <p>
                    Yes, we do provide certificates after the successful
                    completion of the course. The student needs to give a final
                    exam and a project presentation in order to earn his/her
                    certificate.
                  </p>
                </div>
              </div>
            </div>
            <div class="panel">
              <div class="panel-heading" role="tab" id="headingseven">
                <h3 class="panel-title">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse7th"
                    aria-expanded="false"
                    aria-controls="collapse7th"
                  >
                    Do the students need to be technically sound in order to
                    join online/offline courses?
                  </a>
                </h3>
              </div>
              <div
                id="collapse7th"
                class="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headinseven"
              >
                <div class="panel-body">
                  <p>
                    No, since we provide the courses from fundamental level and
                    advanced level, any student can enrol as per his/her
                    interest. The most important criteria is they should have an
                    interest towards technology and learning the range of skills
                    that Mechatron Robotics Australia can teach.
                  </p>
                </div>
              </div>
            </div>
            <div class="panel">
              <div class="panel-heading" role="tab" id="headingeigt">
                <h3 class="panel-title">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse8th"
                    aria-expanded="false"
                    aria-controls="collapse8th"
                  >
                    Do you think the course increases the out-of-school learning
                    opportunities?
                  </a>
                </h3>
              </div>
              <div
                id="collapse8th"
                class="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingeight"
              >
                <div class="panel-body">
                  <p>
                    Yes, our courses increase the out of school learning
                    opportunities as the school courses align with the
                    Australian curriculum whereas our courses are more practical
                    and framed in a way that relates to day-to-day activities
                    and the practical world. This makes students more
                    enthusiastic towards the learning process and as they can
                    apply the knowledge and build something innovative.
                  </p>
                </div>
              </div>
            </div>
            <div class="panel">
              <div class="panel-heading" role="tab" id="headingnine">
                <h3 class="panel-title">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse9th"
                    aria-expanded="false"
                    aria-controls="collapse9th"
                  >
                    Do you provide kits along with the course?
                  </a>
                </h3>
              </div>
              <div
                id="collapse9th"
                class="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingnine"
              >
                <div class="panel-body">
                  <p>
                    Kits are not required for any of the online courses. Even
                    for the Offline / Classroom based courses the training
                    centre / Lab has all the Kits and equipment for the students
                    to learn. However students can buy the kits by paying extra
                    as per the kit price.
                  </p>
                </div>
              </div>
            </div>
            <div class="panel">
              <div class="panel-heading" role="tab" id="headingten">
                <h3 class="panel-title">
                  <a
                    class="collapsed"
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse10th"
                    aria-expanded="false"
                    aria-controls="collapse10th"
                  >
                    Are the trainers easily accessible for the students who need
                    extra help?
                  </a>
                </h3>
              </div>
              <div
                id="collapse10th"
                class="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="headingten"
              >
                <div class="panel-body">
                  <p>
                    Yes. Our trainers help students achieve. They are passionate
                    about educating the next, upcoming generation, and are
                    empowered to work with kids on an individual basis. Our
                    trainers help students to solve various tasks and
                    challenges, according to the requirements of the task they
                    have set for the kids. Globally, Mechatron Robotics students
                    have achieved many national and international level
                    championships with the help of their trainers, who are also
                    their mentors.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-md-12">
          <div class="faq">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel">
                <div class="panel-heading" role="tab" id="headingfour">
                  <h3 class="panel-title">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse4st"
                      aria-expanded="false" aria-controls="collapse4st">How different Mechatron robotics is and why you should be
                     <span style="margin-left: 33px;"> part of it?</span>
                    </a>
                  </h3>
                </div>
                <div id="collapse4st" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingfour">
                  <div class="panel-body">
                    <p>At Mechatron we not only develop the students in technical things but also various non-technical skills. Our Trainers are mainly from electronics background and provide really great knowledge compared to any other company. Our Courses are also designed in-depth for 48 hours and affordable for students. Read above the 30+ reasons how we are different from any other similar company in the world.</p>

                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-heading" role="tab" id="headingfive">
                  <h3 class="panel-title">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse5nd"
                      aria-expanded="false" aria-controls="collapse5nd">How can I track my child’s progress?</a>
                  </h3>
                </div>
                <div id="collapse5nd" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingfive">
                  <div class="panel-body">
                    <p>Our trainers share the progress of the child regularly with the parents. We also provide a detailed evaluation on various skills like creativity, efforts taken to build extra projects, team work, presentation and communication skills, perseverance, problem solving skills and logical thinking at the end of the course. We also take periodic quizzes, give assignments to assess a child's understanding of the subject.</p>

                  </div>
                </div>
              </div>
              <div class="panel ">
                <div class="panel-heading" role="tab" id="headingsix">
                  <h3 class="panel-title">
                    <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse6rd"
                      aria-expanded="false" aria-controls="collapse6rd">
                      Do you provide certificate, after completion of courses?
                      </a>
                  </h3>
                </div>
                <div id="collapse6rd" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingsix">
                  <div class="panel-body">
                    <p>Yes, we do provide certificates after the successful completion of the course. The student needs to give a final exam and a presentation on Graduation Day in order to earn his certificate.</p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>  -->
    </div>
  </div>
</div>
