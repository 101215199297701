<div class="hero-image">
  <div class="container">
     <div class="row">
        <div class="col-md-6 top-margin-top">
           <div class="d-flex flex-column mt-5">
              <p class="landing-top-text font-familyheading">
                 <span class="boldText">Are You</span> <span class="orangeText ml-2">PASSIONATE</span><br>
                 <span class="boldText">To Start Your Own Business In</span><br>
                 <span class="orangeText"><span class="bottmb ">Robotics</span> & AI?</span>
              </p>
              <h1 class="banner-small-text-second mt-5">
                 Best franchise business in Australia for education
              </h1>
              <div class="mt-5">
                 <a href="https://b24-e32qj7.bitrix24.site/mechatronrobotics.franchisee.form/" target="_blank">
                 <button type="button" class="my-btn" >
                 Enquire Now
                 </button></a>
              </div>
           </div>
        </div>
        <div class="col-md-6 mt-5 ">
           <img src="../../../../../assets/images/franchise/Group 218.png" alt="child" class="mtp-63 banerimg ">
        </div>
     </div>
  </div>
</div>

<section class="first-top mg-top">
  <div class="container text-center g-max-width-950">
     <div class="col-md-12">
        <div class="row">
           <div class="col-md-6 col-sm-6 mt-5">
              <img alt="child" src="../../../../../assets/images/franchise/Group 8.png" class="w-70 firsttop">
           </div>
           <div class="col-md-6 col-sm-6">
              <h2 class="leftside" style="margin-bottom: 15px;"> <span class="le">Interested in</span><span
                 style="color:#ff8500 ;" class="lef">
                 Starting<br>The Right</span> <span class="lef"> Business ?</span>
              </h2>
              <div class="mtop-32"> <img alt="child" src="../../../../../assets/images/aboutus_page_images/Group 436.png" class="tick"></div>
              <div class="left mg-45">
                 <p class="text-font-p">Are you a person driven by Passion, Creativity & Technology?</p>
              </div>
              <div class="mtop-32"><img alt="child" src="../../../../../assets/images/aboutus_page_images/Group 436.png" class="tick"></div>
              <div class="left mg-45">
                 <p class="text-font-p">Do you wish to start a business where the growth opportunities are High?</p>
              </div>
              <div class="mtop-32"><img alt="child" src="../../../../../assets/images/aboutus_page_images/Group 436.png" class="tick"></div>
              <div class="left mg-45">
                 <p class="text-font-p">Are you looking for an experienced partner, who knows the market and can support you well with their Technical & Marketing skills?</p>
              </div>
              <div class="mtop-32"><img alt="child" src="../../../../../assets/images/aboutus_page_images/Group 436.png" class="tick"></div>
              <div class="left mg-45">
                 <p class="text-font-p">Do you already have an existing educational business and want to give it a competitive advantage?</p>
              </div>
              <p class="leftside" style="font-weight: 600;  color: #333333;">If any of your answers are yes, you have landed at the right place!</p>
           </div>
        </div>
     </div>
  </div>
</section>

<section class="mg-top first-top">
  <div class="container text-center g-max-width-950">
     <div class="col-md-12">
        <div class="row">
           <div class="col-md-6 col-sm-6">
              <p class="leftside sec" style="font-family: sans-serif !important; color:#ff8500">Growing Demand In Robotics </p>
              <h2 class=" leftside" style="margin-bottom: 17px;"> <span class="lef"> 5 Reasons</span> <span class="le">
                 Why</span> <br> <span style="color:#ff8500 ;" class="lef">It's The Best Time</span> <span class="le"> to
                 Start<br> A </span><span class="lef">Business</span><span style="color:#ff8500 ;" class="lef"> in Robotics
                 & AI</span>
              </h2>
              <div class="mtop-32"> <img alt="child" src="../../../../../assets/images/aboutus_page_images/Group 436.png" class="tick"></div>
              <div class="left mg-45">
                 <p class="text-font-p">EdTech is one of the fastest-growing sectors in the world.</p>
              </div>
              <div class="mtop-32"> <img alt="child" src="../../../../../assets/images/aboutus_page_images/Group 436.png" class="tick"></div>
              <div class="left mg-45">
                 <p class="text-font-p" >80-90 % of jobs in the next few years will be related to Robotics, AI & ML.</p>
              </div>
              <div class="mtop-32"><img alt="child" src="../../../../../assets/images/aboutus_page_images/Group 436.png" class="tick"> </div>
              <div class="left mg-45">
                 <p class="text-font-p">Increasing demand for such platforms not only to school/college students but professionals as well.</p>
              </div>
              <div class="mtop-32"><img alt="child" src="../../../../../assets/images/aboutus_page_images/Group 436.png" class="tick"> </div>
              <div class="left mg-45">
                 <p class="text-font-p">EdTech is a highly profitable business with the least investment.</p>
              </div>
              <div class="mtop-32"><img alt="child" src="../../../../../assets/images/aboutus_page_images/Group 436.png" class="tick"></div>
              <div class="left mg-45">
                 <p class="text-font-p">It has a huge market opportunity with millions of students every year interested to learn technology courses. (Currently, less than 1% of total demand is being addressed).</p>
              </div>
           </div>
           <div class="col-md-6 col-sm-6">
              <img alt="child" src="../../../../../assets/images/franchise/amchart.png" class="w-100 mg-40">
           </div>
        </div>
     </div>
  </div>
</section>

<section class="mg-top first-top">
  <div class="container text-center g-max-width-950">
     <div class="col-md-12">
        <div class="row">
           <div class="col-md-6 col-sm-6">
              <img alt="child" src="../../../../../assets/images/franchise/third_sec.png" class="w-85 mg-25">
           </div>
           <div class="col-md-6 col-sm-6">
              <p class="leftside sec" style="font-family: sans-serif !important;">Collaborate with Mechatron </p>
              <h2 class="leftside" style="margin-bottom: 17px;"><span class="lef">3 Reasons</span> <span class="le">
                 Why</span> <br><span style="color:#ff8500 ;" class="lef">Entrepreneurs </span> <span class="le">
                 Choose</span> <br> <span style="color:#ff8500 ;" class="lef">Mechatron Robotics</span>
              </h2>
              <div> <img alt="child" src="../../../../../assets/images/franchise/tick.png" class="tick"></div>
              <div class="left mg-45">
                 <p  class="text-font-p">Robotics and other course modules are available for all age groups - Kids, School students, Engineering students, and Industrial professionals.</p>
              </div>
              <div class="mtop-32"> <img alt="child" src="../../../../../assets/images/franchise/tick.png" class="tick"></div>
              <div class="left mg-45">
                 <p  class="text-font-p">Mechatron Robotics Australia has a highly qualified technical team to support the business partners.</p>
              </div>
              <div class="mtop-32"> <img alt="child" src="../../../../../assets/images/franchise/tick.png" class="tick"></div>
              <div class="left mg-45">
                 <p  class="text-font-p">Support to partners for lead generation, branding, promotion, and building marketing strategy.
                 </p>
              </div>
           </div>
        </div>
     </div>
  </div>
</section>

<section class="mg-top">
  <div class="images pbtm">
     <div class="container g-max-width-950">
        <div class="col-md-12">
           <div class="row">
              <div class="col-md-3 col-sm-3 mtp-80">
                 <span>
                 <img
                    class="img-fluid w-17 w-171"
                    src="../../../../../assets/images/training.png"
                    alt="First slide"
                    />
                 </span>
                 <span class="log-24"> 960,000+</span>
                 <p class="trin">
                    <span>Training Hrs.</span><br /><span>since 2018</span>
                 </p>
              </div>
              <div class="col-md-3 col-sm-3 mtp-80">
                 <img
                    class="img-fluid w-15 lss"
                    src="../../../../../assets/images/course.png"
                    alt="First slide"
                    /><span class="log-24 lt"> 10+</span>
                 <p class="trin">
                    <span>Levels/Course with</span> <br />
                    <span>each course of 48 Hrs.</span>
                 </p>
              </div>
              <div class="col-md-3 col-sm-3 mtp-80">
                 <img
                    class="img-fluid w-17 ls"
                    src="../../../../../assets/images/location.png"
                    alt="First slide"
                    /><span class="log-24 lt"> 15+</span>
                 <p class="trin">
                    <span>Globally</span
                       >
                 </p>
              </div>
              <div class="col-md-3 col-sm-3 mtp-80">
                 <img
                    class="img-fluid w-10 lti"
                    src="../../../../../assets/images/project.png"
                    alt="First slide"
                    /><span class="log-24 lt"> 500+</span>
                 <p class="trin">
                    <span>International Projects</span><br /><span
                       >published by Students</span
                       >
                 </p>
              </div>
           </div>
        </div>
     </div>
  </div>
</section>
<!--
<section class="mg-top first-top">
  <div class="container ">
     <h2 style="margin-left: 47px;"><span class="lef">Testimonials</span><span class="le"> of</span> <span
        style="color:#ff8500;" class="lef"> Our Franchisee</span></h2>
     <br>
     <div class="col-md-12 test">
        <div class="row">
           <div id="carouselExampleControls-one" class="carousel slide courl" data-ride="carousel">
              <ol class="carousel-indicators">
                 <li data-target="#carouselExampleControls-one" data-slide-to="0" class="active"></li>
                 <li data-target="#carouselExampleControls-one" data-slide-to="1"></li>
                 <li data-target="#carouselExampleControls-one" data-slide-to="2"></li>
                 <li data-target="#carouselExampleControls-one" data-slide-to="3"></li>
                 <li data-target="#carouselExampleControls-one" data-slide-to="4"></li>
                 <li data-target="#carouselExampleControls-one" data-slide-to="5"></li>
              </ol>
              <div class=" carousel-inner divh" role="listbox">
                 <div class="container item2">
                    <div class="carousel-item active">
                       <div class="bg"></div>
                       <div class="row rowlf">
                          <div class="item ">
                             <div class="row">
                                <div class="col-md-6 col-sm-6 ">
                                   <div class="mb-3 ">
                                      <div class="row no-gutters ">
                                         <blockquote class="newslider">
                                            <div class="row">
                                               <div class="col-7">
                                                  <h3 class="leftside slif ft"
                                                     style="color: #333333;font-weight: 600; font-size: 20px;">Angshuman Das: (Delhi)
                                                  </h3>
                                               </div>
                                               <div class="col-5 textright">
                                                  <span class="strclr"><i class="fa fa-star"></i></span><span class="strclr"><i
                                                     class="fa fa-star"></i></span><span class="strclr"><i
                                                     class="fa fa-star"></i></span><span class="strclr"><i
                                                     class="fa fa-star"></i></span><span class="strclr"><i
                                                     class="fa fa-star"></i></span>
                                               </div>
                                            </div>
                                            <p class="leftside f-14">
                                               " I have had a great experience with Mechatron Robotics so far. The policies are not
                                               very rigid and pretty flexible as per market and client requirement. I think I had made
                                               a right decision to be part of Mechatron Robotics Family."<br>
                                            </p>
                                            <br>
                                            <span class="ft-42"><i class="fa fa-quote-left" aria-hidden="true"></i></span> <br>
                                         </blockquote>
                                         <div style="margin-left: 80%;">
                                            <div class="quote-footer  ">
                                               <div class="quote-author-img">
                                                  <img src="../../../../../assets/images/franchise/anghshuman.png" alt="anghshuman.png">
                                               </div>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                                <div class="col-md-6 col-sm-6 ne">
                                   <div id="accordion" class="accordion accw">
                                      <div class="card">
                                         <div class="card-header collapsed" data-toggle="collapse" href="#collapseOne">
                                            <a class="card-title">
                                            Tell Us Something About Yourself.
                                            </a>
                                         </div>
                                         <div id="collapseOne" class="card-body collapse" data-parent="#accordion">
                                            <p> I am a family man I live with my wife and seven-year-old son. I enjoy sports and
                                               exploring new
                                               places with my family.
                                            </p>
                                         </div>
                                      </div>
                                      <br>
                                      <div class="card">
                                         <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                                            href="#collapseTwo">
                                            <a class="card-title">
                                            What Made You Choose Mechatron Robotics?
                                            </a>
                                         </div>
                                         <div id="collapseTwo" class="card-body collapse" data-parent="#accordion">
                                            <p>The course curriculum offered by Mechatron Robotics is also very unique having core
                                               structure of
                                               Robotics i.e. Mechanics, Electronics, Arduino and Programming which is very much
                                               relevant to current
                                               and future education system. The overall Franchisee cost offered by Mechatron Robotics
                                               is also very
                                               competitive and there is no hidden cost.
                                            </p>
                                         </div>
                                      </div>
                                      <br>
                                      <div class="card">
                                         <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                                            href="#collapseThree">
                                            <a class="card-title mrf-12">
                                            How has Your experience been with Mechatron Robotics?
                                            </a>
                                         </div>
                                         <div id="collapseThree" class="collapse" data-parent="#accordion">
                                            <div class="card-body">
                                               <p> I have had a great experience with Mechatron Robotics so far. The
                                                  policies are
                                                  not very rigid and pretty flexible as per market and client requirement. I think I had
                                                  made a right
                                                  decision to be part of Mechatron Robotics Family.
                                               </p>
                                            </div>
                                         </div>
                                      </div>
                                      <br>
                                      <div class="card">
                                         <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                                            href="#collapsefour">
                                            <a class="card-title mrf-13">
                                            What is your vision with respect to Mechatron Robotics?
                                            </a>
                                         </div>
                                         <div id="collapsefour" class="collapse" data-parent="#accordion">
                                            <div class="card-body">
                                               <p>
                                                  In coming years, we want to be one of the best Robotics institute in India. I also
                                                  want
                                                  to get
                                                  associated with few schools to conduct workshop or regular classes and thus spreading
                                                  the awareness
                                                  about the company and brand value.
                                               </p>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                    <div class="carousel-item  ">
                       <div class="bg"></div>
                       <div class="row rowlf">
                          <div class="item ">
                             <div class="row">
                                <div class="col-md-6 col-sm-6">
                                   <div class="mb-3 ">
                                      <div class="row no-gutters ">
                                         <blockquote class="newslider">
                                            <div class="row">
                                               <div class="col-7">
                                                  <h3 class="leftside slif ft"
                                                     style="color: #333333;font-weight: 600; font-size: 20px;">Seema Gupta: (Gurugram)
                                                  </h3>
                                               </div>
                                               <div class="col-5 textright">
                                                  <span class="strclr"><i class="fa fa-star"></i></span><span class="strclr"><i
                                                     class="fa fa-star"></i></span><span class="strclr"><i
                                                     class="fa fa-star"></i></span><span class="strclr"><i
                                                     class="fa fa-star"></i></span><span class="strwhite"><i
                                                     class="fa fa-star"></i></span>
                                               </div>
                                            </div>
                                            <p class="leftside f-14">
                                               " I have always believed that STEM Education & Robotics is one of the great ways of
                                               creating interest among younger generation to learn and experiment. Mechatron Robotics
                                               does exactly that. I am really happy with their support for last 3 years now.
                                               Parents have now recognised us as a trusted training institute for their kids."
                                            </p>
                                            <span class="ft-42"><i class="fa fa-quote-left" aria-hidden="true"></i></span> <br>
                                         </blockquote>
                                         <div style="margin-left: 80%;">
                                            <div class="quote-footer  ">
                                               <div class="quote-author-img">
                                                  <img src="../../../../../assets/images/franchise/seema.png" alt="seema.png">
                                               </div>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                                <div class="col-md-6 col-sm-6 ne">
                                   <div id="accordion" class="accordion accw">
                                      <div class="card">
                                         <div class="card-header collapsed" data-toggle="collapse" href="#collapseFive">
                                            <a class="card-title">
                                            Tell Us Something About Yourself.
                                            </a>
                                         </div>
                                         <div id="collapseFive" class="card-body collapse" data-parent="#accordion">
                                            <p> I did my BSc in electronics and had been a housewife before I started the franchisee
                                               of Mechatron Robotics.
                                               I love electronics & I am very passionate for anything related to creativity.
                                            </p>
                                         </div>
                                      </div>
                                      <br>
                                      <div class="card">
                                         <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                                            href="#collapseSix">
                                            <a class="card-title">
                                            What Made You Choose Mechatron Robotics?
                                            </a>
                                         </div>
                                         <div id="collapseSix" class="card-body collapse" data-parent="#accordion">
                                            <p> I personally believe that to understand anything the best way is to "learn by doing".
                                               I decided to choose Mechatron Robotics because their vision is same as mine. Their
                                               courses are really good they have really transformed the kids into technical genius.
                                            </p>
                                         </div>
                                      </div>
                                      <br>
                                      <div class="card">
                                         <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                                            href="#collapseSeven">
                                            <a class="card-title mrf-13">
                                            How has Your experience been with Mechatron Robotics?
                                            </a>
                                         </div>
                                         <div id="collapseSeven" class="collapse" data-parent="#accordion">
                                            <div class="card-body">
                                               <p> My experience with Mechatron has been really great for last few years.
                                                  We have a collaborative platform to discuss new ideas.
                                                  They have been very encouraging at all times and open to ideas with answers to
                                                  small/foolish
                                                  questions. The overall environment is extremely supportive.
                                               </p>
                                            </div>
                                         </div>
                                      </div>
                                      <br>
                                      <div class="card">
                                         <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                                            href="#collapseEight">
                                            <a class="card-title mrf-13">
                                            What is your vision with respect to Mechatron Robotics?
                                            </a>
                                         </div>
                                         <div id="collapseEight" class="collapse" data-parent="#accordion">
                                            <div class="card-body">
                                               <p> My short-term goal is to make my center one of the best in its domain and that’s
                                                  what I want to focus on. I wish to provide a creative environment with structured
                                                  content to students
                                                  by opening more training centers in Gurgaon by partnering with schools and like-minded
                                                  people.
                                               </p>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                    <div class="carousel-item  ">
                       <div class="bg"></div>
                       <div class="row rowlf">
                          <div class="item ">
                             <div class="row">
                                <div class="col-md-6 col-sm-6">
                                   <div class="mb-3 ">
                                      <div class="row no-gutters ">
                                         <blockquote class="newslider">
                                            <div class="row">
                                               <div class="col-7">
                                                  <h3 class="leftside slif ft"
                                                     style="color: #333333;font-weight: 600; font-size: 20px;">Salome Chopde: (Pune)</h3>
                                               </div>
                                               <div class="col-5 textright">
                                                  <span class="strclr"><i class="fa fa-star"></i></span><span class="strclr"><i
                                                     class="fa fa-star"></i></span><span class="strclr"><i
                                                     class="fa fa-star"></i></span><span class="strclr"><i
                                                     class="fa fa-star"></i></span><span class="strwhite"><i
                                                     class="fa fa-star"></i></span>
                                               </div>
                                            </div>
                                            <p class="leftside f-14">
                                               " The core value to imbibe robotics & electronics skillset in children & the reputation
                                               of
                                               Mechatron Robotics is what made us associate with them in the first place."
                                            </p>
                                            <br> <br>
                                            <span class="ft-42"><i class="fa fa-quote-left" aria-hidden="true"></i></span> <br>
                                         </blockquote>
                                         <div style="margin-left: 80%;">
                                            <div class="quote-footer  ">
                                               <div class="quote-author-img">
                                                  <img src="../../../../../assets/images/franchise/chopde.png" alt="chopde.png">
                                               </div>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                                <div class="col-md-6 col-sm-6 ne">
                                   <div id="accordion" class="accordion accw">
                                      <div class="card">
                                         <div class="card-header collapsed" data-toggle="collapse" href="#collapseNine">
                                            <a class="card-title">
                                            Tell Us Something About Yourself.
                                            </a>
                                         </div>
                                         <div id="collapseNine" class="card-body collapse" data-parent="#accordion">
                                            <p> I am person with varied interests. I have done MA in English, being associated with
                                               banking sector to name a few – Citi Bank, HDFC & Yes Bank, with 8 years of experience. I
                                               live with my family at Pune, Maharashtra. My hobbies include painting, arts & crafts,
                                               artistically creating wealth out of waste and passionate about cooking exclusive
                                               cuisines and exploring new places through travelling with kids.
                                            </p>
                                         </div>
                                      </div>
                                      <br>
                                      <div class="card">
                                         <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                                            href="#collapseTen">
                                            <a class="card-title">
                                            What Made You Choose Mechatron Robotics?
                                            </a>
                                         </div>
                                         <div id="collapseTen" class="card-body collapse" data-parent="#accordion">
                                            <p> I aspire to spread the awareness around the importance of Robotics and benefits out of
                                               it to all school students in my region so that it will help them in their future studies
                                               and career path. There are multiple reasons I decided to go with them but primarily
                                               their reputation and core value to imbibe robotics skillset in children is what drew me
                                               towards it.
                                            </p>
                                         </div>
                                      </div>
                                      <br>
                                      <div class="card">
                                         <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                                            href="#collapseEleven">
                                            <a class="card-title mrf-13">
                                            How has Your experience been with Mechatron Robotics?
                                            </a>
                                         </div>
                                         <div id="collapseEleven" class="collapse" data-parent="#accordion">
                                            <div class="card-body">
                                               <p> Yes, we have made the right decision and the experience has been positive to work
                                                  with Mechatron team. We get all the supports and input which makes the working
                                                  environment great.
                                               </p>
                                            </div>
                                         </div>
                                      </div>
                                      <br>
                                      <div class="card">
                                         <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                                            href="#collapsetwelve">
                                            <a class="card-title mrf-13">
                                            What is your vision with respect to Mechatron Robotics?
                                            </a>
                                         </div>
                                         <div id="collapsetwelve" class="collapse" data-parent="#accordion">
                                            <div class="card-body">
                                               <p>
                                                  I wish to sustain this partnership with Mechatron Robotics and share knowledge on
                                                  robotics and artificial intelligence with young children & encourage them to take
                                                  interest in these topics.
                                               </p>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                    <div class="carousel-item  ">
                       <div class="bg"></div>
                       <div class="row rowlf">
                          <div class="item ">
                             <div class="row">
                                <div class="col-md-6 col-sm-6">
                                   <div class="mb-3 ">
                                      <div class="row no-gutters ">
                                         <blockquote class="newslider">
                                            <div class="row">
                                               <div class="col-7">
                                                  <h3 class="leftside slif ft"
                                                     style="color: #333333;font-weight: 600; font-size: 20px;">Shalini Bhushan:
                                                     (Dehradun)
                                                  </h3>
                                               </div>
                                               <div class="col-5 textright">
                                                  <span class="strclr"><i class="fa fa-star"></i></span><span class="strclr"><i
                                                     class="fa fa-star"></i></span><span class="strclr"><i
                                                     class="fa fa-star"></i></span><span class="strclr"><i
                                                     class="fa fa-star"></i></span><span class="strwhite"><i
                                                     class="fa fa-star"></i></span>
                                               </div>
                                            </div>
                                            <p class="leftside f-14">
                                               " I hope to establish Mechatron Robotics as the leading education provider & holistic
                                               development tool for children in Dehradun. The entire team has been extremely supportive
                                               and transparent right from the beginning."
                                            </p>
                                            <br>
                                            <span class="ft-42"><i class="fa fa-quote-left" aria-hidden="true"></i></span> <br>
                                         </blockquote>
                                         <div style="margin-left: 80%;">
                                            <div class="quote-footer  ">
                                               <div class="quote-author-img">
                                                  <img src="../../../../../assets/images/franchise/shalini.png" alt="shalini.png">
                                               </div>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                                <div class="col-md-6 col-sm-6 ne">
                                   <div id="accordion" class="accordion accw">
                                      <div class="card">
                                         <div class="card-header collapsed" data-toggle="collapse" href="#collapseFives">
                                            <a class="card-title">
                                            Tell Us Something About Yourself.
                                            </a>
                                         </div>
                                         <div id="collapseFives" class="card-body collapse" data-parent="#accordion">
                                            <p> I am a mother, a homemaker & an entrepreneur! A back-end corporate executive, who quit
                                               role post marriage and brought up the new family. An avid chatter & fun loving person
                                               who can sit at home doing nothing or go out and face the world single handedly.
                                            </p>
                                         </div>
                                      </div>
                                      <br>
                                      <div class="card">
                                         <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                                            href="#collapseSixs">
                                            <a class="card-title">
                                            What Made You Choose Mechatron Robotics?
                                            </a>
                                         </div>
                                         <div id="collapseSixs" class="card-body collapse" data-parent="#accordion">
                                            <p> They have very good response time and respond to our queries very quickly which is
                                               very important. Their content is very good, it covers all the important areas like
                                               coding, electronics, app development, mechanical design, python etc. They are
                                               transparent from the beginning and have no hidden costs or terms.
                                            </p>
                                         </div>
                                      </div>
                                      <br>
                                      <div class="card">
                                         <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                                            href="#collapseSevens">
                                            <a class="card-title mrf-13">
                                            How has Your experience been with Mechatron Robotics?
                                            </a>
                                         </div>
                                         <div id="collapseSevens" class="collapse" data-parent="#accordion">
                                            <div class="card-body">
                                               <p> The experience has been good till now. They have come across as someone who are
                                                  confident of who they are and what they do supplementing our thought and energies.
                                               </p>
                                            </div>
                                         </div>
                                      </div>
                                      <br>
                                      <div class="card">
                                         <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                                            href="#collapseEightt">
                                            <a class="card-title mrf-13">
                                            What is your vision with respect to Mechatron Robotics?
                                            </a>
                                         </div>
                                         <div id="collapseEightt" class="collapse" data-parent="#accordion">
                                            <div class="card-body">
                                               <p> I hope to establish Mechatron Robotics as the leading education provider & holistic
                                                  development tool for children in our region i.e Dehradun. We hope to benefit a larger
                                                  section of the society with these courses.
                                               </p>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                    <div class="carousel-item  ">
                       <div class="bg"></div>
                       <div class="row rowlf">
                          <div class="item ">
                             <div class="row">
                                <div class="col-md-6 col-sm-6">
                                   <div class="mb-3 ">
                                      <div class="row no-gutters ">
                                         <blockquote class="newslider">
                                            <div class="row">
                                               <div class="col-7">
                                                  <h3 class="leftside slif ft"
                                                     style="color: #333333;font-weight: 600; font-size: 20px;">Ranajit patil: (Pune)</h3>
                                               </div>
                                               <div class="col-5 textright">
                                                  <span class="strclr"><i class="fa fa-star"></i></span><span class="strclr"><i
                                                     class="fa fa-star"></i></span><span class="strclr"><i
                                                     class="fa fa-star"></i></span><span class="strclr"><i
                                                     class="fa fa-star"></i></span><span class="strwhite"><i
                                                     class="fa fa-star"></i></span>
                                               </div>
                                            </div>
                                            <p class="leftside f-14">
                                               " There are many robotics classes and institutes out in the market, but what made me
                                               more
                                               attracted towards this is the structure of course and the maintenance of each students
                                               progress towards betterment. I have always received proper assistance for training,
                                               marketing of upcoming workshops and guidance to make relationship with schools. The team
                                               has very well trained trainers."
                                            </p>
                                            <span class="ft-42"><i class="fa fa-quote-left" aria-hidden="true"></i></span> <br>
                                         </blockquote>
                                         <div style="margin-left: 80%;">
                                            <div class="quote-footer  ">
                                               <div class="quote-author-img">
                                                  <img src="../../../../../assets/images/franchise/ranjit.png" alt="ranjit.png">
                                               </div>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                                <div class="col-md-6 col-sm-6 ne">
                                   <div id="accordion" class="accordion accw">
                                      <div class="card">
                                         <div class="card-header collapsed" data-toggle="collapse" href="#collapseNinee">
                                            <a class="card-title">
                                            Tell Us Something About Yourself.
                                            </a>
                                         </div>
                                         <div id="collapseNinee" class="card-body collapse" data-parent="#accordion">
                                            <p>I completed my Btech and then M.Tech in design engineering, I have been always
                                               passionate about robotics so started to work on it from my engineering days and won lot
                                               of competitions in robotics such as robotic race, war of robotics etc. I have a loving
                                               mother and my younger sister in my family. I like playing chess with my friends and also
                                               love to read books.
                                            </p>
                                         </div>
                                      </div>
                                      <br>
                                      <div class="card">
                                         <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                                            href="#collapseTenn">
                                            <a class="card-title">
                                            What Made You Choose Mechatron Robotics?
                                            </a>
                                         </div>
                                         <div id="collapseTenn" class="card-body collapse" data-parent="#accordion">
                                            <p> There are many robotics classes and institutes in the market now, but what made me
                                               more attracted towards this is the structure of course and the focus on each student’s
                                               progress towards betterment. The work done by the trainers of Mechatron Robotics for
                                               student’s growth of knowledge is really appreciable.
                                            </p>
                                         </div>
                                      </div>
                                      <br>
                                      <div class="card">
                                         <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                                            href="#collapseElevenn">
                                            <a class="card-title mrf-13">
                                            How has Your experience been with Mechatron Robotics?
                                            </a>
                                         </div>
                                         <div id="collapseElevenn" class="collapse" data-parent="#accordion">
                                            <div class="card-body">
                                               <p> I have experienced a nice & supporting staff at Mechatron robotics. I would like to
                                                  express my special thanks to Siddharth Sir for his dedicated support towards the
                                                  growth of STEM partners.
                                               </p>
                                            </div>
                                         </div>
                                      </div>
                                      <br>
                                      <div class="card">
                                         <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                                            href="#collapsetwelvee">
                                            <a class="card-title mrf-13">
                                            What is your vision with respect to Mechatron Robotics?
                                            </a>
                                         </div>
                                         <div id="collapsetwelvee" class="collapse" data-parent="#accordion">
                                            <div class="card-body">
                                               <p>
                                                  I want to take this platform towards the extreme success by increasing the students’
                                                  Knowledge in the field of robotics & technology.
                                               </p>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                    <div class="carousel-item  ">
                       <div class="bg"></div>
                       <div class="row rowlf">
                          <div class="item ">
                             <div class="row">
                                <div class="col-md-6 col-sm-6">
                                   <div class="mb-3 ">
                                      <div class="row no-gutters ">
                                         <blockquote class="newslider">
                                            <div class="row">
                                               <div class="col-7">
                                                  <h3 class="leftside slif ft"
                                                     style="color: #333333;font-weight: 600; font-size: 20px;">Shweta Parode: (Pune)</h3>
                                               </div>
                                               <div class="col-5 textright">
                                                  <span class="strclr"><i class="fa fa-star"></i></span><span class="strclr"><i
                                                     class="fa fa-star"></i></span><span class="strclr"><i
                                                     class="fa fa-star"></i></span><span class="strclr"><i
                                                     class="fa fa-star"></i></span><span class="strwhite"><i
                                                     class="fa fa-star"></i></span>
                                               </div>
                                            </div>
                                            <p class="leftside f-14">
                                               " We have been able to develop a motivating culture among trainers, students and parents
                                               which is really great. Everyone in the team has a feeling of ownership and have been
                                               supporting activities for smooth operation of the business."
                                            </p>
                                            <br> <br>
                                            <span class="ft-42"><i class="fa fa-quote-left" aria-hidden="true"></i></span> <br>
                                         </blockquote>
                                         <div style="margin-left: 80%;">
                                            <div class="quote-footer  ">
                                               <div class="quote-author-img">
                                                  <img src="../../../../../assets/images/franchise/Shweta.png" alt="Shweta.png">
                                               </div>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                                <div class="col-md-6 col-sm-6 ne">
                                   <div id="accordion" class="accordion accw">
                                      <div class="card">
                                         <div class="card-header collapsed" data-toggle="collapse" href="#collapseNinep">
                                            <a class="card-title">
                                            Tell Us Something About Yourself.
                                            </a>
                                         </div>
                                         <div id="collapseNinep" class="card-body collapse" data-parent="#accordion">
                                            <p> I have done ME in Electronics (Embedded & VLSI Design). I like Singing & exploring new
                                               places. I wanted to start an educational business where we can provide all the latest
                                               technologies to the young kids of India.
                                            </p>
                                         </div>
                                      </div>
                                      <br>
                                      <div class="card">
                                         <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                                            href="#collapseTenp">
                                            <a class="card-title">
                                            What Made You Choose Mechatron Robotics?
                                            </a>
                                         </div>
                                         <div id="collapseTenp" class="card-body collapse" data-parent="#accordion">
                                            <p> I have always enjoyed spreading the technical awareness among young kids. I chose
                                               Mechatron as they are famous for providing education in robotics and technological
                                               courses. They have really good courses pattern & syllabus for the students which is
                                               holistic and covers different areas under robotics.
                                            </p>
                                         </div>
                                      </div>
                                      <br>
                                      <div class="card">
                                         <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                                            href="#collapseElevenp">
                                            <a class="card-title mrf-13">
                                            How has Your experience been with Mechatron Robotics?
                                            </a>
                                         </div>
                                         <div id="collapseElevenp" class="collapse" data-parent="#accordion">
                                            <div class="card-body">
                                               <p> We have been able to develop a culture among trainers, students and parents which is
                                                  really great. Everyone in the team has a feeling of ownership and have been supporting
                                                  activities for smooth operation of the business. They have been very transparent right
                                                  from the beginning so it’s a good experience overall.
                                               </p>
                                            </div>
                                         </div>
                                      </div>
                                      <br>
                                      <div class="card">
                                         <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion"
                                            href="#collapsetwelvep">
                                            <a class="card-title mrf-13">
                                            What is your vision with respect to Mechatron Robotics?
                                            </a>
                                         </div>
                                         <div id="collapsetwelvep" class="collapse" data-parent="#accordion">
                                            <div class="card-body">
                                               <p>
                                                  I wish we could spread more awareness of robotics related activities & its benefits to
                                                  young generation. I wish to reach out to more children and make them the innovators
                                                  from a very young age.
                                               </p>
                                            </div>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </div>
  </div>
</section> -->

<section class="map-bg">
  <div class="container text-center g-max-width-950 ">
     <br><br><br><br>
     <h2><span class="le"> Our</span> <span style="color:#ff8500;" class="lef"> Franchisee</span> <span class="lef">
        Locations </span>
     </h2>
     <br>
     <img alt="child" src="../../../../../assets/images/franchise/upatedmap.png" class="map-img">
  </div>
</section>

<br><br><br>
<section class="mg-top first-top">
  <div class="container">
     <div class="text-center">
      <h2 style="margin-left: 47px;"><span class="lef">Frequently</span><span class="le"> asked</span> <span
         style="color:#ff8500;" class="lef"> Questions</span></h2>
     </div>
     <div class="row" style="justify-content: center;">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist" style="display: flex">
           <li class="nav-item">
              <a class="nav-link active nvtb" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab"
                 aria-controls="pills-home" aria-selected="true">GENERAL</a>
           </li>
           <li class="nav-item">
              <a class="nav-link nvtb" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab"
                 aria-controls="pills-profile" aria-selected="false">TECHNICAL</a>
           </li>
           <li class="nav-item">
              <a class="nav-link nvtb" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab"
                 aria-controls="pills-contact" aria-selected="false">FINANCIAL</a>
           </li>
           <li class="nav-item">
              <a class="nav-link nvtb" id="pills-contact-tab1" data-toggle="pill" href="#pills-contact1" role="tab"
                 aria-controls="pills-contact1" aria-selected="false">MARKETING</a>
           </li>
        </ul>
     </div>
     <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
           <div class="row">
              <div class="col-sm-12 col-md-12">
                 <div class="faq">
                    <div class="panel-group " id="accordion" role="tablist" aria-multiselectable="true" >
                       <div class="panel card">
                          <div class="panel-heading " role="tab" id="headingOne">
                             <h3 class="panel-title">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#general1" aria-expanded="false" aria-controls="collapse1st" >
                                <span >What is the minimum target to be achieved? Is there a penalty for not achieving the target?</span >
                                </a>
                             </h3>
                          </div>
                          <div id="general1" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne" >
                             <div class="panel-body">
                                <p class="text-font-p">
                                  We have kept a target of 15 students/ quarter for one per centre. There is no penalty for not achieving the target however, it is suggested to achieve this target in order to maintain a healthy flow of revenue and be profitable.
                                </p>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
              <div class="col-sm-12 col-md-12">
                <div class="faq">
                   <div class="panel-group " id="accordion" role="tablist" aria-multiselectable="true" >
                      <div class="panel card">
                         <div class="panel-heading " role="tab" id="headingOne">
                            <h3 class="panel-title">
                               <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#general22" aria-expanded="false" aria-controls="collapse1st" >
                               <span >Will the initial training be provided by the company, and is it included in the franchise fee?</span >
                               </a>
                            </h3>
                         </div>
                         <div id="general22" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne" >
                            <div class="panel-body">
                               <p class="text-font-p">
                                Yes, we will provide the training of the subscribed courses after onboarding of the franchise and it is included in the fees.
                               </p>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
           </div>
           <div class="row">
              <div class="col-sm-12 col-md-12">
                 <div class="faq">
                    <div class="panel-group " id="accordion" role="tablist" aria-multiselectable="true" >
                       <div class="panel card">
                          <div class="panel-heading " role="tab" id="headingOne">
                             <h3 class="panel-title">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#general2" aria-expanded="false" aria-controls="collapse1st" >
                                <span >I am working so can I start this business along with my existing job?</span >
                                </a>
                             </h3>
                          </div>
                          <div id="general2" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne" >
                             <div class="panel-body">
                                <p class="text-font-p">
                                 Yes, you can start this business with your existing job as well. However, you should have a team of trainers and (as your business grows) student counsellors to manage the day-to-day activities.                                </p>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
              <div class="col-sm-12 col-md-12">
                <div class="faq">
                   <div class="panel-group " id="accordion" role="tablist" aria-multiselectable="true" >
                      <div class="panel card">
                         <div class="panel-heading " role="tab" id="headingOne">
                            <h3 class="panel-title">
                               <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#general4" aria-expanded="false" aria-controls="collapse1st" >
                               <span >What is the starting age group of students?</span >
                               </a>
                            </h3>
                         </div>
                         <div id="general4" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne" >
                            <div class="panel-body">
                               <p class="text-font-p">
                                 For offline courses, the starting age group is 6 years and for online courses we take kids of 7 years+. Usually after taking a trial class, we can decide if the student is able to grasp the concepts or not, and which level they are best suited to.                               </p>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
           </div>
           <div class="row">
              <div class="col-sm-12 col-md-12">
                 <div class="faq">
                    <div class="panel-group " id="accordion" role="tablist" aria-multiselectable="true" >
                       <div class="panel card">
                          <div class="panel-heading " role="tab" id="headingOne">
                             <h3 class="panel-title">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#general5" aria-expanded="false" aria-controls="collapse1st" >
                                <span >Do I need to be a technical person to start this business?</span >
                                </a>
                             </h3>
                          </div>
                          <div id="general5" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne" >
                             <div class="panel-body">
                                <p class="text-font-p">
                                 You do not need to be a technical person to conduct this business. The technical things shall be taken care of by the trainers. However, you just need to understand the business and explain the course structure and its benefits to the students/ to parents and schools.                                </p>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
              <div class="col-sm-12 col-md-12">
                <div class="faq">
                   <div class="panel-group " id="accordion" role="tablist" aria-multiselectable="true" >
                      <div class="panel card">
                         <div class="panel-heading " role="tab" id="headingOne">
                            <h3 class="panel-title">
                               <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#general6" aria-expanded="false" aria-controls="collapse1st" >
                               <span >Can we run the franchise online only?</span >
                               </a>
                            </h3>
                         </div>
                         <div id="general6" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne" >
                            <div class="panel-body">
                               <p class="text-font-p">
                                Yes, we do have a model where you can run the business online.
                               </p>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
           </div>
           <div class="row">
              <div class="col-sm-12 col-md-12">
                 <div class="faq">
                    <div class="panel-group " id="accordion" role="tablist" aria-multiselectable="true" >
                       <div class="panel card">
                          <div class="panel-heading " role="tab" id="headingOne">
                             <h3 class="panel-title">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#general7" aria-expanded="false" aria-controls="collapse1st" >
                                <span >Why we should choose Mechatron Robotics over any other player in the market?</span >
                                </a>
                             </h3>
                          </div>
                          <div id="general7" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne" >
                             <div class="panel-body">
                                   <ol class="text-font-p">
                                     <li>Training experience of more than 50,000+ students across India and the world through various workshops and training programs.</li>
                                     <li>Affordable Franchisee Fee and Kit Fee for any passionate entrepreneur to start.</li>
                                     <li>One of the best course structures in both online (10+ courses) and offline modes (7+ courses).</li>
                                     <li>Not just into coding but also provide training in mechanics, electronics, design, microcontrollers, robot operating system. These are very popular with students.</li>
                                     <li>Robotics and other course modules available for all age groups - kids, school students, engineering students and industry professionals.</li>
                                     <li>Highly qualified technical team to support franchise partners.</li>
                                     <li>Highly supportive model and very good track record to support partners for lead generation, branding, promotion and building marketing strategy which is mostly not provided by other partners.</li>
                                     <li>Low initial investment and ROI in 12-15 months.</li>
                                     <li>Internship opportunity for school and university students as we are into IT and industrial applications as well.</li>
                                     <li>International presence not just in India but in Germany, Australia and Africa (15+ countries) as well.</li>
                                     <li>FREE Portal for franchisee partners, trainers and students (coming soon) to manage all the operational activities like student registration, attendance, certifications, grading, videos and course, quizzes, assignment submission etc.</li>
                                     <li>Very good feedback from existing franchisees.</li>
                                   </ol>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
              <div class="col-sm-12 col-md-12">
                <div class="faq">
                   <div class="panel-group " id="accordion" role="tablist" aria-multiselectable="true" >
                      <div class="panel card">
                         <div class="panel-heading " role="tab" id="headingOne">
                            <h3 class="panel-title">
                               <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#general8" aria-expanded="false" aria-controls="collapse1st" >
                               <span >Does the company provide student leads or franchise has to generate. and how to generate students lead?</span >
                               </a>
                            </h3>
                         </div>
                         <div id="general8" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne" >
                            <div class="panel-body">
                               <p class="text-font-p">
                                 The company will assist franchisees with lead generation through our website. We can also discuss individual campaigns and approve these in conjunction with the Head Office in India.
                               </p>
                            </div>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
           </div>
           <div class="row">
              <div class="col-sm-12 col-md-12">
                 <div class="faq">
                    <div class="panel-group " id="accordion" role="tablist" aria-multiselectable="true" >
                       <div class="panel card">
                          <div class="panel-heading " role="tab" id="headingOne">
                             <h3 class="panel-title">
                                <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#general9" aria-expanded="false" aria-controls="collapse1st" >
                                <span >Is it mandatory to open a Physical Centre?</span >
                                </a>
                             </h3>
                          </div>
                          <div id="general9" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne" >
                             <div class="panel-body">
                                <p class="text-font-p">
                                 We have both the Online and physical centre models available.
                              </p>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </div>
        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
          <div class="row">
            <div class="col-sm-12 col-md-12">
               <div class="faq">
                  <div class="panel-group " id="accordion" role="tablist" aria-multiselectable="true" >
                     <div class="panel card">
                        <div class="panel-heading " role="tab" id="headingOne">
                           <h3 class="panel-title">
                              <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#general10" aria-expanded="false" aria-controls="collapse1st" >
                              <span >Who will take care of the training of students? How do you support training?</span >
                              </a>
                           </h3>
                        </div>
                        <div id="general10" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne" >
                           <div class="panel-body">
                              <p class="text-font-p">
                                We first prepare a job description of the trainer with the right skills set. We assist you in finding a suitable trainer. Then we train the trainer who will train the students registered under your centre. The technical things shall be taken care of by the trainers hired by the franchisee.
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-sm-12 col-md-12">
              <div class="faq">
                 <div class="panel-group " id="accordion" role="tablist" aria-multiselectable="true" >
                    <div class="panel card">
                       <div class="panel-heading " role="tab" id="headingOne">
                          <h3 class="panel-title">
                             <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#general11" aria-expanded="false" aria-controls="collapse1st" >
                             <span >Do we have courses for high school, university students and professionals as well?</span >
                             </a>
                          </h3>
                       </div>
                       <div id="general11" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne" >
                          <div class="panel-body">
                             <p class="text-font-p">
                              Yes, we do have course for these groups. The Courses usually taken by high school and university students are Electronics, Embedded Systems using Arduino, Python, Robot Operating system, IOT, Raspberry Pi and Machine Learning.
                             </p>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
         </div>
         <div class="row">
          <div class="col-sm-12 col-md-12">
             <div class="faq">
                <div class="panel-group " id="accordion" role="tablist" aria-multiselectable="true" >
                   <div class="panel card">
                      <div class="panel-heading " role="tab" id="headingOne">
                         <h3 class="panel-title">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#general12" aria-expanded="false" aria-controls="collapse1st" >
                            <span >How are your technical courses different from others?</span >
                            </a>
                         </h3>
                      </div>
                      <div id="general12" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne" >
                         <div class="panel-body">
                            <p class="text-font-p">
                              Our market research tells us that we are the ones who provide an extensive course of 48 hours per course for school and university students. We provide holistic knowledge of technical skills and teach interdisciplinary subjects to students. The courses not only include Coding, but Electronics, Microcontrollers, Artificial Intelligence, Mechanical Design, Arduino, Python and many more.
                            </p>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
        </div>
        <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
          <div class="row">
            <div class="col-sm-12 col-md-12">
               <div class="faq">
                  <div class="panel-group " id="accordion" role="tablist" aria-multiselectable="true" >
                     <div class="panel card">
                        <div class="panel-heading " role="tab" id="headingOne">
                           <h3 class="panel-title">
                              <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#general13" aria-expanded="false" aria-controls="collapse1st" >
                              <span >What are the areas of Revenue Generation?</span >
                              </a>
                           </h3>
                        </div>
                        <div id="general13" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne" >
                           <div class="panel-body">
                              <p class="text-font-p">
                                Revenue can be generated through 4 areas:
                              </p>
                              <ol class="text-font-p">
                                <li>Training programs at Training centres & schools.</li>
                                <li>Workshops at Training centre and schools.</li>
                                <li>Robotics labs.</li>
                                <li>Kits & parts.</li>
                              </ol>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-sm-12 col-md-12">
              <div class="faq">
                 <div class="panel-group " id="accordion" role="tablist" aria-multiselectable="true" >
                    <div class="panel card">
                       <div class="panel-heading " role="tab" id="headingOne">
                          <h3 class="panel-title">
                             <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#general14" aria-expanded="false" aria-controls="collapse1st" >
                             <span >Is the revenue sharing done on monthly basis? How it is calculated?</span >
                             </a>
                          </h3>
                       </div>
                       <div id="general14" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne" >
                          <div class="panel-body">
                             <p class="text-font-p">
                              Revenue sharing is done only on the course fees collected from the students. Instead of charging various fees, 15 per cent of revenue is paid to Mechatron Robotics in Australia and India (7.5 per cent each). More details will be provided upon enquiry.
                             </p>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
         </div>
         <div class="row">
          <div class="col-sm-12 col-md-12">
             <div class="faq">
                <div class="panel-group " id="accordion" role="tablist" aria-multiselectable="true" >
                   <div class="panel card">
                      <div class="panel-heading " role="tab" id="headingOne">
                         <h3 class="panel-title">
                            <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#general15" aria-expanded="false" aria-controls="collapse1st" >
                            <span >What are my fixed Costs?</span >
                            </a>
                         </h3>
                      </div>
                      <div id="general15" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne" >
                         <div class="panel-body">
                            <p class="text-font-p">
                              Your fixed costs would be the salary for staff which includes trainer, counsellor, rent for the centre. Initially you can avoid to hire the counsellor however we would recommend hiring the counsellor once the business grows.
                            </p>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
          </div>
          <div class="col-sm-12 col-md-12">
            <div class="faq">
               <div class="panel-group " id="accordion" role="tablist" aria-multiselectable="true" >
                  <div class="panel card">
                     <div class="panel-heading " role="tab" id="headingOne">
                        <h3 class="panel-title">
                           <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#general16" aria-expanded="false" aria-controls="collapse1st" >
                           <span >What will be the ROI?</span >
                           </a>
                        </h3>
                     </div>
                     <div id="general16" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne" >
                        <div class="panel-body">
                           <p class="text-font-p">
                            The ROI will depend on how aggressively you promote this business in your area. We have given an approximate ROI model based on our experience with other franchisees.
                           </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
       </div>
        </div>
        <div class="tab-pane fade" id="pills-contact1" role="tabpanel" aria-labelledby="pills-contact-tab1">
          <div class="row">
            <div class="col-sm-12 col-md-12">
               <div class="faq">
                  <div class="panel-group " id="accordion" role="tablist" aria-multiselectable="true" >
                     <div class="panel card">
                        <div class="panel-heading " role="tab" id="headingOne">
                           <h3 class="panel-title">
                              <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#Marketing1" aria-expanded="false" aria-controls="collapse1st" >
                              <span >What support do you provide in Marketing?</span >
                              </a>
                           </h3>
                        </div>
                        <div id="Marketing1" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne" >
                           <div class="panel-body">
                              <p class="text-font-p">
                                We provide various support under Marketing and Digital Marketing.
                              </p>
                              <ol class="text-font-p">
                                <li>Build Campaigns based on the strategy.</li>
                                <li>Design of all marketing materials like Signboard, Pamphlets, Marketing Collaterals for your Location.</li>
                                <li>Support & Training to deal with enquiries - Assisting partners for making proposals to schools and new leads for workshops.</li>
                                <li>Emails to be sent to schools, parents etc.</li>
                                <li>Complete design support for advertisement on social media.</li>
                                <li>Support for creation of social media campaigns.</li>
                              </ol>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-sm-12 col-md-12">
              <div class="faq">
                 <div class="panel-group " id="accordion" role="tablist" aria-multiselectable="true" >
                    <div class="panel card">
                       <div class="panel-heading " role="tab" id="headingOne">
                          <h3 class="panel-title">
                             <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse1st" aria-expanded="false" aria-controls="collapse1st" >
                             <span >What skills do the students develop at Mechatron robotics?</span >
                             </a>
                          </h3>
                       </div>
                       <div id="collapse1st" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne" >
                          <div class="panel-body">
                             <p class="text-font-p">
                                Students learn various technical skills like Coding,
                                Electronics, Mechanics, Mechanical design, Arduino, IOT and
                                many more practical courses. Apart from the technical skills
                                students also develop various non-technical skills like
                                creativity, innovation, communication skills, logical
                                thinking, problem solving skills & teamwork.
                             </p>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
         </div>
        </div>
     </div>
  </div>
</section>
<br><br><br>
