
<!-- <div class="mb-4"> -->
    <section class="hero-image">
        <div class="robot">
      <img src="../../../../../assets/images/thankurobot.svg" alt="robot" class="img-fluid robot-img"
        >
        </div>
</section>
<div class="text-center mt-4">
  <p class="thanks-message">  Thank you for booking your appointment !</p>
  </div>
  <div class="text-center mt-4">
    <p class="will-reach"> We will reach out to you shortly !</p>
    </div>
    <div class="text-center mt-4">
     <button type="button" class="button-back" routerLink="/">Back to home Page</button>
      </div>
    <div class="text-center mt-4">
      <p class="will-reach"> Connect with us !</p>
      </div>
    <div class="text-center">
      <a href="https://www.facebook.com/MechatronRobotics/" target="_blank"><i class="fa fa-facebook-square m-2" aria-hidden="true"  style="color:#3B5998; font-size: 25px;"></i></a>
      <a href="https://www.youtube.com/c/MechatronRobotics" target="_blank"><i class="fa fa-youtube-play m-2" aria-hidden="true" style="color:#C4302B;font-size: 25px;"></i></a>
       <a href="https://www.instagram.com/mechatron_robotics" target="_blank"><i class="fa fa-instagram m-2" aria-hidden="true" style="color: #FB3958;font-size: 25px;"></i></a>
       <a href="https://in.linkedin.com/company/mechatronrobotics" target="_blank"><i class="fa fa-linkedin-square m-2" aria-hidden="true" style="color: #0E76A8;font-size: 25px;"></i></a>
    </div>
<!-- </div> -->
<!-- </div> -->
