import {Injectable} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable()
export class SEOService {
  constructor(private title: Title, private meta: Meta) { }

    updateTitle(title: string) {
        this.title.setTitle(title);
    }
    updateTitleDescription(title: string, desc: string) {
        this.meta.updateTag({ name: title, content: desc });
    }
}
