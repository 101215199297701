<footer class="footer-area footer-bg">
  <div class="footer-big">
    <!-- start .container -->
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-12">
          <div class="footer-widget">
            <h4 class="footer-widget-title font-familyn">About Us</h4>
            <p class="font-familyp ft" align="justify" style="line-height: 20px;">
              Mechatron Robotics is the pioneer & leader in the field of Robotics and Artificial Intelligence and has
              trained more than 50,000+ students across the world by developing a strong project-based curriculum for
              the students. Mechatron Robotics started with a focus to develop young minds aged 7 years+, to prepare
              them for Industry 4.0 by providing practical exposure & project-based training to students and developing
              core skills in the field of Robotics & AI.
            </p>
            <!-- <p class="font-familyp ft">
              Since 2018, the company has successfully build trust with more
              than 50,000+ students, parents & business partners across India &
              abroad.
            </p> -->
          </div>
          <!-- Ends: .footer-widget -->

        </div>
        <!-- end /.col-md-4 -->
        <div class="col-md-2 col-sm-4">
          <div class="footer-widget">
            <div class="footer-menu footer-menu--1">
              <h4 class="footer-widget-title font-familyn">Quick Links</h4>
              <ul>
                <li class="font-familyp">
                  <a routerLink="/" class="srvcls">Home</a>
                </li>

                <li class="font-familyp">
                  <a class="srvcls" routerLink="/aboutus"> About Us</a>
                </li>
                <li class="font-familyp">
                  <a class="srvcls" routerLink="/courses">Courses</a>
                </li>


                <!-- <li class="font-familyp">
                  <a class="srvcls" routerLink="/careers">Career</a>
                </li>
                <li class="font-familyp">
                  <a class="srvcls" routerLink="/sponsors">Sponsors</a>
                </li> -->
                <!-- <li class="font-familyp">
                  <a class="srvcls" routerLink="/contactus">Contact Us</a>
                </li> -->

              </ul>
            </div>
            <!-- end /.footer-menu -->
          </div>
          <!-- Ends: .footer-widget -->
        </div>
        <!-- end /.col-md-3 -->

        <div class="col-md-3 col-sm-12">
          <div class="footer-widget">
            <div class="footer-menu">
              <h4 class="footer-widget-title font-familyn">Top Courses
              </h4>
              <ul>
                <li class="font-familyp">
                  <a class="srvcls">Coding through Scratch</a>
                </li>
                <li class="font-familyp">
                  <a class="srvcls">Artificial intelligence (A.I)</a>
                </li>
                <li class="font-familyp">
                  <a class="srvcls">Electronics & Circuits</a>
                </li>
                <li class="font-familyp">
                  <a class="srvcls">Arduino & Microcontrollers</a>
                </li>
                <li class="font-familyp">
                  <a class="srvcls">Python Foundation</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- <div class="col-md-2 col-sm-12">
          <div class="footer-widget">
            <div class="footer-menu">
              <h4 class="footer-widget-title font-familyn">Our Services</h4>
              <ul>
                <li class="font-familyp">
                  <a class="srvcls" routerLink="/courses">Courses</a>
                </li>

                <li class="font-familyp">
                  <a class="srvcls" routerLink="/offerings/workshop">Workshops & Events</a>

                </li>

                <li class="font-familyp">
                  <a class="srvcls" routerLink="/franchisee">Franchise
                  </a>
                </li>
                <li class="font-familyp">
                  <a class="srvcls" routerLink="/schools">Schools</a>
                </li>
              </ul>
            </div>
          </div>
        </div> -->
        <!-- end /.col-lg-3 -->

        <div class="col-md-4 col-sm-12">
          <div class="footer-widget">
            <div class="footer-menu">
              <div>
                <div class="footer-widget">
                  <h4 class="footer-widget-title font-familyn">Contact Us</h4>
                  <!-- <p class="font-familyp ft" style="line-height: 20px;"><i class="fa fa-map-marker"
                      aria-hidden="true"></i> : 191 Balaclava Road Caulfield North Victoria 3161. </p>
                  <p class="font-familyp ft" style="line-height: 20px;"><i class="fa fa-phone-square"
                      aria-hidden="true"></i> : 1800 490 617 / 03 9524 3115</p> -->
                  <p class="font-familyp ft" style="line-height: 20px;"><i class="fa fa-envelope"
                      aria-hidden="true"></i> info@mechatronrobotics.com</p>
                  <!-- <p class="font-familyp ft" style="line-height: 20px;"><i class="fa fa-solid fa-briefcase"
                      aria-hidden="true"></i> : ACN 654 594 064</p> -->
                </div>
              </div>
            </div>
            <div class="follow-us">
              <p class="font-familyn">FOLLOW US</p>
              <ul class="social-network social-circle">
                <li><a href="https://www.youtube.com/c/MechatronRobotics
                  " target="_blank"><i class="fa fa-youtube"></i></a></li>
                <li><a href="https://www.facebook.com/mechatronrobotics/" target="_blank"><i
                      class="fa fa-facebook"></i></a></li>
                <li><a href="https://www.instagram.com/mechatron_robotics/" target="_blank"><i
                      class="fa fa-instagram"></i></a></li>
                <li><a href="https://www.linkedin.com/company/mechatronrobotics/" target="_blank"><i
                      class="fa fa-linkedin"></i></a></li>
              </ul>
              <!-- <ul class="social-network social-circle">
                <li>
                  <a href="https://www.linkedin.com/company/mechatronrobotics/" target="_blank"><i
                      class="fab fa-linkedin"></i></a>
                </li>
                <li>
                  <a href="https://www.facebook.com/MechatronRobotics/" target="_blank"><i
                      class="fa fa-facebook"></i></a>
                </li>
                <li>
                  <a href="https://www.instagram.com/mechatron_robotics/" target="_blank"><i
                      class="fa fa-instagram"></i></a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/c/MechatronRobotics"
                    target="_blank"
                    ><i class="fa fa-youtube"></i
                  ></a>
                </li>
              </ul> -->
            </div>
            <!-- end /.footer-menu -->
          </div>
          <!-- Ends: .footer-widget -->
        </div>
        <!-- Ends: .col-lg-3 -->
      </div>
      <!-- end /.row -->
    </div>
    <!-- end /.container -->
  </div>
  <!-- end /.footer-big -->
  <div class="container">
    <hr class="footer-line" />
  </div>

  <div class="mini-footer">
    <div class="container">
      <div class="row">
        <div class="col-md-5 col-md-5 col-sm-12">
          <!-- <div class="copyright-text">
            <p class="font-familyp">
              Copyright © Mechatron Robotics 2021. All Rights Reserved.
            </p>
          </div> -->
        </div>
        <div class="col-md-7 col-md-5 col-sm-12">
          <div class="copyright-text policy-text">
            <p class="font-familyp">
              <!-- <span><a routerLink="/terms_condition" class="decoration-none">Terms & Conditions</a>
              </span> -->
              <span class="ml-3"><a routerLink="/privacy_policy" class="decoration-none">Privacy Policy</a> </span><span
                class="ml-3"><a routerLink="/web_terms" class="decoration-none">Web Terms & Conditions</a>
              </span>
              <!-- <span class="ml-3">
                <a routerLink="/shippingpolicy" class="decoration-none"
                  >Shipping Policy
                </a></span
              >
              <span class="ml-3 mr-4">
                <a routerLink="/disclaimer" class="decoration-none"
                  >Disclaimer</a
                ></span
              > -->
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
