import { Component, OnInit, ViewChild } from '@angular/core';
import { CourseDataService } from '../../course-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Address, EMAIL, Enquiry, PHONE } from '../../book-trail-class-page/landing-page.model';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-download-boucher-form',
  templateUrl: './download-boucher-form.component.html',
  styleUrls: ['./download-boucher-form.component.css']
})
export class DownloadBoucherFormComponent implements OnInit {

  onSubmitBtn: boolean = false;
  downloadbtn: boolean = false;
  enquiry: Enquiry;
  dial = '61';
  mobile!: String;
  formValidationErrors: any;
  currDate = new Date();
  @ViewChild('form', { static: false }) form: any;
  constructor(private courseData: CourseDataService, private router: Router,
    public dialogRef: MatDialogRef<DownloadBoucherFormComponent>,) {
    this.enquiry = new Enquiry();
    this.enquiry.query_date = this.currDate;
    this.enquiry.address = new Address();
    this.enquiry.PHONE = new Array<PHONE>();
    this.enquiry.EMAIL = new Array<EMAIL>();
    this.enquiry.PHONE.push(new PHONE());
    this.enquiry.EMAIL.push(new EMAIL());
    this.formValidationErrors = this.courseData.getFormValidationErrors();
    // /book-trial-class?utm_source=OLX&utm_medium=Banner&utm_campaign=Summer%20Campaign
    if (this.router.url.split('?')[1]) {
      let UrlStr = this.router.url.split('?')[1].split('&');
      let tempArr: any = [];
      for (let index = 0; index < UrlStr.length; index++) {
        tempArr.push(UrlStr[index].split('='));
      }
      let utmDict = [];
      for (let i = 0; i < tempArr.length; i++) {
        utmDict.push({
          key: tempArr[i][0],
          value: tempArr[i][1],
        });
      }
      var body: { [key: string]: string } = {};
      for (var x in utmDict) {
        body[utmDict[x].key] = utmDict[x].value;
      }
      // console.log('this.enquiry.utmObj', body[utmDict[x].key]);
      this.enquiry = {
        ...this.enquiry,
        ...body,
      };
    }
  }

  ngOnInit(): void {
  }
  onSubmit() {
    if (!this.form.valid) {
      return;
    }
    console.log('this.dial::', this.dial);
    this.enquiry.PHONE[0].VALUE = '+' + this.dial + ' ' + this.mobile;
    this.enquiry.notification_mail = true;
    this.enquiry.scope="Download Brochure"
    this.onSubmitBtn = true;
    console.log("enquiry Data",this.enquiry);
    this.courseData.createEnquiry(this.enquiry).subscribe((res) => {
      console.log('enq', res);
      this.form.reset();
      this.onSubmitBtn = false;
      this.downloadbtn=true
      //this.router.navigate(['/sponsors']);
    });
  }
  closeDialog(){
    this.dialogRef.close();
  }
}
