<div class="hero-image">
  <div class="container">
    <div class="row">
      <div class="col-md-12 top-margin-top text-center g-max-width-950">
        <h2>
          <span class="lef"> Become a</span>
          <span style="color: #ff8500" class="lef"> Sponsor</span>
        </h2>
        <img
          src="../../../../../assets/images/sponsers_page_assets/Group 419.png"
          alt="child"
          class="w-70 firsttop"
        />
      </div>
    </div>
  </div>
</div>
<br /><br /><br /><br />
<!-- Why Sponsor Us -->
<section class="mg-top left-back-img">
  <div class="container text-center g-max-width-950">
    <div class="row">
      <div class="col-md-2 col-sm-2 col-lg-2 col-xl-2">
        <img
          alt="child"
          src="../../../../../assets/images/school_page_images/comma-left.PNG"
          class="left-comma"
        />
      </div>
      <div class="col-md-8 col-sm-8 col-lg-8 col-xl-8" align="center">
        <h2>
          <span class="lef">Why</span>
          <span style="color: #ff8500" class="lef"> Sponsor</span>
          <span class="lef"> Us</span>
        </h2>
        <p class="text-font-p text-center">
          Mechatron Robotics Australia aims and focuses to develop the young minds of
          Australia, preparing them for Industry 4.0 by providing practical
          exposure and project-based training to students and developing their core
          skills in the field of Robotics and AI.
        </p>
        <p class="text-font-p text-center">
          Mechatron Robotics Australia offers various opportunities to sponsors to
          showcase their business, build business relationships, direct
          opportunities to sell their products and services, which increases their
          market reach, enhances growth and to get their business logo and other details displayed
          on our website and social media handles.
        </p>
      </div>
      <div class="col-md-2 col-sm-2 col-lg-2 col-xl-2">
        <img
          alt="child"
          src="../../../../../assets/images/school_page_images/comma-right.PNG"
          class="rigth-comma"
        />
      </div>
    </div>
  </div>
</section>

<!-- Website Sponsorship -->
<section class="mg-top first-top neurapses-bg">
  <div class="container text-center g-max-width-950">
    <br /><br />
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <h2>
          <span class="lef" style="color: #fff">What could be a better </span>
          <span style="color: #ff8500" class="lef"> Investment </span>
          <span class="lef" style="color: #fff">than in </span>
          <span style="color: #ff8500" class="lef"> Education?</span>
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <h2>
          <span class="lef" style="color: #fff; font-size: 25px"
            >Corporate Sponsors</span
          >
        </h2>
        <p style="color: #fff; font-size: 15px">
          Corporate Sponsors provide support to the business and contribute in
          their business activities and programs, <br />
          to help children get education in the field of technology. <br />
          Sponsors have numerous opportunities to partner with Mechatron Robotics Australia and promote their brand and products and services.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12">
        <h2>
          <span class="lef" style="color: #fff; font-size: 20px"
            >Benefits of
          </span>
          <span class="lef" style="color: #ff8500; font-size: 20px"
            >Corporate Sponsorship</span
          >
        </h2>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3 col-sm-3">
        <ol type="A" start="1">
          <li class="li">Exposure to market</li>
        </ol>
      </div>
      <div class="col-md-3 col-sm-3">
        <ol type="A" start="2">
          <li class="li">Increases Business opportunities</li>
        </ol>
      </div>
      <div class="col-md-3 col-sm-3">
        <ol type="A" start="3">
          <li class="li">
            Heightened visibility for business products and services
          </li>
        </ol>
      </div>
      <div class="col-md-3 col-sm-3">
        <ol type="A" start="4">
          <li class="li">High networking opportunities</li>
        </ol>
      </div>
    </div>

    <hr style="border-top: 1.5px solid #fff" />

    <div class="row">
      <div class="col-md-12 col-sm-12">
        <h2>
          <span class="lef" style="color: #fff; font-size: 25px"
            >Website Sponsors</span
          >
        </h2>
        <p style="color: #fff; font-size: 15px">
          A sponsorship where your business is promoted through our website with
          display of your logo and business details. Sponsors have   <br />
          numerous opportunities to partner with Mechatron Robotics Australia and promote their brand products and services.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12">
        <h2>
          <span class="lef" style="color: #fff; font-size: 20px"
            >Benefits of
          </span>
          <span class="lef" style="color: #ff8500; font-size: 20px"
            >Website Sponsorship</span
          >
        </h2>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4 col-sm-4">
        <ol type="A" start="1">
          <li class="li">It increases business visibility</li>
        </ol>
      </div>
      <div class="col-md-4 col-sm-4">
        <ol type="A" start="2">
          <li class="li">It increases market reach and enhances growth</li>
        </ol>
      </div>
      <div class="col-md-4 col-sm-4">
        <ol type="A" start="3">
          <li class="li">Build relationships with customers</li>
        </ol>
      </div>
    </div>
    <br />
  </div>
</section>

<!-- Benefits of  Sponsorship -->
<section class="mg-top right-back-img">
  <div class="container text-center g-max-width-950">
    <h2>
      <span class="lef"> Benefits of</span>
      <span style="color: #ff8500" class="lef"> Sponsorship</span>
      <span class="lef"> for You</span>
    </h2>
    <div class="row">
      <div class="col-md-3 col-sm-3 col-lg-3 col-xl-3">
        <img
          alt="child"
          src="../../../../../assets/images/sponsers_page_assets/Vector Smart Object-4.png"
          class="img-fluid svgimg-logo"
        />
        <p class="value-card-tite mt-4">
          Increases <br />
          company's visibility
        </p>
      </div>
      <div class="col-md-3 col-sm-3 col-lg-3 col-xl-3">
        <img
          alt="child"
          src="../../../../../assets/images/sponsers_page_assets/Vector Smart Object-3.png"
          class="img-fluid svgimg-logo"
        />
        <p class="value-card-tite mt-4">
          Increases <br />
          growth
        </p>
      </div>
      <div class="col-md-3 col-sm-3 col-lg-3 col-xl-3">
        <img
          alt="child"
          src="../../../../../assets/images/sponsers_page_assets/Vector Smart Object-2.png"
          class="img-fluid svgimg-logo"
        />
        <p class="value-card-tite mt-4">
          Generates qualified <br />
          Leads
        </p>
      </div>
      <div class="col-md-3 col-sm-3 col-lg-3 col-xl-3">
        <img
          alt="child"
          src="../../../../../assets/images/sponsers_page_assets/Vector Smart Object.png"
          class="img-fluid svgimg-logo"
        />
        <p class="value-card-tite mt-4">
          Increases <br />
          ROI
        </p>
      </div>
    </div>
  </div>
</section>

<!-- Downloadbrochure -->
<div
  class="
    margin_top
    container-fluid
    images
    d-flex
    ustify-center
    align-items-center
  "
>
  <div class="container mt-5 mb-5">
    <div class="col-md-12 text-center p-3">
      <h2>
        <span class="lef"> Please</span>
        <span style="color: #ff8500" class="lef"> Download</span>
        <span class="lef"> Our</span>
        <span style="color: #ff8500" class="lef"> Brochure</span>
        <span class="lef"> to know more about us</span>
      </h2>
      <h2 class="value-card-tite" style="font-size: 20px !important">
        In the field of Technological Advancement
      </h2>

      <!-- <a
        type="button"
        class="my-btn"
        href="../../../../../assets/images/pdf/Mechatron Flipbook Australia.pdf"
        download="Mechatron Flipbook Australia.pdf"
      >
        Download Now
      </a> -->
      <button
        type="button"
        class="my-btn"
        (click)="openDialog()"
      >
        Download Now
      </button>
    </div>
  </div>
</div>

<!-- Benefits of  Sponsorship -->
<!-- <section class="mg-top">
  <div class="container text-center g-max-width-950">
    <h2>
      <span class="lef"> We Thank Our</span>
      <span style="color: #ff8500" class="lef"> Sponsors</span>
    </h2>
    <div class="row">
      <div class="col-md-3 col-sm-3 col-lg-3 col-xl-3"> -->
        <!-- <img
          alt="child"
          src="../../../../../assets/images/sponsers_page_assets/bigwing_logo.png"
          class="img-fluid svgimg-logo"
        /> -->
      <!-- </div>
      <div class="col-md-3 col-sm-3 col-lg-3 col-xl-3">
        <img
          alt="child"
          src="../../../../../assets/images/sponsers_page_assets/bigwing_logo.png"
          class="img-fluid svgimg-logo"
        />
      </div>
      <div class="col-md-3 col-sm-3 col-lg-3 col-xl-3">
        <img
          alt="child"
          src="../../../../../assets/images/sponsers_page_assets/NT-blank.png"
          class="img-fluid svgimg-logo"
        />
      </div>
      <div class="col-md-3 col-sm-3 col-lg-3 col-xl-3"> -->
        <!-- <img
          alt="child"
          src="../../../../../assets/images/sponsers_page_assets/bigwing_logo.png"
          class="img-fluid svgimg-logo"
        /> -->
      <!-- </div>
    </div>
  </div>
</section> -->
<br><br><br><br>

