import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import {CourseDataService} from '../course-data.service'
import { Router } from '@angular/router';

@Component({
  selector: 'app-our-courses',
  templateUrl: './our-courses.component.html',
  styleUrls: ['./our-courses.component.css']
})
export class OurCoursesComponent implements OnInit {
  Courses: any = [];
  recommendedallCourses: any = [];
  hybridCourses: any = [];
  selfPlaceCourses: any = [];
  integratedCourses:any = [];
  storagepath: any;
  allCourses: any = [];
  onlineCourses: any = [];
  offlineCourses: any = [];
  course_loader=false;
  constructor(
    private router: Router,
    private courseData:CourseDataService) { }

  ngOnInit(): void {
    this.getAllCourse()
  }

  //get All Course
  getAllCourse()
  {
    this.course_loader=false
    this.courseData.getAllCourse().subscribe(
      (res)=>{
        this.Courses = res;
        this.allCourses=this.Courses.data;
        this.storagepath = this.Courses.storage_path;
        if(!this.allCourses){
        this.course_loader=true;
        alert("No Data Found");
        }
        localStorage.setItem("storageBaseUrl",  this.storagepath);
        this.allCourses.forEach((element:any) => {
          if (element.course) {
            if (element.course.type === "Online" && element.catalog_visibility) {
              this.onlineCourses.push(element);
            } else if (element.course.type === "Class Room" && element.catalog_visibility) {
              this.offlineCourses.push(element);
            }
            else if (element.course.type === "Self Paced" && element.catalog_visibility) {
              this.selfPlaceCourses.push(element);
            }
            else if (element.course.type === "Hybrid" && element.catalog_visibility) {
              this.hybridCourses.push(element);
            }
            else if (element.course.type === "Integrated" && element.catalog_visibility) {
              this.integratedCourses.push(element);
            }
          }
        });
        this.course_loader=true;
      },
    error =>{
      console.log(error.message)
      this.course_loader = true;
    }
    )
  }


  getCourseImage(url: any) {
      let result;
      url?.forEach((element: any) => {
        if(!element.url ||element.url == null || element.url == '') {
          result = element.url;
        }
        else {
          result = element.url;
        }
      });
      let defaultImg;
      if (result == null || result == '' || result == undefined) {
        defaultImg = '/assets/images/courses/defaultpic.png';
        return defaultImg;
      }
      else {
        return this.storagepath + result;
      }
    }






  viewDetails(item: any) {
    let newType = item.course.type.replace(/\s+/g, "-").toLowerCase();
    let newUrl = item.seo.url;
    setTimeout(() => {
      this.router.navigate(["/course/", newType, newUrl]);
    }, 500);

  }




  scroll(el: HTMLElement) {
    el.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
}
